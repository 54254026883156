<div *ngIf="loadingConfig">
    <span class="app-loading">
        <svg class="spinner" viewBox="25 25 50 50">
            <circle
                class="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="2"
                stroke-miterlimit="10"
            />
        </svg>
    </span>
</div>
<h3>Configuration</h3>
<form
    class="page-form"
    #configForm="ngForm"
    *ngIf="!loadingConfig"
    (ngSubmit)="onSubmit()"
>
    <div>
        <div class="lined-input-group">
            <label for="maximumAllowedCredits"> Max Allowed Credits </label>
            <input
                class="form-control"
                required
                id="maximumAllowedCredits"
                name="maximumAllowedCredits"
                #maximumAllowedCredits="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.maximumAllowedCredits"
                type="number"
                [step]="0.01"
                [min]="0.01"
            />
            <div
                *ngIf="
                    maximumAllowedCredits.invalid &&
                    (maximumAllowedCredits.dirty ||
                        maximumAllowedCredits.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="maximumAllowedCredits.errors.required">
                    Max Allowed Credits is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="maxExpensesSubmitable">
                Max Expenses Claim Amount
            </label>
            <input
                class="form-control"
                required
                id="maxExpensesSubmitable"
                name="maxExpensesSubmitable"
                #maxExpensesSubmitable="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.maxExpensesSubmitable"
                type="number"
                [step]="0.01"
                [min]="0.01"
            />
            <div
                *ngIf="
                    maxExpensesSubmitable.invalid &&
                    (maxExpensesSubmitable.dirty ||
                        maxExpensesSubmitable.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="maxExpensesSubmitable.errors.required">
                    Max Expenses Claim Amount is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="subsistencePerDay"> Subsistence Amount Per Day </label>
            <input
                class="form-control"
                required
                id="subsistencePerDay"
                name="subsistencePerDay"
                #subsistencePerDay="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.subsistencePerDay"
                type="number"
                [step]="0.01"
                [min]="0.01"
            />
            <div
                *ngIf="
                    subsistencePerDay.invalid &&
                    (subsistencePerDay.dirty || subsistencePerDay.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="subsistencePerDay.errors.required">
                    Subsistence Amount Per Day is required.
                </div>
            </div>
        </div>

        <div class="lined-input-group">
            <label for="maxAccommodationPricePerNight">
                Max Accommodation Price Per Night
            </label>
            <input
                class="form-control"
                required
                id="maxAccommodationPricePerNight"
                name="maxAccommodationPricePerNight"
                #maxAccommodationPricePerNight="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.maxAccommodationPricePerNight"
                type="number"
                [step]="0.01"
                [min]="0.01"
            />
            <div
                *ngIf="
                    maxAccommodationPricePerNight.invalid &&
                    (maxAccommodationPricePerNight.dirty ||
                        maxAccommodationPricePerNight.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="maxAccommodationPricePerNight.errors.required">
                    Max Accommodation Price Per Night is required.
                </div>
            </div>
        </div>

        <div class="lined-input-group">
            <label for="carAllowancePerKm"> Car Allowance Per Kilometer </label>
            <input
                class="form-control"
                required
                id="carAllowancePerKm"
                name="carAllowancePerKm"
                #carAllowancePerKm="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.carAllowancePerKm"
                type="number"
                [step]="0.01"
                [min]="0.01"
            />
            <div
                *ngIf="
                    carAllowancePerKm.invalid &&
                    (carAllowancePerKm.dirty || carAllowancePerKm.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="carAllowancePerKm.errors.required">
                    Car Allowance Per Kilometer is required.
                </div>
            </div>
        </div>

        <div class="lined-input-group">
            <label for="subsistencePerDay">
                Substitute Cost Per Half Day
            </label>
            <input
                class="form-control"
                required
                id="substituteCostPerHalfDay"
                name="substituteCostPerHalfDay"
                #substituteCostPerHalfDay="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.substituteCostPerHalfDay"
                type="number"
                [step]="0.01"
                [min]="0.01"
            />
            <div
                *ngIf="
                    substituteCostPerHalfDay.invalid &&
                    (substituteCostPerHalfDay.dirty ||
                        substituteCostPerHalfDay.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="substituteCostPerHalfDay.errors.required">
                    Substitute Cost Per Half Day is required.
                </div>
            </div>
        </div>

        <div class="lined-input-group">
            <label for="substituteCostPerFullDay">
                Substitute Cost Per Full Day
            </label>
            <input
                class="form-control"
                required
                id="substituteCostPerFullDay"
                name="substituteCostPerFullDay"
                #substituteCostPerFullDay="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.substituteCostPerFullDay"
                type="number"
                [step]="0.01"
                [min]="0.01"
            />
            <div
                *ngIf="
                    substituteCostPerFullDay.invalid &&
                    (substituteCostPerFullDay.dirty ||
                        substituteCostPerFullDay.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="substituteCostPerFullDay.errors.required">
                    Substitute Cost Per Full Day is required.
                </div>
            </div>
        </div>

        <div class="lined-input-group">
            <label for="subsistencePerDay">
                Substitute Maximum Cost Per Grid Day
            </label>
            <input
                class="form-control"
                required
                id="substituteMaximumGridCostPerDay"
                name="substituteMaximumGridCostPerDay"
                #substituteMaximumGridCostPerDay="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.substituteMaximumGridCostPerDay"
                type="number"
                [step]="0.01"
                [min]="0.01"
            />
            <div
                *ngIf="
                    substituteMaximumGridCostPerDay.invalid &&
                    (substituteMaximumGridCostPerDay.dirty ||
                        substituteMaximumGridCostPerDay.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="substituteMaximumGridCostPerDay.errors.required">
                    Substitute Maximum Cost Per Grid Day is required.
                </div>
            </div>
        </div>

        <div class="lined-input-group">
            <label for="dateHelpText"> Date Help Text </label>
            <textarea
                class="form-control text"
                rows="2"
                required
                id="dateHelpText"
                name="dateHelpText"
                #dateHelpText="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.dateHelpText"
                type="text"
            ></textarea>
            <div
                *ngIf="
                    dateHelpText.invalid &&
                    (dateHelpText.dirty || dateHelpText.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="dateHelpText.errors.required">
                    Date Help Text is required.
                </div>
            </div>
        </div>

        <div class="lined-input-group">
            <label for="conferenceInfoText">
                Attach Conference Information Text
            </label>
            <textarea
                class="form-control text"
                rows="1"
                required
                id="conferenceInfoText"
                name="conferenceInfoText"
                #conferenceInfoText="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.conferenceInfoText"
                type="text"
            ></textarea>
            <div
                *ngIf="
                    conferenceInfoText.invalid &&
                    (conferenceInfoText.dirty || conferenceInfoText.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="conferenceInfoText.errors.required">
                    Attach Conference Information Text is required.
                </div>
            </div>
        </div>

        <div class="lined-input-group">
            <label for="pdExplanationHelpText">
                PD Explanation Help Text
            </label>
            <textarea
                class="form-control text"
                rows="1"
                required
                id="pdExplanationHelpText"
                name="pdExplanationHelpText"
                #pdExplanationHelpText="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.pdExplanationHelpText"
                type="text"
            ></textarea>
            <div
                *ngIf="
                    pdExplanationHelpText.invalid &&
                    (pdExplanationHelpText.dirty ||
                        pdExplanationHelpText.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="dateHelpText.errors.required">
                    PD Explanation Help Text is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="substituteExpenseHelpText">
                Substitute Expenses Help Text
            </label>
            <textarea
                class="form-control text"
                rows="7"
                required
                id="substituteExpenseHelpText"
                name="substituteExpenseHelpText"
                #substituteExpenseHelpText="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.substituteExpenseHelpText"
                type="text"
            ></textarea>
            <div
                *ngIf="
                    substituteExpenseHelpText.invalid &&
                    (substituteExpenseHelpText.dirty ||
                        substituteExpenseHelpText.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="substituteExpenseHelpText.errors.required">
                    Substitute Expenses Help Text is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="mileageHelpText"> Mileage Help Text </label>
            <textarea
                class="form-control text"
                rows="1"
                required
                id="mileageHelpText"
                name="mileageHelpText"
                #mileageHelpText="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.mileageHelpText"
                type="text"
            ></textarea>
            <div
                *ngIf="
                    mileageHelpText.invalid &&
                    (mileageHelpText.dirty || mileageHelpText.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="mileageHelpText.errors.required">
                    Mileage Help Text is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="subsistenceHelpText"> Subsistence Help Text </label>
            <textarea
                class="form-control text"
                rows="2"
                required
                id="subsistenceHelpText"
                name="subsistenceHelpText"
                #subsistenceHelpText="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="config.subsistenceHelpText"
                type="text"
            ></textarea>
            <div
                *ngIf="
                    subsistenceHelpText.invalid &&
                    (subsistenceHelpText.dirty || subsistenceHelpText.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="subsistenceHelpText.errors.required">
                    Subsistence Help Text is required.
                </div>
            </div>
        </div>

        <div class="submit-button-div">
            <div *ngIf="savingConfig">
                <span class="app-loading">
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle
                            class="path"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            stroke-width="2"
                            stroke-miterlimit="10"
                        />
                    </svg>
                </span>
            </div>
            <button
                type="submit"
                class="btn btn-primary"
                [disabled]="
                    !configForm.form.valid || savingConfig || disableForNonAdmin
                "
            >
                Save
            </button>
        </div>
    </div>

    <div *ngIf="!disableForNonAdmin">
        <h3>Bulk Credit Load</h3>
        <div class="past-bulk-credit-list">
            <h6>Past Credit Loads:</h6>
            <table class="list-table smaller past-credit-loads-table">
                <thead>
                    <tr>
                        <th class="header">Date</th>
                        <th class="header">Loaded By</th>
                        <th class="header">Amount</th>
                        <th class="header">To Max</th>
                        <th class="header">Active Members</th>
                        <th class="header">Members Completed</th>
                        <th class="header">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="loadingPastCredits">
                        <td colspan="5">
                            <span class="app-loading">
                                <svg class="spinner" viewBox="25 25 50 50">
                                    <circle
                                        class="path"
                                        cx="50"
                                        cy="50"
                                        r="20"
                                        fill="none"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                    />
                                </svg>
                            </span>
                        </td>
                    </tr>
                    <tr
                        class="list-row"
                        *ngFor="let pastCreditLoad of pastCreditLoads"
                    >
                        <td title="{{ pastCreditLoad.startDate }}">
                            {{ getAgo(pastCreditLoad.startDate) }}
                        </td>
                        <td>
                            <span
                                title="{{ pastCreditLoad.startedByUserEmail }}"
                            >
                                {{ pastCreditLoad.startedByUserName }}
                            </span>
                        </td>
                        <td>
                            {{
                                pastCreditLoad.amountToLoad
                                    | currency
                                        : "CAD"
                                        : "symbol-narrow"
                                        : "1.2-2"
                            }}
                        </td>
                        <td>
                            {{
                                pastCreditLoad.toMaximumOf
                                    | currency
                                        : "CAD"
                                        : "symbol-narrow"
                                        : "1.2-2"
                            }}
                        </td>
                        <td>
                            {{ pastCreditLoad.membersToLoad }}
                        </td>
                        <td>
                            {{ pastCreditLoad.membersLoaded }}
                        </td>
                        <td>
                            {{ pastCreditLoad.loadStatus }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div
                *ngIf="
                    !loadingPastCredits &&
                    (!pastCreditLoads || !pastCreditLoads.length)
                "
            >
                No previous bulk credit loads found.
            </div>
        </div>
        <div
            class="new-bulk-credits"
            *ngIf="!loadingRosterMembers && !loadingConfig"
        >
            <div class="bulk-load-info">
                There are {{ rosterMembers.length }} active members. The maximum
                credit balance is
                {{
                    config.maximumAllowedCredits
                        | currency : "CAD" : "symbol-narrow" : "1.2-2"
                }}.
            </div>
            <div class="add-credits-div" *ngIf="!activeCreditLoad">
                Bulk load credits: $
                <input
                    id="bulkCreditsToAdd"
                    name="bulkCreditsToAdd"
                    [(ngModel)]="bulkCreditsToAdd"
                    type="number"
                    [step]="0.01"
                    [min]="0.01"
                />
                <button
                    type="button"
                    class="btn btn-info"
                    (click)="startBulkCredit(bulkCreditsToAdd)"
                    [disabled]="
                        !bulkCreditsToAdd ||
                        loadingRosterMembers ||
                        loadingConfig ||
                        disableForNonAdmin
                    "
                >
                    Start Bulk Credit Add
                </button>
            </div>
            <div
                class="active-credit-load-detail"
                *ngIf="activeCreditLoad"
                [ngSwitch]="activeCreditLoad.loadStatus"
            >
                <div *ngSwitchCase="'Finished'">
                    Bulk credit load
                    <span class="succeeded-span">succeeded</span>;
                    {{ activeCreditLoad.MembersLoaded }} members credited. View
                    detailed results below.
                </div>
                <div *ngSwitchCase="'Failed'">
                    The bulk load operation
                    <span class="failed-span">failed</span>.
                    {{ activeCreditLoad.MembersLoaded }} out of
                    {{ rosterMembers.length }} members were credited.
                </div>
                <div *ngSwitchCase="'Finishing'">
                    <span class="inline-spinner"></span>Finishing up...
                </div>
                <div *ngSwitchCase="'Started'">
                    <span class="inline-spinner"></span>Bulk load in progress:
                    {{ processingRosterIndex + 1 }}/{{ rosterMembers.length }}.
                    <span class="do-not-click-warning">
                        Please do not close this window or click anything inside
                        it!
                    </span>
                </div>
                <div *ngSwitchDefault>
                    <span class="inline-spinner"></span>Starting bulk credit
                    load...
                </div>
            </div>
            <div>
                <span *ngIf="activeCreditLoad">
                    Results of this bulk credit load:
                </span>
                <span *ngIf="!activeCreditLoad">
                    Active members to credit:
                </span>
                <table class="list-table smaller new-credit-roster-table">
                    <thead>
                        <tr class="list-row">
                            <th class="header">Member</th>
                            <th class="header">Last Seen</th>
                            <th class="header">Last Credited</th>
                            <th class="header">Last Credited Amount</th>
                            <th class="header left-border">
                                <span [ngClass]="{ hidden: !activeCreditLoad }">
                                    Previous Balance
                                </span>
                            </th>
                            <th class="header">
                                <span [ngClass]="{ hidden: !activeCreditLoad }">
                                    Credits Added
                                </span>
                            </th>
                            <th class="header right-border">
                                <span [ngClass]="{ hidden: !activeCreditLoad }">
                                    Resulting Balance
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="list-row"
                            *ngFor="let member of rosterMembers"
                        >
                            <td>
                                <span title="{{ member.email }}">
                                    {{ member.firstName }} {{ member.lastName }}
                                </span>
                            </td>
                            <td title="{{ member.lastSeen }}">
                                {{ getAgo(member.lastSeen) }}
                            </td>
                            <td title="{{ member.lastCreditLoadDate }}">
                                {{ getAgo(member.lastCreditLoadDate) }}
                            </td>
                            <td>
                                {{
                                    member.lastCreditLoadAmount
                                        | currency
                                            : "CAD"
                                            : "symbol-narrow"
                                            : "1.2-2"
                                }}
                            </td>
                            <td class="left-border">
                                <span *ngIf="activeCreditLoad">
                                    {{
                                        member.previousBalance
                                            | currency
                                                : "CAD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </span>
                            </td>
                            <td>
                                <span
                                    *ngIf="activeCreditLoad"
                                    class="credits-added"
                                >
                                    <span
                                        class="inline-spinner"
                                        *ngIf="member.isBeingCredited"
                                    ></span>
                                    {{
                                        member.creditsAdded
                                            | currency
                                                : "CAD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </span>
                            </td>
                            <td class="right-border">
                                <span *ngIf="activeCreditLoad">
                                    {{
                                        member.resultingBalance
                                            | currency
                                                : "CAD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</form>
<div *ngIf="loadingPastCredits || loadingRosterMembers || loadingConfig">
    <span class="app-loading">
        <svg class="spinner" viewBox="25 25 50 50">
            <circle
                class="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="2"
                stroke-miterlimit="10"
            />
        </svg>
    </span>
</div>
