<div *ngIf="loadingTerm">
    <span class="app-loading">
        <svg class="spinner" viewBox="25 25 50 50">
            <circle
                class="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="2"
                stroke-miterlimit="10"
            />
        </svg>
    </span>
</div>
<form
    class="page-form"
    #termForm="ngForm"
    *ngIf="!loadingTerm"
    (ngSubmit)="onSubmit()"
>
    <div>
        <div class="lined-input-group">
            <label for="termName"> Name </label>
            <input
                type="text"
                required
                class="form-control"
                id="termName"
                name="termName"
                #termName="ngModel"
                [readOnly]="disableForNonAdmin"
                [(ngModel)]="term.name"
            />
            <div
                *ngIf="termName.invalid && (termName.dirty || termName.touched)"
                class="alert alert-danger"
            >
                <div *ngIf="termName.errors.required">
                    Term Name is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="startDateInput"> Start Date </label>
            <input
                type="text"
                id="startDateInput"
                name="termStartDate"
                class="form-control"
                #startDatePicker="bsDatepicker"
                #termStartDate="ngModel"
                bsDatepicker
                required
                [disabled]="disableForNonAdmin"
                [(ngModel)]="term.startDate"
            />
            <div
                *ngIf="
                    termStartDate.invalid &&
                    (termStartDate.dirty || termStartDate.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="termStartDate.errors.required">
                    Term Start Date is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="endDateInput"> End Date </label>
            <input
                type="text"
                id="endDateInput"
                name="termEndDate"
                class="form-control"
                #endDatePicker="bsDatepicker"
                #termEndDate="ngModel"
                bsDatepicker
                required
                [disabled]="disableForNonAdmin"
                [(ngModel)]="term.endDate"
            />
            <div
                *ngIf="
                    termEndDate.invalid &&
                    (termEndDate.dirty || termEndDate.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="termEndDate.errors.required">
                    Term End Date is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="startingBalance"> Starting Balance </label>
            <input
                class="form-control"
                required
                id="startingBalance"
                name="startingBalance"
                #startingBalance="ngModel"
                [(ngModel)]="term.startingBalance"
                type="number"
                [step]="0.01"
                [min]="0.01"
                [readOnly]="disableForNonAdmin"
            />
            <div
                *ngIf="
                    startingBalance.invalid &&
                    (startingBalance.dirty || startingBalance.touched)
                "
                class="alert alert-danger"
            >
                <div *ngIf="startingBalance.errors.required">
                    Starting Balance is required.
                </div>
            </div>
        </div>

        <div class="lined-input-group">
            <label> Term open for applications </label>
            <br />
            <mat-slide-toggle
                *ngIf="!loadingTerm"
                name="isOpen"
                class="slide-toggle"
                hideIcon="true"
                [disabled]="disableForNonAdmin"
                [(ngModel)]="term.isOpen"
            >
                {{ term.isOpen ? "Open" : "Closed" }}
            </mat-slide-toggle>
        </div>

        <div class="allocation-bar" *ngIf="showAllocationBar">
            <eil-color-bar
                [spent]="term.totalSpent"
                [pending]="term.totalPending"
                [requested]="term.totalProposed"
                [ceiling]="term.startingBalance"
                [showCeilingLabel]="true"
                [showLegend]="true"
                [showRemaining]="true"
            >
            </eil-color-bar>
        </div>

        <div class="submit-button-div">
            <div *ngIf="savingTerm">
                <span class="app-loading">
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle
                            class="path"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            stroke-width="2"
                            stroke-miterlimit="10"
                        />
                    </svg>
                </span>
            </div>
            <button
                type="submit"
                class="btn btn-primary"
                [disabled]="
                    !termForm.form.valid || savingTerm || disableForNonAdmin
                "
            >
                Save Term
            </button>
            <div
                *ngIf="termForm.invalid && !loadingTerm"
                class="alert alert-danger"
            >
                <div *ngIf="termName.invalid && termName.errors.required">
                    Term Name is required.
                </div>
                <div
                    *ngIf="
                        termStartDate.invalid && termStartDate.errors.required
                    "
                >
                    Term Start Date is required.
                </div>
                <div *ngIf="termEndDate.invalid && termEndDate.errors.required">
                    Term End Date is required.
                </div>
                <div
                    *ngIf="
                        startingBalance.invalid &&
                        startingBalance.errors.required
                    "
                >
                    Term Starting Balance is required.
                </div>
            </div>
        </div>
    </div>
</form>
