import { Component, 
         forwardRef,
         OnInit,
         Input,
         EventEmitter,
         Output }        from '@angular/core';
import { IAmountPair } from '../../models/amount-pair.model';
import { NG_VALUE_ACCESSOR,
         ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

export const AMOUNT_PAIR_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AmountpairComponent),
    multi: true
};

@Component({
        selector:       'eil-amountpair',
        templateUrl:    './amountpair.component.html',
        styleUrls: ['./amountpair.component.scss'],
        providers: [AMOUNT_PAIR_CONTROL_VALUE_ACCESSOR]
    }
)
export class AmountpairComponent implements OnInit, ControlValueAccessor {

    @Input() public amountHeader?: string;
    @Input() public amountPrefix?: string;
    @Input() public amountSuffix?: string;
    @Input() public placeholder?: string;
    @Input() public step?: string;
    @Input() public min?: string;
    @Output() public change = new EventEmitter<IAmountPair>();
    @Input() public disabled?: boolean;
    @Input() public actual?: boolean;

    // The internal data model
    amountPair: IAmountPair = {};

    // Databinding the input control
    get boundAmount(): number {
        if (!this.amountPair) { return null; }

        return this.actual
            ? this.amountPair.actual
            : this.amountPair.anticipated;
    }
    set boundAmount(calc: number) {
        if (!this.amountPair) { this.amountPair = {}; }

        if (this.actual) {
            this.amountPair.actual = calc;
        } else {
            this.amountPair.anticipated = calc;
        }
    }

    // Placeholders for the callbacks which are later providesd
    // by the Control Value Accessor
    onTouchedCallback: () => void = noop;
    onChangeCallback: (_: any) => void = noop;

    // get accessor
    get value(): IAmountPair {
        return this.amountPair;
    }

    // set accessor including call the onchange callback
    set value(v: IAmountPair) {
        if (v !== this.amountPair) {
            this.amountPair = v;
            this.onChangeCallback(v);
        }
    }

    // Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    // From ControlValueAccessor interface
    writeValue(value: IAmountPair) {
        if (value !== this.amountPair) {
            this.amountPair = value;
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    constructor() {

    }

    ngOnInit() {

    }
}
