
<div class="admin-panel" *ngIf="showAdminPanel()">
    <h4 class="account-details-header">
        User Administration
    </h4>

    <div class="row user-admin-property-row">
        <div class="col-4 user-admin-property-label-div">
            <span class="user-admin-property-label">
                Is PD Member:
            </span>
        </div>
        <div class="col-2 user-admin-property-value-div">
            <span class="user-admin-property-value" [ngClass]="{'bold': !user.isMember}">
                {{ user.isMember ? "Yes" : "No" }}
            </span>
        </div>
        <div class="col-4 user-admin-property-actions-div">
            <button type="button" class="btn btn-info" *ngIf="memberLogic.adminCanMakeMember(user)" (click)="toggleIsMember(user, true)">
                Set as Member
            </button>
            <button type="button" class="btn btn-info" *ngIf="memberLogic.adminCanRemoveAsMember(user)" (click)="toggleIsMember(user, false)">
                Remove as Member
            </button>
        </div>
    </div>

    <div class="row user-admin-property-row">
        <div class="col-4 user-admin-property-label-div">
            <span class="user-admin-property-label">
                PD Member Registration Complete:
            </span>
        </div>
        <div class="col-2 user-admin-property-value-div">
            <span class="user-admin-property-value" [ngClass]="{'bold': !user.isRegistered}">
                {{ user.isRegistered ? "Yes" : "No" }}
            </span>
        </div>
    </div>

    <div class="row user-admin-property-row">
        <div class="col-4 user-admin-property-label-div">
            <span class="user-admin-property-label">
                Approval Status:
            </span>
        </div>
        <div class="col-2 user-admin-property-value-div">
            <span class="user-admin-property-value">
                {{ user.isApproved ? "Approved" : (user.hasBeenApproved ? "Deactivated" : "Pending") }}
            </span>
        </div>
        <div class="col-4 user-admin-property-actions-div">
            <button type="button" class="btn btn-primary" *ngIf="memberLogic.adminCanApprove(user)" (click)="toggleIsApproved(user, true)">
                Approve
            </button>
            <button type="button" class="btn btn-danger" *ngIf="memberLogic.adminCanReject(user)" (click)="reject(user)">
                Reject
            </button>
            <button type="button" class="btn btn-info" *ngIf="memberLogic.adminCanDeactivate(user)" (click)="deactivate(user)">
                Deactivate
            </button>
            <button type="button" class="btn btn-info" *ngIf="memberLogic.adminCanReactivate(user)" (click)="reactivate(user)">
                Reactivate
            </button>
        </div>
    </div>

    <div class="row user-admin-property-row">
        <div class="col-4 user-admin-property-label-div">
            <span class="user-admin-property-label">
                Is Administrator:
            </span>
        </div>
        <div class="col-2 user-admin-property-value-div">
            <span class="user-admin-property-value" [ngClass]="{'bold': user.isAdmin}">
                {{ user.isAdmin ? "Yes" : "No" }}
            </span>
        </div>
        <div class="col-4 user-admin-property-actions-div">
            <button type="button" class="btn btn-info" *ngIf="memberLogic.adminCanMakeAdmin(user)" (click)="toggleIsAdmin(user, true)">
                Make Admin
            </button>
            <button type="button" class="btn btn-info" *ngIf="memberLogic.adminCanRemoveAsAdmin(user)" (click)="toggleIsAdmin(user, false)">
                Remove as Admin
            </button>
        </div>
    </div>
</div>

<div class="credit-balance-div" *ngIf="user && user.id && user.hasBeenApproved && !user.isAuditor">
    <h4>Credit Balance History</h4>
    <div class="credit-balance-explanation">
        PD Applications go through several stages of affecting remaining credits:
        <ol>
            <li>
                When the application is first submitted with anticipated expenses, but has not yet been approved ("<b><i>Requested</i></b>")
            </li>
            <li>
                The application has been approved with anticipated expenses, but not yet with actual expenses ("<b><i>Pending</i></b>")
            </li>
            <li>
                Actual expenses have been approved ("<b><i>Actual</i></b>")
            </li>
        </ol>
        This credit balance chart shows 3 remaining balance columns. Each of these only include the cost of applications that have gone at least as far
        as that stage.<br />
        For example, a $70 application that has reached the "Pending" stage will result in that $70 being subtracted from the "Requested"
        and "Pending" balances, but not from the "Actual" balance. <br />
        Note: For the purposes of the credits available display bar, and the credits exceeded warnings when submitting an application, the system uses the
        "Pending" balance type.
    </div>
    <div class="add-credits-div" *ngIf="showAdminPanel()">
        Add credits:
        <input id="startingBalance" name="startingBalance" [(ngModel)]="creditsToAdd"
               type="number"
               [step]="0.01" />
        <button type="button" class="btn btn-info" (click)="addCredits(creditsToAdd)" [disabled]="!creditsToAdd">Add</button>
    </div>
    <table class="full-width-table credit-balance-table">
        <thead>
            <tr>
                <th>
                    Date
                </th>
                <th>
                    Label
                </th>
                <th class="amount-header">
                    Amount
                </th>
                <th>
                    Status
                </th>
                <th class="balances-header">
                    Effective Balance:
                </th>
                <th class="subheader requested-header">
                    Requested
                </th>
                <th class="subheader pending-header">
                    Pending
                </th>
                <th class="subheader spent-header">
                    Actual
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of user.ledger">
                <td>
                    {{ getDisplayDate(row.date) }}
                </td>
                <td>
                    {{ row.label }}
                </td>
                <td class="amount-cell">
                    {{ row.amount | currency:'CAD':'symbol-narrow':'1.2-2' }}
                </td>
                <td>
                    {{ row.status }}
                </td>
                <td></td>
                <td class="requested-cell">
                    {{ row.newProposedBalance | currency:'CAD':'symbol-narrow':'1.2-2' }}
                </td>
                <td class="pending-cell">
                    {{ row.newPendingBalance | currency:'CAD':'symbol-narrow':'1.2-2' }}
                </td>
                <td class="spent-cell">
                    {{ row.newSpentBalance | currency:'CAD':'symbol-narrow':'1.2-2' }}
                </td>
            </tr>
            <tr *ngIf="!user.ledger || !user.ledger.length">
                <td colspan="8">
                    No credits or applications yet.
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="credit-balance-div" *ngIf="user && user.id && user.hasBeenApproved && user.isAuditor">
    <h4>Auditor cannot alter their account</h4>
</div>

<div class="subscription-div" *ngIf="user && user.id && user.hasBeenApproved && (!user.isAuditor || showAdminPanel())">
    <span class="app-loading" *ngIf="waiting">
        <svg class="spinner" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
        </svg>
    </span>
    <div *ngIf="!waiting">
        <h4 class="subscription-header">
            Email from Elk Island Local #28
        </h4>
        Currently {{user.isSubscribed ? '' : 'NOT'}} subscribed to EIL mailings.
        <button type="button" class="btn btn-info" *ngIf="user.isSubscribed" (click)="unsubscribe(user)">
            Unsubscribe
        </button>
        <button type="button" class="btn btn-info" *ngIf="!user.isSubscribed" (click)="resubscribe(user)">
            Re-subscribe
        </button>
    </div>
</div>

<form class="page-form" #pdAccountDetailsForm="ngForm" (ngSubmit)="onSave()" *ngIf="!user.isAuditor">
    <span class="app-loading" *ngIf="waiting">
        <svg class="spinner" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
        </svg>
    </span>
    <div class="account-details" *ngIf="!waiting">
        <h4 class="account-details-header">
            Account Details
        </h4>
        <div class="lined-input-group">
            <label for="firstName">
                First Name
            </label>
            <input type="text" required class="form-control" id="firstName" name="firstName" #firstName="ngModel" [(ngModel)]="user.firstName" />
            <div *ngIf="firstName.invalid"
                 class="alert alert-danger">

                <div *ngIf="firstName.errors.required">
                    First Name is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="lastName">
                Last Name
            </label>
            <input type="text" required class="form-control" id="lastName" name="lastName" #lastName="ngModel" [(ngModel)]="user.lastName" />
            <div *ngIf="lastName.invalid"
                 class="alert alert-danger">

                <div *ngIf="lastName.errors.required">
                    Last Name is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="employmentType">
                Employment Type
            </label>
            <select required class="form-control" id="employmentType" name="employmentType" #employmentType="ngModel" [(ngModel)]="user.employmentType">
                <option value="Permanent">Permanent</option>
                <option value="Temporary">Temporary</option>
                <option value="Substitute">Substitute</option>
            </select>
            <div *ngIf="employmentType.invalid"
                 class="alert alert-danger">

                <div *ngIf="employmentType.errors.required">
                    Employment Type is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label>
                Login Email
            </label>
            <input type="text" disabled class="form-control form-control-plaintext" id="email" name="email" #email="ngModel" [(ngModel)]="user.email"
                   [pattern]="emailRegex" />
        </div>
        <div class="lined-input-group">
            <label for="email">
                Preferred Email
            </label>
            <input type="text" required class="form-control" id="preferredEmail" name="preferredEmail" #preferredEmail="ngModel" [(ngModel)]="user.preferredEmail" />
            <div *ngIf="preferredEmail.invalid"
                 class="alert alert-danger">

                <div *ngIf="preferredEmail.errors.required">
                    Preferred Email is required.
                </div>
                <div *ngIf="preferredEmail.errors.pattern">
                    Preferred Email must be a valid email address.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="teachingCertNo">
                Teaching Certificate Number
            </label>
            <input type="text" required class="form-control" id="teachingCertNo" name="teachingCertNo" #teachingCertNo="ngModel" [(ngModel)]="user.teachingCertNo" />
            <div *ngIf="teachingCertNo.invalid"
                 class="alert alert-danger">

                <div *ngIf="teachingCertNo.errors.required">
                    Teaching Certificate Number is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="address1">
                Address line 1
            </label>
            <input type="text" required class="form-control" id="address" name="address" #address="ngModel" [(ngModel)]="user.addressLine1" />
            <div *ngIf="address.invalid"
                 class="alert alert-danger">

                <div *ngIf="address.errors.required">
                    Address is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="address2">
                Address line 2
            </label>
            <input type="text" class="form-control" id="address2" name="address2" #address2="ngModel" [(ngModel)]="user.addressLine2" />
        </div>
        <div class="lined-input-group">
            <label for="city">
                City
            </label>
            <input type="text" required class="form-control" id="city" name="city" #city="ngModel" [(ngModel)]="user.city" />
            <div *ngIf="city.invalid"
                 class="alert alert-danger">

                <div *ngIf="city.errors.required">
                    City is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="postalCode">
                Postal Code
            </label>
            <input type="text" required class="form-control" id="postalCode" name="postalCode" #postalCode="ngModel" [(ngModel)]="user.postalCode"
                   [pattern]="postalCodeRegex" />
            <div *ngIf="postalCode.invalid"
                 class="alert alert-danger">

                <div *ngIf="postalCode.errors.required">
                    Postal Code is required.
                </div>
                <div *ngIf="postalCode.errors.pattern">
                    Postal Code must be valid.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="homePhone">
                Home Phone
            </label>
            <input type="text" required class="form-control" id="homePhone" name="homePhone" #homePhone="ngModel" [(ngModel)]="user.homePhone"
                   [pattern]="phoneNumberRegex" />
            <div *ngIf="homePhone.invalid"
                 class="alert alert-danger">

                <div *ngIf="homePhone.errors.required">
                    Home Phone is required.
                </div>
                <div *ngIf="homePhone.errors.pattern">
                    Home Phone must be a valid phone number.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="schoolName">
                School
            </label>
            <input type="text" required class="form-control" id="schoolName" name="schoolName" #schoolName="ngModel" [(ngModel)]="user.schoolName"
                   pattern=".{3,}" />
            <div *ngIf="schoolName.invalid"
                 class="alert alert-danger">

                <div *ngIf="schoolName.errors.required">
                    School is required.
                </div>
                <div *ngIf="schoolName.errors.pattern">
                    School cannot be less than 3 letters.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="schoolPhone">
                School Phone
            </label>
            <input type="text" required class="form-control" id="schoolPhone" name="schoolPhone" #schoolPhone="ngModel" [(ngModel)]="user.schoolPhone"
                   [pattern]="phoneNumberRegex" />
            <div *ngIf="schoolPhone.invalid"
                 class="alert alert-danger">

                <div *ngIf="schoolPhone.errors.required">
                    School Phone is required.
                </div>
                <div *ngIf="schoolPhone.errors.pattern">
                    School Phone must be a valid phone number.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="grades">
                Grade(s)
            </label>
            <input type="text" required class="form-control" id="grades" name="grades" #grades="ngModel" [(ngModel)]="user.grades" />
            <div *ngIf="grades.invalid"
                 class="alert alert-danger">

                <div *ngIf="grades.errors.required">
                    Grade(s) is required.
                </div>
            </div>
        </div>
        <div class="lined-input-group">
            <label for="subject">
                Subject(s)
            </label>
            <input type="text" required class="form-control" id="subject" name="subject" #subject="ngModel" [(ngModel)]="user.subject" />
            <div *ngIf="subject.invalid"
                 class="alert alert-danger">

                <div *ngIf="subject.errors.required">
                    Subject(s) is required.
                </div>
            </div>
        </div>

        <div class="submit-button-div">
            <button type="submit" class="btn btn-primary" [ngClass]="{'working': waiting}" [disabled]="waiting || !pdAccountDetailsForm.form.valid">Save</button>
            <div *ngIf="!waiting && !pdAccountDetailsForm.form.valid"
                 class="alert alert-danger">
                There are errors in the information entered above.
            </div>
        </div>
    </div>
</form>
