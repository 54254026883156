<div class="row full-height">
    <div class="col-lg-2
       col-md-3
       d-none d-md-block
       gutter left-gutter">
        <router-outlet name="sidenav"></router-outlet>
    </div>
    <div class="smallnav
         d-block d-md-none">
        <router-outlet name="sidenav"></router-outlet>
    </div>
    <div class="col-xl-8
       col-lg-10
       col-md-9
       col-xs-12
       main-area
       bottom">
        <!--TEMP-->
        <!--<div>
            <span class="d-inline d-sm-none">XS</span>
            <span class="d-none d-sm-inline d-md-none">SM</span>
            <span class="d-none d-md-inline d-lg-none">MD</span>
            <span class="d-none d-lg-inline d-xl-none">LG</span>
            <span class="d-none d-xl-inline">XL</span>
        </div>-->
        <router-outlet></router-outlet>
    </div>
  <div class="col-xl-2
        d-none d-xl-block
       gutter right-gutter">

  </div>
</div>



