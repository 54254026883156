import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { ServiceBase } from './base.service';
import { ITerm } from "../models/term.model";
import { ParsedError } from "./parsed-error";
import { ToastrService } from 'ngx-toastr';
import { MonitoringService } from "./monitoring.service";

@Injectable()
export class TermsService extends ServiceBase {

    constructor(protected http: HttpClient,
        protected monitoringService: MonitoringService,
        protected toastrService: ToastrService) {
        super(http, monitoringService, toastrService);
    }

    private apiUrl = 'term/';

    getMany(filter, callback: (isSuccess: boolean, result?: ITerm[], error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'GetAllForFilter';
        this.http.get<Array<ITerm>>(callUrl,
            {
                params: {
                    filter: filter
                }
            })
            .subscribe(
            result => {
                callback(true, result, null);
            },
            error => {
                // TODO
                callback(false, null, this.handleError(error, filter));
            });
    }

    getOne(id, callback: (isSuccess: boolean, result?: ITerm, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'GetOne';
        this.http.get<ITerm>(callUrl,
                {
                    params: {
                        "id": id
                    }
                })
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error, id));
                });
    }

    getImportant(callback: (isSuccess: boolean, result?: ITerm[], error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'GetImportant';
        this.http.get<Array<ITerm>>(callUrl)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error));
                });
    }

    getForDateAndAppId(date: string, appId: string, callback: (isSuccess: boolean, result?: ITerm, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'GetForDateAndAppId';
        this.http.get<ITerm>(callUrl,
                {
                    params: {
                        "date": date,
                        "appId": appId
                    }
                })
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error, {date: date, appId: appId}));
                });
    }

    save(term: ITerm, callback: (isSuccess: boolean, result?: ITerm, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'save';
        this.http.post(callUrl, term)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error, term));
                });
    }
}

