import { Component,
         forwardRef,
         OnInit, Input, Output, EventEmitter }        from '@angular/core';


import { Calculation } from '../../models/calculation.model';
import { CalculationPair } from '../../models/calculation-pair.model';
import { NG_VALUE_ACCESSOR,
         ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

export const UNIT_CALC_PAIR_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UnitcalcpairComponent),
    multi: true
};

@Component({
        selector:       'eil-unitcalcpair',
        templateUrl:    './unitcalcpair.component.html',
        styleUrls: ['./unitcalcpair.component.scss'],
        providers: [UNIT_CALC_PAIR_CONTROL_VALUE_ACCESSOR] 
    }
)
export class UnitcalcpairComponent implements OnInit {

    @Input() public amountHeader?: string;
    @Input() public amountPrefix?: string;
    @Input() public amountSuffix?: string;
    @Input() public unitCostHeader?: string;
    @Input() public unitPrefix?: string;
    @Input() public unitSuffix?: string;
    @Input() public totalHeader?: string;
    @Input() public unitCostEditable?: boolean;
    @Output() public change = new EventEmitter<CalculationPair>();
    @Input() public disabled?: boolean;
    @Input() public actual?: boolean;

    // The internal data model
    calcPair: CalculationPair = {
        actual: {},
        anticipated: {}
    };

    // Databinding the calc control
    get boundCalc(): Calculation {
        if (!this.calcPair) { return null; }

        return this.actual
            ? this.calcPair.actual
            : this.calcPair.anticipated;
    }
    set boundCalc(calc: Calculation) {
        if (!this.calcPair) { this.calcPair = CalculationPair.newDefault(); }

        if (this.actual) {
            this.calcPair.actual = calc;
        } else {
            this.calcPair.anticipated = calc;
        }
    }

    // Placeholders for the callbacks which are later provided
    // by the Control Value Accessor
    onTouchedCallback: () => void = noop;
    onChangeCallback: (_: any) => void = noop;

    // get accessor
    get value(): CalculationPair {
        return this.calcPair;
    }

    // set accessor including call the onchange callback
    set value(v: CalculationPair) {
        if (v !== this.calcPair) {
            this.calcPair = v;
            this.onChangeCallback(v);
        }
    }

    // Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    // From ControlValueAccessor interface
    writeValue(value: CalculationPair) {
        if (value !== this.calcPair) {
            this.calcPair = value;
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    constructor() { 

    }

    ngOnInit() {

    }

    isExceeded() {
        let anticipated = 0;
        let actual = 0;
        if (this.calcPair && this.calcPair.anticipated && this.calcPair.anticipated.total) {
            anticipated = parseFloat(this.calcPair.anticipated.total);
        }
        if (this.calcPair && this.calcPair.actual && this.calcPair.actual.total) {
            actual = parseFloat(this.calcPair.actual.total);
        }
        return actual > anticipated;
    }
}
