import { Component } from '@angular/core';

@Component({
    selector: 'eil-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
}
)
export class AppComponent {
    title = 'app';
    showNav = true;
    currentPos = 0;
    lastTimeNegative = false;
    ngOnInit() {

        var container = document.getElementById("container");
        //container.addEventListener('touchend', this.displayNavBarMobile);
    }

    showNavBar(event) {
        if (window.screen.width < 900 && event.deltaY > 0) {
            this.showNav = false;
        }
        else {
            this.showNav = true;
        }
    }

    displayNavBarMobile(event) {
        // Last time negative is used since finger swipes can be a bit finicky
        var dif = event.changedTouches[0].pageY - this.currentPos;
        if (dif < 0) {
            if (this.lastTimeNegative) {
                this.showNav = false;
            }
            this.lastTimeNegative = true;
        }
        else if (dif > 0) {
            if (!this.lastTimeNegative) {
                this.showNav = true;
            }
            this.lastTimeNegative = false;
        }
        this.currentPos = event.changedTouches[0].pageY;
    }

}

