export class Calculation {
    amount?: number;
    unitCost?: number;
    total?: string;

    static newDefault(): Calculation {
        return {
             total: "0"
        };
    }
}
