import { Component, 
         OnInit }               from '@angular/core';
import { CurrentUserService }   from "../../services/current-user.service";
import { IUserDetail }          from "../../models/user-detail.model";

@Component({
        selector:       'eil-user-menu',
        templateUrl:    './user-menu.component.html',
        styleUrls:      ['./user-menu.component.scss']
    }
)
export class UserMenuComponent implements OnInit {

    currentUser: IUserDetail;

    constructor(private currentUserService: CurrentUserService) { 
        
    }

    ngOnInit() {
        this.currentUserService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }

}
