<div class="application-list">
    <div class="row event-item" *ngFor="let pdApp of pdApps">
        <div class="col-sm-7 col-lg-9">
            <span class="event-title">
                <a [routerLink]="[linkToEdit + pdApp.id]">
                    {{pdApp.event.name}}
                </a>
            </span>
            <span class="event-date">
                {{getEventRange(pdApp.event)}}
            </span>
        </div>
        <div class="col-sm-5 col-lg-3 event-status-div">
            <span class="event-status {{pdApp.status.toLowerCase()}}">
                {{pdApp.status}}
            </span>

            <div class="event-amount">
                {{ (expensesAreActual(pdApp) ? pdApp.actualTotal : pdApp.anticipatedTotal) | currency:'CAD':'symbol-narrow':'1.2-2' }}
            </div>
        </div>
    </div>
    <div *ngIf="loading">
        <span class="app-loading">
            <svg class="spinner" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
            </svg>
        </span>
    </div>
    <div *ngIf="!loading && (!pdApps || !pdApps.length) && !currentUser.isAuditor">
        You have no applications yet.
    </div>
    <div *ngIf="!loading && (!pdApps || !pdApps.length) && currentUser.isAuditor">
        Auditors cannot create applications
    </div>
</div>
