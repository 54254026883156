import { Calculation } from "./calculation.model";

export class CalculationPair {
    anticipated?: Calculation;
    actual?: Calculation;

    static newDefault(): CalculationPair {
        return {
            anticipated: Calculation.newDefault(),
            actual: Calculation.newDefault()
        };
    }
}
