<div class="app-list">
    <div class="lined-input-group filters">
        <input type="text" class="form-control app-filter" [value]="searchText" (input)="searchText = $event.target.value" placeholder="Search: Event or Member" />
        <select class="form-control app-filter state-filter" (change)="filterStatus = $event.target.value">
            <option value="" disabled hidden>Select State</option>
            <option value="">All</option>
            <option value="Approved">Approved</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Declined">Declined</option>
            <option value="ExpensesApproved">Expenses Approved</option>
            <option value="ExpensesDue">Expenses Due</option>
            <option value="ExpensesOverdue">Expenses Overdue</option>
            <option value="ExpensesSubmitted">Expenses Submitted</option>
            <option value="Submitted">Submitted</option>
        </select>
    </div>
    <table class="list-table app-list-table">
        <thead>
            <tr>
                <th class="header" [class.active]="order === 'event.name'" (click)="setOrder('event.name')">
                    Event<span [hidden]="reverse">▼</span>
                    <span [hidden]="!reverse">▲</span>
                </th>
                <th class="header" [class.active]="order === 'created'" (click)="setOrder('created')">
                    Created<span [hidden]="reverse">▼</span>
                    <span [hidden]="!reverse">▲</span>
                </th>
                <th class="header" [class.active]="order === 'applicantName'" (click)="setOrder('applicantName')">
                    Member<span [hidden]="reverse">▼</span>
                    <span [hidden]="!reverse">▲</span>
                </th>
                <th class="header" [class.active]="order === 'displayTotal'" (click)="setOrder('displayTotal')">
                    Expense<span [hidden]="reverse">▼</span>
                    <span [hidden]="!reverse">▲</span>
                </th>
                <th class="header" [class.active]="order === 'status'" (click)="setOrder('status')">
                    State<span [hidden]="reverse">▼</span>
                    <span [hidden]="!reverse">▲</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="loading">
                <td colspan="5">
                    <span class="app-loading">
                        <svg class="spinner" viewBox="25 25 50 50">
                            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                        </svg>
                    </span>
                </td>
            </tr>
            <tr class="list-row" *ngFor="let pdApp of pdApps|orderBy: this.order:reverse:true |searchFilter: searchText |statusFilter: filterStatus |paginate: {itemsPerPage: 20, currentPage: this.tablePage}">
                <td>
                    <a [routerLink]="[linkToEdit + pdApp.id]">
                        {{pdApp.event.name}}
                    </a>
                </td>
                <td title="{{ pdApp.created }}">
                    {{ getAgo(pdApp.created) }}
                </td>
                <td>
                    <span title="{{pdApp.applicantName}}">
                        {{pdApp.applicantName}}
                    </span>
                </td>
                <td>
                    ${{pdApp.displayTotal}}
                </td>
                <td>
                    {{pdApp.status}}
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="!loading && (!pdApps || !pdApps.length)">
        No applications match the current filters.
    </div>
    <pagination-controls (pageChange)="this.tablePage = $event"></pagination-controls>
    <div class="row right">
        <span>
            Have some time to wait?
            <button (click)="loadAllApps()">Load historical PD's</button>
        </span>
    </div>
</div>

