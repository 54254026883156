<div *ngIf="loadingPDApp">
    <span class="app-loading">
        <svg class="spinner" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
        </svg>
    </span>
</div>
<form class="page-form" #pdAppForm="ngForm" *ngIf="!loadingPDApp" (ngSubmit)="onSubmit(principalConfirmTemplate)">
    <h2 *ngIf="state.messageAboutState">
        {{ state.messageAboutState }}
    </h2>

    <div class="personal-details" *ngIf="state.showUserDetails">
        <div class="row">
            <div class="col-12 personal-details-header-col">
                <h3 class="personal-details-header">
                    Personal Details
                </h3>
            </div>
            <div class="col-10 personal-details-help-col" *ngIf="state.canEditUserDetails">
                <p class="help-block">
                    This is what we have on file for you. Ensure it is up to date before starting your application.
                </p>
            </div>
            <div class="col-2 personal-details-editbtn-col" *ngIf="state.canEditUserDetails">
                <a [routerLink]="['/pd/account-detail']">
                    Edit
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6">

                <div class="form-group">
                    <label>
                        Name
                    </label>
                    <p class="form-control-static">
                        {{ pdApp.applicant.firstName }} {{ pdApp.applicant.lastName }}
                    </p>
                </div>

                <div class="form-group">
                    <label>
                        Address
                    </label>
                    <p class="form-control-static">
                        {{ pdApp.applicant.addressLine1 }}
                        <br *ngIf="currentUser.addressLine2" />
                        {{ pdApp.applicant.addressLine2 }}<br />
                        {{ pdApp.applicant.city }} {{ pdApp.applicant.postalCode }}
                    </p>
                </div>

                <div class="form-group">
                    <label>
                        Home Phone
                    </label>
                    <p class="form-control-static">
                        {{ pdApp.applicant.homePhone }}
                    </p>
                </div>

                <div class="form-group">
                    <label>
                        Email
                    </label>
                    <p *ngIf="pdApp.applicant.preferredEmail != pdApp.applicant.email" class="form-control-static">
                        <span title="user is registered as {{ pdApp.applicant.email }}">{{ pdApp.applicant.preferredEmail }}*</span>
                    </p>
                    <p *ngIf="pdApp.applicant.preferredEmail == pdApp.applicant.email" class="form-control-static">
                        {{ pdApp.applicant.email }}
                    </p>
                </div>
            </div>

            <div class="col-12 col-sm-6">

                <div class="form-group">
                    <label>
                        School
                    </label>
                    <p class="form-control-static">
                        {{ pdApp.applicant.schoolName }}
                        <br *ngIf="currentUser.schoolName && currentUser.schoolPhone" />
                        {{ pdApp.applicant.schoolPhone }}
                    </p>
                </div>

                <div class="form-group">
                    <label>
                        Employment Type
                    </label>
                    <p class="form-control-static">
                        {{ pdApp.applicant.employmentType }}
                    </p>
                </div>

                <div class="form-group">
                    <label>
                        Grade(s)
                    </label>
                    <p class="form-control-static">
                        {{ pdApp.applicant.grades }}
                    </p>
                </div>

                <div class="form-group">
                    <label>
                        Subject
                    </label>
                    <p class="form-control-static">
                        {{ pdApp.applicant.subject }}
                    </p>
                </div>

                <div class="form-group">
                    <label>
                        Teaching Certificate Number
                    </label>
                    <p class="form-control-static">
                        {{ pdApp.applicant.teachingCertNo }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="event-details customPrintPageAvoid">
        <h3 class="event-details-header">
            Event Details
        </h3>
        <!--<div>
            TODO: select from list or pick new event
        </div>-->
        <div class="create-new-event-div">
            <p class="help-block event-details-help-block" *ngIf="state.canEditEventDetails">
                Please Enter Event Details
            </p>
            <div class="lined-input-group">
                <label for="eventName">
                   Event Name
                </label>
                <input type="text" required class="form-control" id="eventName" name="eventName" #eventName="ngModel" [(ngModel)]="pdApp.event.name"
                       [disabled]="!state.canEditEventDetails" [ngClass]="{'form-control-plaintext': !state.canEditEventDetails}" />
                <div *ngIf="eventName.invalid && (eventName.dirty || eventName.touched)"
                     class="alert alert-danger">

                    <div *ngIf="eventName.errors.required">
                        Event name is required.
                    </div>
                </div>
            </div>
            <div class="lined-input-group">
                <label for="eventType">
                    Event Type
                </label>
                <select required class="form-control" id="eventType" name="eventType" #eventType="ngModel" [(ngModel)]="pdApp.event.type"
                        [disabled]="!state.canEditEventDetails" [ngClass]="{'form-control-plaintext': !state.canEditEventDetails}">
                    <option value="Conference">Conference</option>
                    <option value="Workshop">Workshop</option>
                    <option value="Non-credit Course">Non-credit Course</option>
                    <option value="Other">Other</option>
                </select>
                <div *ngIf="eventType.invalid && (eventType.dirty || eventType.touched)"
                     class="alert alert-danger">

                    <div *ngIf="eventType.errors.required">
                        Event Type is required.
                    </div>
                </div>
            </div>
            <div class="lined-input-group">
                <label for="eventDescription">
                   Event Description
                </label>
                <textarea class="form-control" required rows="2" id="eventDescription" name="eventDescription"
                          #eventDescription="ngModel" [(ngModel)]="pdApp.event.description"
                          [disabled]="!state.canEditEventDetails" [ngClass]="{'form-control-plaintext': !state.canEditEventDetails}"></textarea>
                <div *ngIf="eventDescription.invalid && (eventDescription.dirty || eventDescription.touched)"
                     class="alert alert-danger">

                    <div *ngIf="eventDescription.errors.required">
                        Event description is required.
                    </div>
                </div>
            </div>
            <div class="lined-input-group">
                <label for="eventLocation">
                   Event Location
                </label>
                <input type="text" required class="form-control" id="eventLocation" name="eventLocation"
                       #eventLocation="ngModel" [(ngModel)]="pdApp.event.location"
                       [disabled]="!state.canEditEventDetails" [ngClass]="{'form-control-plaintext': !state.canEditEventDetails}" />
                <div *ngIf="eventLocation.invalid && (eventLocation.dirty || eventLocation.touched)"
                     class="alert alert-danger">

                    <div *ngIf="eventLocation.errors.required">
                        Event Location is required.
                    </div>
                </div>
            </div>
            <hr />
            <div class="lined-input-group" [hidden]="this.nonContinuousExists()">
                <label for="startDateInput">
                    Event Start Date
                </label>
                <input type="text"
                       id="startDateInput"
                       name="eventStartDate"
                       class="form-control"
                       [hidden]="this.pdApp.event.useNonContinuousDate"
                       #startDatePicker="bsDatepicker"
                       #eventStartDate="ngModel"
                       bsDatepicker
                       required
                       [(ngModel)]="pdApp.event.startDate"
                       [disabled]="!state.canEditEventDetails" [ngClass]="{'form-control-plaintext': !state.canEditEventDetails}"
                       ngOnChanges>
                <div *ngIf="eventStartDate.invalid && (eventStartDate.dirty || eventStartDate.touched)"
                     class="alert alert-danger">

                    <div *ngIf="eventStartDate.errors.required">
                        Event Start Date is required.
                    </div>
                </div>
            </div>
            <div class="lined-input-group" [hidden]="this.pdApp.event.useNonContinuousDate">
                <label for="endDateInput">
                   Event End Date
                </label>
                <input type="text"
                       id="endDateInput"
                       name="eventEndDate"
                       class="form-control"
                       #endDatePicker="bsDatepicker"
                       #eventEndDate="ngModel"
                       bsDatepicker
                       required
                       [(ngModel)]="pdApp.event.endDate"
                       [disabled]="!state.canEditEventDetails" [ngClass]="{'form-control-plaintext': !state.canEditEventDetails}">
                <div *ngIf="eventEndDate.invalid && (eventEndDate.dirty || eventEndDate.touched)"
                     class="alert alert-danger">

                    <div *ngIf="eventEndDate.errors.required">
                        Event End Date is required.
                    </div>
                </div>
            </div>
            <div class="lined-input-group">
                <label class="doNotPrint" [hidden]="!this.pdApp.event.useNonContinuousDate || !this.nonContinuousExists()">
                    Select Dates
                </label>
                <p class="help-block" [hidden]="(!this.pdApp.event.useNonContinuousDate && this.nonContinuousExists())">
                    {{this.dateHelpText}}
                </p>
                <bs-datepicker-inline id="nonContinuousDateInput"
                    class="doNotPrint"
                    [hidden]="!this.pdApp.event.useNonContinuousDate || !this.nonContinuousExists() || currentUser.isAuditor"
                    (bsValueChange)="onCalendarSelect($event)"
                    [dateCustomClasses]="dateCustomClasses"
                    [datesEnabled]="state.canEditEventDetails ? null : datesEnabled"></bs-datepicker-inline>
                <p [hidden]="!this.pdApp.event.useNonContinuousDate || !this.nonContinuousExists()">
                    Selected Dates: {{
this.getDates()
                    }}
                </p>
                <div *ngIf="!this.nonContinuousExists() && this.pdApp.event.useNonContinuousDate"
                     class="alert alert-danger">
                    Old Applications can not select non-continuous dates
                </div>
            </div>
            <hr />
            <p class="help-block additional-event-info-help-block">
                {{this.conferenceInfoText}}
            </p>
            <div class="lined-input-group customPrintPageBefore">
                <label for="eventUrl">
                    Event URL
                </label>
                <input *ngIf="state.canEditEventDetails" type="text" class="form-control" id="eventUrl" name="eventUrl" [(ngModel)]="pdApp.event.eventUrl" />
                <a class="form-control-plaintext" *ngIf="!state.canEditEventDetails" href="{{pdApp.event.eventUrl}}" target="_blank">
                    {{ pdApp.event.eventUrl }}
                </a>
            </div>
            <div>
                Upload Conference Documents
                <eil-file-upload [(ngModel)]="pdApp.event.files" [ngModelOptions]="{standalone: true}"
                                 [disabled]="!state.canEditEventDetails"></eil-file-upload>
            </div>
        </div>
    </div>

    <div class="explanation customPrintPageAvoid" *ngIf="state.showExplanation">
        <h3 class="explanation-header">
            Explanation
        </h3>
        <div class="form-group lined-input-group">
            <p class="help-block">
                {{this.pdExplanationHelpText}}
            </p>
            <textarea class="form-control" id="explanationText" rows="4" name="explanationText"
                      #explanationText="ngModel" required [(ngModel)]="pdApp.explanation"
                      [disabled]="!state.canEditExplanation" [ngClass]="{'form-control-plaintext': !state.canEditExplanation}"></textarea>
            <div *ngIf="explanationText.invalid && (explanationText.dirty || explanationText.touched)"
                 class="alert alert-danger">
                <div *ngIf="explanationText.errors.required">
                    Explanation is required.
                </div>
            </div>
        </div>
    </div>
    <div class="receipts customPrintPageAvoid" *ngIf="state.showExpenseReceipts">
        <h3 class="explanation-header">
            Receipts
        </h3>
        <div>
            <eil-file-upload [(ngModel)]="pdApp.receipts" [ngModelOptions]="{standalone: true}"
                             [disabled]="!state.canUploadExpenseReceipts" [blobConfig]="blobConfig"></eil-file-upload>
        </div>
    </div>
    <div class="sub-names doNotPrint" *ngIf="state.showSubNames">
        <h3 class="explanation-header">
            Sub Name(s), please enter for each sub which date(s) they were needed.
        </h3>
        <p [hidden]="!this.pdApp.event.useNonContinuousDate || !this.nonContinuousExists()">
            Selected Dates: {{
this.getDates()
            }}
        </p>
        <div class="form-group lined-input-group">
            <input type="text" [required]="subNamesRequired()" class="form-control" id="subNames" name="subNames"
                   #subNames="ngModel" [(ngModel)]="pdApp.subNames"
                   [disabled]="!state.canProvideSubNames" [ngClass]="{'form-control-plaintext': !state.canProvideSubNames}" />
            <div *ngIf="subNames.invalid && (subNames.dirty || subNames.touched)"
                 class="alert alert-danger">

                <div *ngIf="subNames.errors.required">
                    Substitute Names are required if any substitute time is listed below.
                </div>
            </div>
        </div>
    </div>

    <div class="expenses">
        <h3 class="expenses-header">
            {{ state.expensesAreActual ? 'Actual' : 'Anticipated'}} Expenses
        </h3>

        <div class="substitute-expenses">
            <h5 class="substitute-header">
                Substitute Expenses
            </h5>
            <p class="substitute-blurb help-block customPrintPageAvoid">
                {{this.substituteHelpText}}
            </p>
            <p class="substitute-blurb help-block customPrintPageAvoid">
                <div class="days-1-to-3 customPrintPageAvoid">
                    <h6>
                        Days 1, 2 &amp; 3
                    </h6>
                    <div class="lined-input-group">
                        <div class="row">
                            <div class="col-lg-4 col-12">
                                <label for="halfDaysUnitCalc">
                                    Number of 1/2 days
                                </label>
                            </div>
                            <div class="col-lg-8 col-12">
                                <eil-unitcalcpair [unitPrefix]="'$'"
                                                  #halfDaysUnitCalc name="halfDaysUnitCalc"
                                                  [(ngModel)]="pdApp.halfDaysCalculation" [ngModelOptions]="{standalone: true}"
                                                  [actual]="state.expensesAreActual"
                                                  [disabled]="!state.canEditExpenses"
                                                  (change)="updateTotal()"></eil-unitcalcpair>
                            </div>
                        </div>
                    </div>
                    <div class="lined-input-group">
                        <div class="row">
                            <div class="col-lg-4 col-12">
                                <label for="fullDaysUnitCalc">
                                    Number of full days
                                </label>
                            </div>
                            <div class="col-lg-8 col-12">
                                <eil-unitcalcpair [unitPrefix]="'$'"
                                                  #fullDaysUnitCalc name="fullDaysUnitCalc"
                                                  [(ngModel)]="pdApp.fullDaysCalculation" [ngModelOptions]="{standalone: true}"
                                                  [actual]="state.expensesAreActual"
                                                  [disabled]="!state.canEditExpenses"
                                                  (change)="updateTotal()"></eil-unitcalcpair>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="days-4-and-later customPrintPageAvoid">
                    <h6>
                        Days 4 and later (at grid rates)
                    </h6>
            <p class="days-4-and-later-note help-block">
                up to the maximum rate as indicated, depending upon substitute's experience
            </p>
            <div class="lined-input-group">
                <div class="row">
                    <div class="col-lg-4 col-12">
                        <label for="gridDaysUnitCalc">
                            Number of grid days
                        </label>
                    </div>
                    <div class="col-lg-8 col-12">
                        <eil-unitcalcpair [unitPrefix]="'$'"
                                          #gridDaysUnitCalc name="gridDaysUnitCalc"
                                          [unitCostEditable]="state.gridUnitCostEditable"
                                          [(ngModel)]="pdApp.gridDaysCalculation" [ngModelOptions]="{standalone: true}"
                                          [actual]="state.expensesAreActual"
                                          [disabled]="!state.canEditExpenses"
                                          (change)="updateTotal()"></eil-unitcalcpair>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="registration-expenses customPrintPageAvoid">
        <h5 class="registration-expenses-header">
            Registration Expenses
        </h5>
        <div class="lined-input-group">
            <div class="row">
                <div class="col-lg-4 col-12">
                    <label for="registrationFee">
                        Registration Fee
                    </label>
                </div>
                <div class="col-lg-8 col-12">
                    <div class="right-align">
                        <eil-amountpair [amountPrefix]="'$'"
                                        [(ngModel)]="pdApp.registrationFee" [ngModelOptions]="{standalone: true}"
                                        [placeholder]="0"
                                        [actual]="state.expensesAreActual"
                                        [disabled]="!state.canEditExpenses"
                                        (change)="updateTotal()"></eil-amountpair>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="travel-expenses customPrintPageAvoid">
        <h5 class="travel-expenses-header">
            Travel Expenses
        </h5>
        <div class="row">
            <div class="col-lg-3 col-12">
                <label for="carAllowanceUnitCalc">
                    Car Allowance
                </label>
                <p class="mileage-note help-block">{{this.mileageHelpText}}</p>
            </div>
            <div class="col-lg-9 col-12 lined-input-group">

                <eil-unitcalcpair [unitPrefix]="'$'"
                                  #carAllowanceUnitCalc name="carAllowanceUnitCalc"
                                  [(ngModel)]="pdApp.carAllowanceCalculation" [ngModelOptions]="{standalone: true}"
                                  [actual]="state.expensesAreActual"
                                  [disabled]="!state.canEditExpenses"
                                  (change)="updateTotal()"></eil-unitcalcpair>
            </div>
        </div>
        <div class="row customPrintPageAvoid">
            <div class="col-3">
                <label for="flightsCost">
                    Flights
                </label>
            </div>

            <div class="col-9 lined-input-group right-align">
                <eil-amountpair [amountPrefix]="'$'"
                                [(ngModel)]="pdApp.flightsCost" [ngModelOptions]="{standalone: true}"
                                [actual]="state.expensesAreActual"
                                [placeholder]="0"
                                [step]="0.01"
                                [min]="0"
                                [disabled]="!state.canEditExpenses"
                                (change)="updateTotal()"></eil-amountpair>
            </div>
        </div>
        <div class="row customPrintPageAvoid">
            <div class="col-3">
                <label for="parkingCost">
                    Parking
                </label>
            </div>
            <div class="col-9 lined-input-group right-align">
                <eil-amountpair [amountPrefix]="'$'"
                                [(ngModel)]="pdApp.parkingCost" [ngModelOptions]="{standalone: true}"
                                [actual]="state.expensesAreActual"
                                [placeholder]="0"
                                [step]="0.01"
                                [min]="0"
                                [disabled]="!state.canEditExpenses"
                                (change)="updateTotal()"></eil-amountpair>
            </div>
        </div>
        <div class="row customPrintPageAvoid">
            <div class="col-3">
                <label for="carRentalCost">
                    Transportation
                </label>
            </div>
            <div class="col-9 lined-input-group right-align">
                <eil-amountpair [amountPrefix]="'$'"
                                [(ngModel)]="pdApp.carRentalCost" [ngModelOptions]="{standalone: true}"
                                [actual]="state.expensesAreActual"
                                [placeholder]="0"
                                [step]="0.01"
                                [min]="0"
                                [disabled]="!state.canEditExpenses"
                                (change)="updateTotal()"
                                (keyup)="updateTotal()"></eil-amountpair>
            </div>
        </div>
        <div>
            <h5>
                Other
            </h5>
            <div class="row customPrintPageAvoid">
                <div class="col-3">
                    <label for="otherDescriptionId">
                        Description
                    </label>
                </div>
                <div class="col-9 pl-0 lined-input-group right-align">
                    <input id="otherDescriptionId" class="other-description" type="text" name="otherDescription"
                           [disabled]="!state.canEditExpenses" [ngClass]="{'form-control-plaintext': formIsReadonly}"
                           [(ngModel)]="pdApp.otherDescription" placeholder="{{ formIsReadonly ? '' : 'Description'}}" #otherDescriptionBox />
                </div>
            </div>
            <div class="row customPrintPageAvoid">
                <div class="col-3">
                    <label for="otherCost">
                        Cost
                    </label>
                </div>
                <div class="col-9 lined-input-group right-align">
                    <eil-amountpair [amountPrefix]="'$'"
                                    [(ngModel)]="pdApp.otherCost" [ngModelOptions]="{standalone: true}"
                                    [actual]="state.expensesAreActual"
                                    [placeholder]="0"
                                    [step]="0.01"
                                    [min]="0"
                                    [disabled]="!state.canEditExpenses"
                                    (change)="updateTotal()"
                                    (keyup)="updateTotal()"></eil-amountpair>
                </div>
            </div>
        </div>
    </div>

    <div class="accommodation-expenses customPrintPageAvoid">
        <h5 class="accommodation-expenses-header">
            Accommodation Expenses
        </h5>
        <div class="lined-input-group">
            <eil-unitcalcpair [unitPrefix]="'$'"
                              [unitCostEditable]="true"
                              #accommodationUnitCalc
                              [(ngModel)]="pdApp.accommodationCalculation" [ngModelOptions]="{standalone: true}"
                              [actual]="state.expensesAreActual"
                              [disabled]="!state.canEditExpenses"
                              (change)="updateTotal()"></eil-unitcalcpair>
            <p class="help-block accomodations-help">Number of days x Room cost per night</p>
            <div *ngIf="accomodationUnitCostExceedsMax()"
                 class="alert alert-danger">
                <div>
                    Accommodation cost per night has a maximum of {{ maxAccommodationPricePerNight | currency:'CAD':'symbol-narrow':'1.2-2' }}
                </div>
            </div>
            <div *ngIf="accomodationTotalExceedsMax()"
                 class="alert alert-danger">
                <div>
                    Cannot apply for more than 25 days of accomodation
                </div>
            </div>
        </div>
    </div>

    <div class="subsistence-expenses customPrintPageAvoid">
        <h5 class="subsistence-expenses-header">
            Subsistence Expenses
        </h5>
        <p class="subsistence-expense-note help-block">{{this.subsistenceHelpText}}</p>
        <div class="lined-input-group">
            <eil-unitcalcpair [unitPrefix]="'$'"
                              #subsistenceUnitCalc
                              [(ngModel)]="pdApp.subsistenceCalculation" [ngModelOptions]="{standalone: true}"
                              [actual]="state.expensesAreActual"
                              [disabled]="!state.canEditExpenses"
                              [unitCostEditable]="state.canEditExpenses"
                              (change)="updateTotal()"></eil-unitcalcpair>
            <div class="alert alert-danger" *ngIf="subsistencePastMax()">
                Subsistence cannot exceed the maximimum amount per day of ${{this.maxSubsistencePerDay}}
            </div>
        </div>
    </div>

    <div class="expenses-total customPrintPageAvoid">
        <div class="anticipated-calc" *ngIf="state.expensesAreActual" [ngClass]="{'exceeded': actualExceedsAnticipated()}">
            <span class="anticipated-text">
                Anticipated:
            </span>
            <span class="total-text">
                ${{pdApp.anticipatedTotal}}
            </span>
        </div>
        <div class="total" [ngClass]="{'excess': anticipatedExceedsCredits()}">
            ${{ state.expensesAreActual ? pdApp.actualTotal : pdApp.anticipatedTotal }}
        </div>
        <div class="total-explanation">
            Total {{ state.expensesAreActual ? 'Actual' : 'Anticipated' }} Expenses
        </div>
        <div *ngIf="anticipatedExceedsCredits()" class="exceeds-credits">
            Exceeds available credits
        </div>
    </div>
    </div>

    <!--<div class="summary">
        <h3 class="summary-header">
            Summary
        </h3>
        <p class="help-block">
            Pending approval; values here are subject to change
        </p>
        TODO: I actually don't understand how this was meant to work; ask Stephen
    </div>-->

    <div class="doNotPrint">
        <h3>Funding Term ({{ getDateRange(term.startDate, term.endDate) }})</h3>
        <div class="allocation-bar">
            <div *ngIf="loadingFundTerm">
                <span class="app-loading">
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                    </svg>
                </span>
            </div>
            <app-eil-color-bar *ngIf="!loadingFundTerm && term"
                               [spent]="term.totalSpent"
                               [pending]="term.totalPending"
                               [requested]="term.totalProposed"
                               [selected]="term.totalSelected"
                               [selectedIsActual]="state.expensesAreActual"
                               [selectedIsApproved]="state.ancicipatedIsApproved"
                               [ceiling]="term.startingBalance"
                               [showCeilingLabel]="true"
                               [showLegend]="true"
                               [showRemaining]="true">

            </app-eil-color-bar>
        </div>
    </div>
    <div class="doNotPrint">
        <h3>Credits Available</h3>
        <div class="allocation-bar">
            <eil-color-bar [selected]="term.totalSelected"
                           [isCreditsMode]="true"
                           [selectedIsActual]="state.expensesAreActual"
                           [selectedIsApproved]="state.ancicipatedIsApproved"
                           [available]="pdApp.applicant.unpendingBalance"
                           [pending]="pdApp.applicant.unspentBalance - pdApp.applicant.unpendingBalance"
                           [ceiling]="maximumAllowedCredits"
                           [showCeilingLabel]="true"
                           [showCreditAllocationExplanation]="true"
                           [showLegend]="true">

            </eil-color-bar>
        </div>
    </div>

    <div class="comments customPrintPageAvoid">
        <h3 class="comments-header">
            Additional Comments
        </h3>
        <div class="form-group lined-input-group">
            <textarea class="form-control" id="additionalCommentText" rows="4" name="additionalCommentText"
                      [disabled]="!state.canEditAdditionalComments" [ngClass]="{'form-control-plaintext': !state.canEditAdditionalComments}"
                      [(ngModel)]="pdApp.additionalComments"></textarea>
        </div>
    </div>

    <div *ngIf="!state.canApproveOrDeclineApp && pdApp.applicationApprovalComments">
        <h4>
            Application Approval Comments:
        </h4>
        <textarea class="form-control form-control-plaintext" id="applicationApprovalComments" rows="4" name="applicationApprovalComments"
                  [disabled]="true" [(ngModel)]="pdApp.applicationApprovalComments"></textarea>
    </div>

    <div class="submit-button-div" *ngIf="state.canSubmit">
        <div *ngIf="savingPDApp">
            <span class="app-loading">
                <svg class="spinner" viewBox="25 25 50 50">
                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
            </span>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="!pdAppForm.form.valid || savingPDApp || accomodationUnitCostExceedsMax() || anticipatedExceedsCredits() || subsistencePastMax() || accomodationTotalExceedsMax() || !termExists() || actualExceedsMaxSubmittable()">
            {{ state.submitText }}
        </button>
        <button *ngIf="state.canCancelApplication" type="button" class="btn btn-primary red-no-border-button" (click)="cancel()">
            Cancel Application
        </button>
        <div>
            <button class="btn btn-primary" type="button" (click)="print()">Print Application</button>
        </div>
        <div *ngIf="pdAppForm.invalid || !pdApp.explanation || accomodationUnitCostExceedsMax() || subDatesExceedTotalDates() || anticipatedExceedsCredits() || subsistencePastMax() || accomodationTotalExceedsMax() || !termExists() || actualExceedsMaxSubmittable()" class="alert alert-danger">
            <div *ngIf="eventName.invalid && eventName.errors.required">
                Event Name is required.
            </div>
            <div *ngIf="eventDescription.invalid && eventDescription.errors.required">
                Event Description is required.
            </div>
            <div *ngIf="eventLocation.invalid && eventLocation.errors.required">
                Event Location is required.
            </div>
            <div *ngIf="eventStartDate.invalid && eventStartDate.errors.required">
                Event Start Date is required.
            </div>
            <div *ngIf="eventEndDate.invalid && eventEndDate.errors.required">
                Event End Date is required.
            </div>
            <div *ngIf="!pdApp.explanation">
                Explanation is required.
            </div>
            <div *ngIf="subDatesExceedTotalDates()">
                Requested more sub dates than exist on the application
            </div>
            <div *ngIf="accomodationUnitCostExceedsMax()">
                Accommodation cost per night has a maximum of {{ maxAccommodationPricePerNight | currency:'CAD':'symbol-narrow':'1.2-2' }}
            </div>
            <div *ngIf="accomodationTotalExceedsMax()">
                Cannot apply for more than 25 days of accomodation
            </div>
            <div *ngIf="anticipatedExceedsCredits()">
                Cannot submit an application that requests more than you have credit for. Please adjust your application before submission
            </div>
            <div *ngIf="subsistencePastMax()">
                Subsistence cannot exceed the maximimum amount per day
            </div>
            <div *ngIf="!termExists()">
                No fund term exists for this event.
            </div>
            <div *ngIf="actualExceedsMaxSubmittable()">
                The amount cannot exceed the maximum claim amount of {{ maxExpensesSubmitable | currency:'CAD':'symbol-narrow':'1.2-2' }}
            </div>
        </div>
    </div>

    <div class="review" *ngIf="state.canApproveOrDeclineApp">
        <h3 class="review-header">
            Approve Application
        </h3>
        <div class="adjudicate-button-div">
            <div *ngIf="savingPDApp">
                <span class="app-loading">
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                    </svg>
                </span>
            </div>
            <label for="applicationApprovalComments">
                Application Approval Comments
            </label>
            <textarea class="form-control" id="applicationApprovalComments" rows="4" name="applicationApprovalComments"
                      [disabled]="!state.canApproveOrDeclineApp" [ngClass]="{'form-control-plaintext': !state.canApproveOrDeclineApp}"
                      [(ngModel)]="pdApp.applicationApprovalComments"></textarea>
            <button type="button" class="btn btn-primary approve doNotPrint" (click)="approve()" [disabled]="savingPDApp">
                Approve
            </button>
            <button type="button" class="btn btn-primary red-no-border-button doNotPrint" (click)="decline()" [disabled]="savingPDApp">
                Decline
            </button>
            <button *ngIf="state.canCancelApplication" type="button" class="btn btn-primary red-no-border-button doNotPrint" (click)="cancel()">
                Cancel Application
            </button>
            <div>
                <button class="btn btn-primary doNotPrint" type="button" (click)="print()">Print Application</button>
            </div>
        </div>
    </div>

    <div *ngIf="!state.canApproveOrDeclineExpenses && pdApp.expenseApprovalComments">
        <h5>
            Expense Approval Comments:
        </h5>
        <textarea class="form-control form-control-plaintext" id="expenseApprovalComments" rows="4" name="expenseApprovalComments"
                  [disabled]="true" [(ngModel)]="pdApp.expenseApprovalComments"></textarea>
    </div>

    <div class="submit-button-div" *ngIf="state.canSubmitExpenses">
        <div *ngIf="savingPDApp">
            <span class="app-loading">
                <svg class="spinner" viewBox="25 25 50 50">
                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
            </span>
        </div>
        <div>
            <button class="btn btn-primary" type="button" (click)="print()">Print Application</button>
        </div>
        <button type="button" class="btn btn-primary" (click)="submitExpenses(noExpensesToSubmitTemplate)" [disabled]="savingPDApp || subNamesInvalid() || subsistencePastMax() || accomodationUnitCostExceedsMax() || subDatesExceedTotalDates() || accomodationTotalExceedsMax()">Submit Expenses</button>
        <div *ngIf="subNamesInvalid()"
             class="alert alert-danger">
            <div>
                Substitute Names are required if any substitute time is listed above.
            </div>
        </div>
        <div class="alert alert-danger" *ngIf="subsistencePastMax()">
            Subsistence cannot exceed the maximimum amount per day of ${{this.maxSubsistencePerDay}}
        </div>
        <div class="alert alert-danger"  *ngIf="accomodationUnitCostExceedsMax()">
            Accommodation cost per night has a maximum of {{ maxAccommodationPricePerNight | currency:'CAD':'symbol-narrow':'1.2-2' }}
        </div>
        <div class="alert alert-danger"  *ngIf="accomodationTotalExceedsMax()">
            Cannot apply for more than 25 days of accomodation
        </div>
    </div>

    <div class="review doNotPrint" *ngIf="state.canApproveOrDeclineExpenses">
        <h3 class="review-header">
            Approve Expenses
        </h3>
        <div class="adjudicate-button-div">
            <div *ngIf="savingPDApp">
                <span class="app-loading">
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                    </svg>
                </span>
            </div>
            <label for="expenseApprovalComments">
                Expense Approval Comments
            </label>
            <textarea class="form-control" id="expenseApprovalComments" rows="4" name="expenseApprovalComments"
                      [disabled]="!state.canApproveOrDeclineExpenses" [ngClass]="{'form-control-plaintext': !state.canApproveOrDeclineExpenses}"
                      [(ngModel)]="pdApp.expenseApprovalComments"></textarea>
            <button type="button" class="btn btn-primary approve" (click)="approveExpenses()" [disabled]="savingPDApp">
                Approve
            </button>
            <button type="button" class="btn btn-primary decline" (click)="declineExpenses()" [disabled]="savingPDApp">
                Decline
            </button>
            <button *ngIf="state.canCancelApplication" type="button" class="btn btn-primary red-no-border-button" (click)="cancel()">
                Cancel Application
            </button>
            <div>
                <button class="btn btn-primary" type="button" (click)="print()">Print Application</button>
            </div>
        </div>
    </div>

    <div class="history" *ngIf="history && history.length">
        <h3>
            Change History
        </h3>
        <table class="history-table">
            <thead>
                <tr class="history-header-row">
                    <th>Date</th>
                    <th>Action</th>
                    <th>By</th>
                </tr>
            </thead>
            <ng-template ngFor let-historyItem [ngForOf]="history">
                <tr class="history-main-row customPrintPageAvoid">
                    <td>
                        {{ getLogDate(historyItem.actionDate) }}
                    </td>
                    <td>
                        {{ historyItem.action }}
                    </td>
                    <td title="{{historyItem.email}}">
                        {{ historyItem.name }}
                    </td>
                </tr>
                <tr class="history-extra-info-row customPrintPageAvoid" *ngIf="historyItem.extraInfo">
                    <td colspan="3">
                        {{ historyItem.extraInfo }}
                    </td>
                </tr>
                <tr class="history-changes-row customPrintPageAvoid" *ngIf="historyItem.changes && historyItem.changes.length">
                    <td colspan="3">
                        Changes:
                        <table class="changes-table">
                            <thead>
                                <tr class="changes-header-row">
                                    <th class="left-most-cell">Field</th>
                                    <th>Original Value</th>
                                    <th>New Value</th>
                                </tr>
                            </thead>
                            <tr class="changes-main-row" *ngFor="let change of historyItem.changes">
                                <td class="left-most-cell">{{ change.fieldName }}</td>
                                <td>
                                    <span [innerHTML]="change.originalValue | nl2br"></span>
                                </td>
                                <td>
                                    <span [innerHTML]="change.newValue | nl2br"></span>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </ng-template>
        </table>
    </div>

    <ng-template #principalConfirmTemplate>
        <div class="modal-header">
            <h4 class="modal-title pull-left">Would you like to submit?</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="cancelPrincipalModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>
                By submitting the application, you confirm that your absence has been approved by your principal.  Please
                <a href="javascript:window.print()">print</a> submit a signed copy to the office manager if substitute days are required.
            </p>
        </div>
        <div class="modal-footer">
            <div class="row modal-confirm-button-row">
                <div class="col-6">
                    <button type="button" class="btn btn-primary red-no-border-button" (click)="cancelPrincipalModal()">
                        Cancel
                    </button>
                </div>
                <div class="col-6 right-align">
                    <button type="button" class="btn btn-primary" (click)="confirmPrincipal()">
                        Submit Application
                    </button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #noExpensesToSubmitTemplate>
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{noExpenseHeader}}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="cancelNoExpensesModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ul *ngFor="let expense of noExpenseModalText">
                <li>{{expense}}</li>
            </ul>
            <p>1. Ensure that you have uploaded all required receipts.</p>
            <p>2. Ensure that the expense portion of the application has been filled out.</p>
            <p>If you do not have any expenses to claim against these fields, continue with your submission.</p>
        </div>
        <div class="modal-footer">
            <div class="row modal-confirm-button-row">
                <div class="col-6">
                    <button type="button" class="btn btn-primary red-no-border-button" (click)="cancelNoExpensesModal()">
                        Cancel
                    </button>
                </div>
                <div class="col-6 right-align">
                    <button type="button" class="btn btn-primary" (click)="confirmNoExpenses()">
                        Submit Expenses
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</form>
