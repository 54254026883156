<div  class="stretch-height">
    <eil-top-nav *ngIf="this.showNav" id="topNav"></eil-top-nav>
    <div id="container" class='container-fluid stretch-height' (wheel)="this.showNavBar($event)" (touchmove)="this.displayNavBarMobile($event)">
        <div class='row stretch-height' >
            <div class='col-sm-12 body-content stretch-height'>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
