<div class="funding">

    <h4>Fund</h4>
  
      <div *ngIf="loading">
          <span class="app-loading">
              <svg class="spinner" viewBox="25 25 50 50">
                  <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
              </svg>
          </span>
      </div>
  
      <div class="fund" *ngFor="let term of terms">
          <div class="row">
              <div class="col-sm-10">
                  <span class="term-name">{{term.name}}</span>
                  <span class="date-range d-block d-lg-inline">({{getTermRange(term)}})</span>
              </div>
              <div class="col-sm-2 status-div {{ term.isOpen ? 'open' : 'closed' }}">
                  {{ term.isOpen ? 'open' : 'closed' }}
              </div>
          </div>
          <div class="row bar-row">
              <div class="col-sm-12">
                  <app-eil-color-bar [spent]="term.totalSpent"
                                     [pending]="term.totalPending"
                                     [requested]="term.totalProposed"
                                     [ceiling]="term.startingBalance"
                                     [showLegend]="true"></app-eil-color-bar>
              </div>
          </div>
      </div>
  
  </div>
