<div class="row">
    <div class="col-10 attached-file-count">
        <span *ngIf="!files.length">
            No files attached.
        </span>
        <span *ngIf="files.length == 1">
            1 file attached:
        </span>
        <span *ngIf="files.length > 1">
            {{files.length}} files attached:
        </span>
    </div>
    <div class="col-2 text-right">
        <button class="file-upload-add-button" *ngIf="!disabled" type="button" (click)="addFile()">Add</button>
    </div>
</div>
<div class="file-uploads-wrapper row" *ngIf="files.length">
    <div class="col">
        <div class="file-upload-div" *ngFor="let file of files">
            <div class="row file-selection" *ngIf="!file.isSelected">
                <div class="col-10">
                    <input type='file' accept=".pdf,.bmp,.docx,.doc,.jpg,.jpeg,.png,.gif" (change)="fileChanged(file, $event)">
                </div>

                <div class="col-2 text-right">
                    <a class="cancel-link" (click)="cancelFile(file)" title="Cancel">
                        <span class="cancel-icon" [inlineSVG]="'close.svg'"></span>
                    </a>
                </div>
            </div>
            <div class="file-uploading" *ngIf="file.isSelected && !file.isUploaded && file.isUploading">
                <span class="file-uploading-name">Uploading {{ file.filename }}...</span>
            </div>
            <div class="row file-uploaded" *ngIf="file.isSelected && file.isUploaded && !file.isDeleting">
                <div class="col-10">
                    <a class="file-link" href="{{file.blobStorageUrl}}" target="_blank">
                        <div class="file-icon-or-thumb doNotPrint">
                            <img class="file-thumb" *ngIf="isImageLink(file.blobStorageUrl)" src="{{file.blobStorageUrl}}" />
                            <span class="file-icon" *ngIf="!isImageLink(file.blobStorageUrl)" [inlineSVG]="'document.svg'"></span>
                        </div>
                        {{ file.filename }}
                    </a>
                </div>
                <div class="col-2 text-right">
                    <a *ngIf="!disabled" class="trash-link file-upload-trash-link" (click)="deleteFile(file)" title="Delete">
                        <span class="trash-icon" [inlineSVG]="'trash.svg'"></span>
                    </a>
                </div>
            </div>
            <div class="file-deleting" *ngIf="file.isDeleting">
                Deleting {{ file.filename }}...
            </div>
            <div class="file-bad-format" *ngIf="!!file.filename && !this.fileIsAcceptable(file.filename)">
                Only word documents, pictures, or pdfs allowed. These are files that end with .pdf, .bmp, .docx, .doc, .jpg, .jpeg, .png or .gif
            </div>
        </div>
    </div>
</div>
