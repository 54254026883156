import { Component,
         OnInit }        from '@angular/core';
import {PDAppService} from "../../../services/pd-app.service";
import {ROUTES} from "../../../routes";
import {TermsService} from "../../../services/terms.service";
import { ITerm } from "../../../models/term.model";
import moment from "moment";

@Component({
        selector:       'eil-fundterms-list',
        templateUrl:    './fundterms-list.component.html',
        styleUrls:      ['./fundterms-list.component.scss']
    }
)
export class FundtermsListComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    terms = [];
    loading = false;
    linkToEdit: string;

    constructor(private termsService: TermsService) {
        this.linkToEdit = `/${ROUTES.ADMIN}/${ROUTES.ADMIN_FUNDTERMS_EDIT}/`;
        this.loading = true;
        // TODO: filter?
        this.termsService.getMany({}, (success, result) => {
            this.terms = result;
            // TODO: process result

            // unflag wait
            this.loading = false;
        });
    }


    getTermRange(term: ITerm) {
        // TODO: this actually should be common code as it's used on applist as well
        const start = moment(term.startDate);
        const end = moment(term.endDate);
        const fullFormatString = "MMMM D, YYYY";

        if (start.get('year') !== end.get('year')) {
            return `${start.format(fullFormatString)} - ${end.format(fullFormatString)}`;
        }

        if (start.get('month') !== end.get('month')) {
            return `${start.format("MMMM D")} - ${end.format(fullFormatString)}`;
        }

        return `${start.format("MMMM D")} - ${end.format("D, YYYY")}`;
    }

    ngOnInit() {

    }

}
