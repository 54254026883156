import { Component, OnInit } from "@angular/core";
import { ConfigService } from "../../services/config.service";
import { IConfigValues } from "../../models/config-values.model";
import { ToastrService } from "ngx-toastr";
import { BulkCreditService } from "../../services/bulk-credit.service";
import moment from "moment";
import { IBulkCreditLoad } from "../../models/bulk-credit-load.model";
import { IBulkLoadMemberRoster } from "../../models/bulk-load-member-roster.model";
import { IBulkLoadNextRequest } from "../../models/bulk-load-next-request.model";

@Component({
    selector: "eil-advanced",
    templateUrl: "./advanced.component.html",
    styleUrls: ["./advanced.component.scss"],
})
export class AdvancedComponent implements OnInit {
    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    loadingConfig = false;
    savingConfig = false;

    loadingPastCredits = false;
    creditLoadInProgress = false;
    activeCreditLoad: IBulkCreditLoad;
    loadingRosterMembers = false;
    rosterMembers: IBulkLoadMemberRoster[] = [];
    processingRosterIndex = 0;
    pastCreditLoads: IBulkCreditLoad[] = [];
    bulkCreditsToAdd;

    disableForNonAdmin: boolean = false;

    config: IConfigValues = {};

    constructor(
        private configService: ConfigService,
        private bulkCreditService: BulkCreditService,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        // flag wait
        this.loadingConfig = true;

        this.configService.instance.subscribe((config) => {
            this.config = config;
            this.bulkCreditsToAdd = config.defaultCreditAddAmount;
        });

        // TODO: should load be called automatically by the service rather than manually on-demand here?
        this.configService.load((isSuccess) => {
            if (isSuccess) this.loadingConfig = false;
            // TODO: what if not?
        });

        this.loadingRosterMembers = true;
        this.bulkCreditService.getActiveMemberRoster(
            (success, result, error) => {
                // unflag wait
                this.loadingRosterMembers = false;

                if (success) {
                    this.rosterMembers = result;
                } else if (!error.displayHasBeenHandled) {
                    if (error.errorMessage == "You are not an administrator.") {
                        this.disableForNonAdmin = true;
                    }
                    this.toastrService.error(error.errorMessage);
                }
            }
        );

        this.loadPastCredits();
    }

    loadPastCredits() {
        this.loadingPastCredits = true;
        this.bulkCreditService.getPastCreditLoads((success, result, error) => {
            // unflag wait
            this.loadingPastCredits = false;

            if (success) {
                this.pastCreditLoads = result;
            } else if (!error.displayHasBeenHandled) {
                if (error.errorMessage == "You are not an administrator.") {
                    this.disableForNonAdmin = true;
                }
                // this.toastrService.error(error.errorMessage);
            }
        });
    }

    onSubmit() {
        // flag wait
        this.savingConfig = true;

        this.configService.save(this.config, (success, error) => {
            // unflag wait
            this.savingConfig = false;

            if (success) {
                this.toastrService.success("Configuration saved.");
                this.loadingConfig = true;
                this.configService.load((isSuccess) => {
                    if (isSuccess) this.loadingConfig = false;
                    // TODO: what if not?
                });
            } else if (!error.displayHasBeenHandled) {
                this.toastrService.error(error.errorMessage);
            }
        });
    }

    // TODO: put this in a common library!
    getAgo(dateString) {
        if (!dateString) {
            return "--";
        }
        return moment(dateString).fromNow();
    }

    startBulkCredit(creditsToAdd) {
        // TODO: confirm they should leave their computer going while we do this
        // TODO:
        if (
            !confirm(
                `This will add up to $${creditsToAdd} to each active member's credit balance, up to a maximum resulting balance of $${this.config.maximumAllowedCredits}.  \nThis may take a few moments.  \nPlease DO NOT CLOSE THIS WINDOW OR CLICK ANYTHING UNTIL THE PROCESS IS COMPLETED, or it will be CANCELLED, with some members having been credited and others not! \n\nDo you still wish to continue?`
            )
        ) {
            return;
        }
        this.creditLoadInProgress = true;
        this.activeCreditLoad = {
            amountToLoad: creditsToAdd,
            membersToLoad: this.rosterMembers.length,
            loadStatus: "New",
        };
        this.bulkCreditService.createNewCreditLoadEntry(
            this.activeCreditLoad,
            (success, result, error) => {
                if (success) {
                    this.activeCreditLoad = result;
                    this.processingRosterIndex = 0;
                    this.processRosterEntry();
                } else {
                    if (error) {
                        if (!error.displayHasBeenHandled) {
                            this.toastrService.error(error.errorMessage);
                        }
                    } else {
                        this.toastrService.error("An unknown error occurred.");
                    }
                    this.activeCreditLoad.loadStatus = "Failed";
                    this.creditLoadInProgress = false;
                }
            }
        );
    }

    processRosterEntry() {
        let rosterEntry = this.rosterMembers[this.processingRosterIndex];
        rosterEntry.isBeingCredited = true;
        let request: IBulkLoadNextRequest = {
            bulkCreditId: this.activeCreditLoad.id,
            userId: rosterEntry.id,
        };
        this.bulkCreditService.performCreditLoad(
            request,
            (success, result, error) => {
                rosterEntry.isBeingCredited = false;
                if (success) {
                    this.activeCreditLoad = result.bulkMaster;
                    this.rosterMembers[this.processingRosterIndex] =
                        result.member;
                } else {
                    let rosterEntryName =
                        rosterEntry.firstName || rosterEntry.lastName
                            ? `${rosterEntry.firstName} ${rosterEntry.lastName}`
                            : rosterEntry.email;
                    if (error) {
                        if (!error.displayHasBeenHandled) {
                            this.toastrService.warning(
                                `An error occurred for ${rosterEntryName}: '${error.errorMessage}'`
                            );
                        }
                    } else {
                        this.toastrService.warning(
                            `An unknown error occurred for ${rosterEntryName}.`
                        );
                    }
                }
                this.processingRosterIndex++;
                if (this.processingRosterIndex >= this.rosterMembers.length) {
                    this.completeBulkCredit();
                } else {
                    this.processRosterEntry();
                }
            }
        );
    }

    completeBulkCredit() {
        this.activeCreditLoad.loadStatus = "Finishing";
        this.bulkCreditService.markCreditLoadComplete(
            this.activeCreditLoad,
            (success, result, error) => {
                if (success) {
                    this.activeCreditLoad = result;
                } else {
                    if (error) {
                        if (!error.displayHasBeenHandled) {
                            this.toastrService.error(error.errorMessage);
                        }
                    } else {
                        this.toastrService.error("An unknown error occurred.");
                    }
                    this.activeCreditLoad.loadStatus = "Failed";
                }
                this.creditLoadInProgress = false;
                this.loadPastCredits();
            }
        );
    }
}
