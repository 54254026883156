<div class="row">
    <div class="col-sm-12">
        <div *ngIf="!disabledForNonAdmin" class="new-button-div">
            <a class="btn btn-default" [routerLink]="[linkToNew]">
                + New Term
            </a>
        </div>
        <eil-fundterms-list></eil-fundterms-list>
    </div>
</div>
