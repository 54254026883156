import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { CurrentUserService } from "./services/current-user.service";
import { ROUTES } from "./routes";

export const AuthGuard: CanActivateFn = (next, state) => {
    const userService = inject(CurrentUserService);
    const router = inject(Router);

    if (!userService.isAuthenticated()) {
        // redirect to login (with a return path) so that the user can be authorized and then come back to whatever page this is
        router.navigate([ROUTES.LOGIN], { queryParams: { returnUrl: state.url.toString() } });
        return false;
    } else if (!userService.isRegistered()) {
        if (state.url === `/${ROUTES.REGISTER}/${ROUTES.REGISTER_DETAIL}`) {
            return true;
        }
        router.navigateByUrl(ROUTES.REGISTER + "/" + ROUTES.REGISTER_DETAIL);
        return false;
    } else if (!userService.isApproved()) {
        if (state.url === `/${ROUTES.REGISTER}/${ROUTES.REGISTER_AWAITING}`) {
            return true;
        }
        // if they haven't been approved yet they have to wait instead of whatever page this is
        router.navigateByUrl(ROUTES.REGISTER + "/" + ROUTES.REGISTER_AWAITING);
        return false;
    } else if (next.url[0].path === ROUTES.ADMIN && !(userService.isAdmin() || userService.isAuditor())) {
        return false;
    }
    return true;
}
