import { Component,
         OnInit }       from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { IUserDetail } from "../../models/user-detail.model";
import { CurrentUserService } from "../../services/current-user.service";


@Component({
        selector:       'eil-credits',
        templateUrl:    './credits.component.html',
        styleUrls:      ['./credits.component.scss']
    }
)
export class CreditsComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    configIsLoaded = false;
    availableCredits: number;
    pendingCredits: number;
    maximumAllowedCredits: number;
    currentUser: IUserDetail;

    constructor(private currentUserService: CurrentUserService, private configService: ConfigService) {

    }

    ngOnInit() {

        this.currentUserService.currentUser.subscribe(user => {

            this.currentUser = user;
            this.availableCredits = user.unpendingBalance;
            this.pendingCredits = user.unspentBalance - user.unpendingBalance;
        });

        this.configService.instance.subscribe(config => {
            this.maximumAllowedCredits = config.maximumAllowedCredits;
        });
        // TODO: should load be called automatically by the service rather than manually on-demand here?
        this.configService.load((isSuccess) => {
            if (isSuccess) { this.configIsLoaded = true; }
        });


    }

}
