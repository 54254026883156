import { Component, 
         OnInit }       from '@angular/core';

@Component({
        selector:       'eil-pdevents',
        templateUrl:    './pdevents.component.html',
        styleUrls:      ['./pdevents.component.scss']
        }
)
export class PDEventsComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    constructor() { 

    }

    ngOnInit() {

    }

}
