import { Component,
         OnInit }       from '@angular/core';
import {PDAppService} from "../../../services/pd-app.service";
import {ROUTES} from "../../../routes";
import {CurrentUserService} from "../../../services/current-user.service";
import {IUserDetail} from "../../../models/user-detail.model";
import { IPDEvent } from "../../../models/pd-event.model";
import moment from "moment";
import { IPDAppModel } from "../../../models/pd-app.model";
import { ToastrService } from "ngx-toastr";

@Component({
        selector:       'eil-pd-applist',
        templateUrl:    './pd-applist.component.html',
        styleUrls:      ['./pd-applist.component.scss']
    }
)
export class PDApplistComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;
    currentUser: IUserDetail;
    pdApps = [];
    loading = false;
    linkToEdit: string;

    constructor(private pdAppService: PDAppService, private toastrService: ToastrService, private currentUserService: CurrentUserService) {
        this.linkToEdit = `/${ROUTES.PD}/${ROUTES.PD_EDITAPP}/`;
    }

    ngOnInit() {
        this.currentUserService.currentUser.subscribe(user => {
            this.currentUser = user;
            this.loading = true;
            this.pdAppService.getMany({
                applicantEmail: this.currentUser.email
            }, (success, result) => {
                this.pdApps = result;
                // TODO: process result
                this.checkAndUpdateStatus();
                // unflag wait
                this.loading = false;
            });
        });

        this.reminderToUpdatePersonalInfo();
    }

    reminderToUpdatePersonalInfo() {
        var lastSessionDate = moment(this.currentUser.lastSession);
        var now = moment();
        var septemberFirstString = now.year().toString() + "-09-01";
        var septemberFirst = moment(septemberFirstString, "YYYY-MM-DD");
        if (now >= septemberFirst && (lastSessionDate < septemberFirst || lastSessionDate == null)) {
            this.toastrService.success("Please ensure that your personal details are up to date", "", { disableTimeOut: true });
        }

    }

    checkAndUpdateStatus() {
        var i;
        var oneDay = 24 * 60 * 60 * 1000;
        for (i = 0; i < this.pdApps.length; i++) {
            if (this.pdApps[i].status == "Approved" || this.pdApps[i].status == "ExpensesDue") {
                var endDate = new Date(this.pdApps[i].event.endDate);
                var now = new Date();
                if (now.valueOf() > endDate.valueOf()) {
                    if (((now.getTime() - endDate.getTime()) / oneDay) > 21) {
                        this.pdAppService.expensesOverdue(this.pdApps[i],
                            (success, result, error) => {

                                if (success) {
                                    this.pdApps[i] = result;
                                } else if (!error.displayHasBeenHandled) {
                                    this.toastrService.error(error.errorMessage);
                                }
                            }
                        );
                    } else {
                        if (this.pdApps[i].status != "ExpensesDue") {
                            this.pdAppService.expensesDue(this.pdApps[i],
                                (success, result, error) => {

                                    if (success) {
                                        this.pdApps[i] = result;
                                    } else if (!error.displayHasBeenHandled) {
                                        this.toastrService.error(error.errorMessage);
                                    }
                                }
                            );
                        }
                    }
                }
            }
        }
    }

    getEventRange(event: IPDEvent) {
        const start = moment(event.startDate);
        const end = moment(event.endDate);
        const fullFormatString = "MMMM D, YYYY";

        if (start.get('year') !== end.get('year')) {
            return `${start.format(fullFormatString)} - ${end.format(fullFormatString)}`;
        }

        if (start.get('month') !== end.get('month')) {
            return `${start.format("MMMM D")} - ${end.format(fullFormatString)}`;
        }

        return `${start.format("MMMM D")} - ${end.format("D, YYYY")}`;
    }

    expensesAreActual(pdApp: IPDAppModel) {
        switch (pdApp.status) {
            case "ExpensesSubmitted":
            case "ExpensesApproved":
            case "Reimbursed":
                return true;
            default:
                return false;
        }
    }
}
