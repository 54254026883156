<div class="member-list">
    <div class="lined-input-group">
        <input type="text" class="form-control member-search" [value]="searchText" (input)="searchText = $event.target.value" placeholder="Search: Name, Email or Certificate #" />
    </div>
    <table class="list-table table-sm">
        <thead>
            <tr>
                <th class="header">Roles</th>
                <th class="header" [class.active]="order ===  'firstName'" (click)="setOrder('firstName')">
                    First Name<span [hidden]="reverse">▼</span>
                    <span [hidden]="!reverse">▲</span>
                </th>
                <th class="header" [class.active]="order ===  'lastName'" (click)="setOrder('lastName')">
                    Last Name<span [hidden]="reverse">▼</span>
                    <span [hidden]="!reverse">▲</span>
                </th>
                <th class="header" [class.active]="order ===  'preferredEmail'" (click)="setOrder('preferredEmail')">
                    Email<span [hidden]="reverse">▼</span>
                    <span [hidden]="!reverse">▲</span>
                </th>
                <th class="header" [class.active]="order ===  'teachingCertNo'" (click)="setOrder('teachingCertNo')">
                    Certificate #<span [hidden]="reverse">▼</span>
                    <span [hidden]="!reverse">▲</span>
                </th>
                <th class="header" [class.active]="order ===  'lastSeen'" (click)="setOrder('lastSeen')">
                    Last Seen<span [hidden]="reverse">▼</span>
                    <span [hidden]="!reverse">▲</span>
                </th>
                <th class="header" [class.active]="order ===  'getStatus(member)'" (click)="setOrder('getStatus(member)')">
                    Status<span [hidden]="reverse">▼</span>
                    <span [hidden]="!reverse">▲</span>
                </th>
                <th class="header">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="loading">
                <td colspan="5">
                    <span class="app-loading">
                        <svg class="spinner" viewBox="25 25 50 50">
                            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                        </svg>
                    </span>

                </td>
            </tr>
            <tr class="list-row" *ngFor="let member of members| memberSearchFilter: searchText | orderBy:order:reverse:true | paginate: {itemsPerPage: 20, currentPage: this.tablePage}">
                <td>
                    <span class="role-indicator member-indicator" *ngIf="member.isMember" title="Member">m</span>
                    <span class="role-indicator admin-indicator" *ngIf="member.isAdmin" title="Administrator">A</span>
                    <span class="role-indicator admin-indicator" *ngIf="member.isAud" title="Auditor">Aud</span>
                </td>
                <td>
                    <a [routerLink]="[linkToEdit + member.id]" *ngIf="!currentUser.isAuditor">
                        {{ member.firstName }}
                    </a>
                    <div *ngIf="currentUser.isAuditor">
                        {{ member.firstName }}
                    </div>
                </td>
                <td>
                    <a [routerLink]="[linkToEdit + member.id]" *ngIf="!currentUser.isAuditor">
                        {{ member.lastName }}{{ member.lastName ? '' : '...'}}
                    </a>
                    <div *ngIf="currentUser.isAuditor">
                        {{ member.lastName }}{{ member.lastName ? '' : '...'}}
                    </div>
                </td>
                <td>
                    {{member.preferredEmail ? member.preferredEmail : member.email}}
                    <span *ngIf="member.preferredEmail && member.preferredEmail != member.email"
                          title="Registered as {{member.email}}">**</span>
                </td>
                <td>
                    {{ member.teachingCertNo }}
                </td>
                <td title="{{ member.lastSeen }}">
                    {{ getAgo(member.lastSeen) }}
                </td>
                <td>
                    {{ memberLogic.getStatus(member) }}
                </td>
                <!--<td>
                    <eil-quick-toggle [currentState]="member.isRegistered" (toggle)="toggleIsRegistered(member, $event)"></eil-quick-toggle>
                </td>
                <td>
                    <eil-quick-toggle [currentState]="member.isApproved" (toggle)="toggleIsApproved(member, $event)"></eil-quick-toggle>
                </td>
                <td>
                    <eil-quick-toggle [currentState]="member.isMember" (toggle)="toggleIsMember(member, $event)"></eil-quick-toggle>
                </td>
                <td>
                    <eil-quick-toggle [currentState]="member.isAdmin" (toggle)="toggleIsAdmin(member, $event)"></eil-quick-toggle>
                </td>-->
                <td>
                    <div class="btn-group" dropdown *ngIf="currentUser && currentUser.email != member.email && !currentUser.isAuditor">
                        <button type="button" dropdownToggle class="btn btn-primary dropdown-toggle">
                            ...
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">

                            <li role="menuitem" *ngIf="memberLogic.adminCanApprove(member)">
                                <a class="dropdown-item" (click)="toggleIsApproved(member, true)">
                                    Approve
                                </a>
                            </li>
                            <li role="menuitem" *ngIf="memberLogic.adminCanReject(member)">
                                <a class="dropdown-item" (click)="reject(member)">
                                    Reject
                                </a>
                            </li>

                            <li class="divider dropdown-divider"></li>

                            <li role="menuitem" *ngIf="memberLogic.adminCanMakeAdmin(member)">
                                <a class="dropdown-item" (click)="toggleIsAdmin(member, true)">
                                    Make Admin
                                </a>
                            </li>
                            <li role="menuitem" *ngIf="memberLogic.adminCanRemoveAsAdmin(member)">
                                <a class="dropdown-item" (click)="toggleIsAdmin(member, false)">
                                    Remove as Admin
                                </a>
                            </li>
                            <li role="menuitem" *ngIf="memberLogic.adminCanMakeMember(member)">
                                <a class="dropdown-item" (click)="toggleIsMember(member, true)">
                                    Set as Member
                                </a>
                            </li>
                            <li role="menuitem" *ngIf="memberLogic.adminCanRemoveAsMember(member)">
                                <a class="dropdown-item" (click)="toggleIsMember(member, false)">
                                    Remove as Member
                                </a>
                            </li>

                            <li class="divider dropdown-divider"></li>

                            <li role="menuitem" *ngIf="memberLogic.adminCanDeactivate(member)">
                                <a class="dropdown-item" (click)="deactivate(member)">
                                    Deactivate
                                </a>
                            </li>
                            <li role="menuitem" *ngIf="memberLogic.adminCanReactivate(member)">
                                <a class="dropdown-item" (click)="reactivate(member)">
                                    Reactivate
                                </a>
                            </li>

                            <li class="divider dropdown-divider"></li>

                            <li role="menuitem" *ngIf="memberLogic.adminCanMakeAuditor(member)">
                                <a class="dropdown-item" (click)="toggleIsAuditor(member, true)">
                                    Make Auditor
                                </a>
                            </li>
                            <li role="menuitem" *ngIf="memberLogic.adminCanRemoveAsAuditor(member)">
                                <a class="dropdown-item" (click)="toggleIsAuditor(member, false)">
                                    Remove As Auditor
                                </a>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="!loading && (!members || !members.length)">
        No members match the current filters.
    </div>
    <pagination-controls (pageChange)="this.tablePage = $event"></pagination-controls>
</div>

