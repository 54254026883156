import {
    Component,
    OnInit
} from '@angular/core';
import { ITerm } from "../../../models/term.model";
import { TermsService } from "../../../services/terms.service";
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTES } from '../../../routes';
import { ToastrService } from 'ngx-toastr';
import { CurrentUserService } from '../../../services/current-user.service';

@Component({
    selector: 'eil-fundterm-form',
    templateUrl: './fundterm-form.component.html',
    styleUrls: [
        // For some reason if you try using just the node module folder name (like an import) it tries to make it relative to this path
        // I'm so sorry
        '../../../../../node_modules/@angular/material/prebuilt-themes/purple-green.css',
        './fundterm-form.component.scss'
    ]
}
)
export class FundtermFormComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    loadingTerm = false;
    savingTerm = false;
    showAllocationBar = false;
    disableForNonAdmin: boolean = false;

    term: ITerm = {
        isOpen: false
    };

    constructor(private termsService: TermsService,
        private toastrService: ToastrService,
        protected router: Router,
        private route: ActivatedRoute,
        userService: CurrentUserService) {
        userService.currentUser.subscribe(user => this.disableForNonAdmin = !user.isAdmin);
    }

    ngOnInit() {
        // load an existing form if applicable
        const paramMap = this.route.snapshot.paramMap;
        if (paramMap.has('id')) {
            const id = paramMap.get('id');
            if (id) {
                // flag wait
                this.loadingTerm = true;
                this.termsService.getOne(id,
                    (success, result) => {
                        if (success) {

                            this.term = result;

                            // date picker is selfish and wants Date objects, not strings.
                            // (https://github.com/valor-software/ngx-bootstrap/issues/4020)
                            if (this.term.startDate && !(this.term.startDate instanceof Date)) {
                                this.term.startDate = new Date(this.term.startDate);
                            }
                            if (this.term.endDate && !(this.term.endDate instanceof Date)) {
                                this.term.endDate = new Date(this.term.endDate);
                            }

                            // unflag wait
                            this.loadingTerm = false;
                            this.showAllocationBar = true;
                        }
                    });
            }
        } else {
            // set for a new submission
            this.showAllocationBar = false;
        }
    }

    onSubmit() {
        // flag wait
        this.savingTerm = true;

        this.termsService.save(this.term,
            (success, result, error) => {

                // unflag wait
                this.savingTerm = false;

                if (success) {
                    this.toastrService.success("Term created.");
                    this.router.navigateByUrl(`${ROUTES.ADMIN}/${ROUTES.ADMIN_FUNDTERMS}`);
                } else if (!error.displayHasBeenHandled) {
                    if (error.errorMessage == "You are not an administrator.") {
                        this.disableForNonAdmin = true;
                    }
                    this.toastrService.error(error.errorMessage);
                }
            });
    }
}
