import { Injectable }      from '@angular/core';
import { ServiceBase }     from './base.service';
import { ParsedError } from "./parsed-error";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {IBulkCreditLoad} from "../models/bulk-credit-load.model";
import {IBulkLoadNextRequest} from "../models/bulk-load-next-request.model";
import {IBulkLoadMemberRoster} from "../models/bulk-load-member-roster.model";
import { IBulkLoadNextResult } from "../models/bulk-load-next-result.model";


@Injectable()
export class BulkCreditService extends ServiceBase {

    constructor(protected http: HttpClient,
        protected toastrService: ToastrService) {
         super(http, null, toastrService);
    }

    private apiUrl = 'bulkCredit/';

    getActiveMemberRoster(callback: (isSuccess: boolean, result?: any[], error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'GetActiveMemberRoster';
        this.http.get<Array<any>>(callUrl)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error));
                });
    }

    getPastCreditLoads(callback: (isSuccess: boolean, result?: any[], error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'GetPastCreditLoads';
        this.http.get<Array<any>>(callUrl)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error));
                });
    }

    createNewCreditLoadEntry(bulkCreditLoad: IBulkCreditLoad, callback: (isSuccess: boolean, result?: IBulkCreditLoad, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'createNewCreditLoadEntry';
        this.http.post(callUrl, bulkCreditLoad)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, {}, this.handleError(error, bulkCreditLoad));
                });
    }

    performCreditLoad(next: IBulkLoadNextRequest, callback: (isSuccess: boolean, result?: IBulkLoadNextResult, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'performCreditLoad';
        this.http.post(callUrl, next)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, {}, this.handleError(error, next));
                });
    }

    markCreditLoadComplete(bulkCreditLoad: IBulkCreditLoad, callback: (isSuccess: boolean, result?: IBulkCreditLoad, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'markCreditLoadComplete';
        this.http.post(callUrl, bulkCreditLoad)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, {}, this.handleError(error, bulkCreditLoad));
                });
    }
}


