import { Component,
         OnInit }       from '@angular/core';
import {ROUTES} from "../../routes";
import { CurrentUserService } from '../../services/current-user.service';

@Component({
        selector:       'eil-fundterms',
        templateUrl:    './fundterms.component.html',
        styleUrls:      ['./fundterms.component.scss']
    }
)
export class FundtermsComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    linkToNew: string;
    disabledForNonAdmin: boolean = false;

    constructor(userService: CurrentUserService) {
        userService.currentUser.subscribe(user => this.disabledForNonAdmin = !user.isAdmin);
        this.linkToNew =//`/${ROUTES.ADMIN}/newterm`;
            //`/${ROUTES.ADMIN}/${ROUTES.ADMIN_FUNDTERMS}/${ROUTES.ADMIN_FUNDTERMS_NEW}`;
            `/${ROUTES.ADMIN}/${ROUTES.ADMIN_FUNDTERMS_NEW}`;
    }

    ngOnInit() {

    }

}
