import { Component, 
         OnInit }        from '@angular/core';

@Component({
        selector:       'eil-wait',
        templateUrl:    './wait.component.html',
        styleUrls:      ['./wait.component.scss'] 
    }
)
export class WaitComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    constructor() { 

    }

    ngOnInit() {

    }

}
