<div class="unit-calc" *ngIf="calculation">
    <div class="row headers" *ngIf="amountHeader || unitCostHeader || totalHeader">
        <div class="col-xs-6 amount-header">
            {{ amountHeader }}
        </div>
        <div class="col-xs-6 unit-cost-header">
            {{ unitCostHeader }}
        </div>
        <div class="col-xs-6 total-header">
            {{ totalHeader }}
        </div>
    </div>
    <div class="values">
        <div class="amount-div">
            {{ amountPrefix }}
            <input class="amount-input" type="number" name="amount" [(ngModel)]="calculation.amount" #amountBox 
                   (change)="onAmountChange(amountBox.value)" (keyup)="onAmountChange(amountBox.value)"
                   [disabled]="disabled" [ngClass]="{'empty': !calculation.amount}"
                   placeholder="0" step="1" min="0" />
            {{ amountSuffix }}
        </div>
        <div class="unit-cost-div">
            <span class="unit-cost">
                x
                {{ unitPrefix }}
                <span *ngIf="!unitCostEditable">
                    {{ calculation?.unitCost }}
                </span>
                <input *ngIf="unitCostEditable" class="unit-cost-input" type="number" name="unitCost" [(ngModel)]="calculation.unitCost" #unitCostBox 
                       (change)="onUnitCostChange(unitCostBox.value)" (keyup)="onUnitCostChange(unitCostBox.value)"
                       [disabled]="disabled" [ngClass]="{'empty': !calculation.unitCost}"
                       placeholder="0" step="1" min="0" />
                {{ unitSuffix }}
            </span>
        </div>
        <div class="result-div">
            <span class="total">
                =
                <span class="inner-total" [ngClass]="{'significant': calculation.total && calculation.total != '0'}">
                    {{ unitPrefix }}
                    {{ calculation?.total }}
                    {{ unitSuffix }}
                </span>
            </span>
        </div>
    </div>
</div>
