import { Component, 
         OnInit }       from '@angular/core';
import { Location } from '@angular/common';
@Component({
        selector:       'eil-members',
        templateUrl:    './members.component.html',
        styleUrls:      ['./members.component.scss']
    }
)
export class MembersComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;
    linkToExcel: string;

    constructor(private location: Location) { 
        this.linkToExcel = location.prepareExternalUrl("PDApp/GetAllMembersAsExcel");
    }

    ngOnInit() {

    }

}
