import { Injectable, computed, signal }       from '@angular/core';
import { HttpClient }       from '@angular/common/http';
import { BehaviorSubject }       from 'rxjs';
import { ServiceBase }      from './base.service';
import { IUserDetail }      from "../models/user-detail.model";
import { ParsedError } from "./parsed-error";
import { ToastrService } from 'ngx-toastr';
import { MonitoringService } from "./monitoring.service";

@Injectable()
export class CurrentUserService extends ServiceBase {

    constructor(protected http: HttpClient,
        protected monitoringService: MonitoringService,
        protected toastrService: ToastrService) {
         super(http, monitoringService, toastrService);
    }

    private currentUserSource = new BehaviorSubject<IUserDetail>({
        isLoaded: false
    });

    public isAuthenticated = signal(false);
    public isRegistered = computed(() => this.isAuthenticated() && this.currentUserSource.getValue().isMember && this.currentUserSource.getValue().isRegistered);
    public isApproved = computed(() => this.isAuthenticated() && this.currentUserSource.getValue().isApproved);
    public isAdmin = computed(() => this.isAuthenticated() && this.currentUserSource.getValue().isAdmin);
    public isAuditor = computed(() => this.isAuthenticated() && this.currentUserSource.getValue().isAuditor);

    currentUser = this.currentUserSource.asObservable();

    private apiUrl = 'user/';

    load(callback: (isSuccess: boolean, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'get';
        this.http.get<IUserDetail>(callUrl)
            .subscribe(
                user => {
                    user.isLoaded = true;
                    this.isAuthenticated.set(user.isAuthenticated);
                    this.monitoringService.setAuthenticatedUserId(user.email);
                    this.currentUserSource.next(user);
                    callback(true, null);
                },
                error => {
                    // TODO: Log?
                    callback(false, this.handleError(error));
                });
    }

    // TODO: this makes no sense to have in "CurrentUserService", but it needs to know the current user, so this and MembersService should be combined anyway
    save(user: IUserDetail, callback: (isSuccess: boolean, result?: IUserDetail, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'save';
        this.http.post(callUrl, user)
            .subscribe(
                (returnedUser: IUserDetail) => {
                    returnedUser.isLoaded = true;
                    if (this.currentUserSource.value && this.currentUserSource.value.email === returnedUser.email) {
                        this.currentUserSource.next(returnedUser);
                    }
                    callback(true, returnedUser);
                },
                error => {
                    // TODO: Log?
                    callback(false, {}, this.handleError(error, user));
                });
    }
}
