import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "searchFilter",
    pure: false
})
export class SearchFilterPipe implements PipeTransform {
    transform(items: any[], args: string): any {
        
        return items.filter(item => (item.applicantName.toLowerCase().indexOf(args.toLowerCase()) !== -1 || (item.event.name != null && item.event.name.toLowerCase().indexOf(args.toLowerCase()) !== -1)));
    }
}
