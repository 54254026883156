import {IUserDetail} from "./user-detail.model";
import {MemberStatus} from "./member-status.model";

export class MemberLogic {

    getStatus(member: IUserDetail) {
        return MemberStatus.for(member);
    }

    adminCanApprove(member: IUserDetail) {
        // have to be unapproved
        if (member.isApproved) {
            return false;
        }

        // can't have been approved before (that's what Reactivate is for)
        if (member.hasBeenApproved) {
            return false;
        }

        // have to either be registered, or an admin and NOT a member
        if (member.isRegistered || (member.isAdmin && !member.isMember)) {
            return true;
        }

        return false;
    }

    adminCanReject(member: IUserDetail) {
        // they're not approved and never have been before (because reject is a delete, so deactivate)
        return !member.isApproved && !member.hasBeenApproved;
    }

    adminCanMakeAdmin(member: IUserDetail) {
        // they just have to not be an admin
        return !member.isAdmin;
    }

    adminCanRemoveAsAdmin(member: IUserDetail) {
        // they just have to be an admin (server will reject attempt if it's themselves)
        return member.isAdmin;
    }

    adminCanMakeMember(member: IUserDetail) {
        // TODO: this is hard because it needs to set other flags too I think
        return !member.isMember;
    }

    adminCanMakeAuditor(member: IUserDetail) {
        // TODO: this is hard because it needs to set other flags too I think
        return !member.isAuditor;
    }

    adminCanRemoveAsAuditor(member: IUserDetail) {
        // they just have to be an admin (server will reject attempt if it's themselves)
        return member.isAuditor;
    }

    adminCanRemoveAsMember(member: IUserDetail) {
        // TODO: this is hard because it needs to set other flags too I think
        return member.isMember;
    }

    adminCanDeactivate(member: IUserDetail) {
        // if they're approved
        return member.isApproved;
    }

    adminCanReactivate(member: IUserDetail) {
        // if they're not approved but have been before
        return member.hasBeenApproved && !member.isApproved;
    }
}
