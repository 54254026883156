import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "statusFilter",
    pure: false
})
export class StatusFilterPipe implements PipeTransform {
    transform(items: any[], args: string): any {
        if (args == "") {
            return items;
        }
        return items.filter(item => item.status.toLowerCase() == args.toLowerCase() );
    }
}
