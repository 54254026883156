<div class="terms-list">
    <table class="list-table">
        <thead>
            <tr>
                <th class="header">Name</th>
                <th class="header">Term</th>
                <th class="header">Status</th>
                <th class="header">Starting Balance</th>
                <th class="header">Allocation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="loading">
                <td colspan="5">
                    <span class="app-loading">
                        <svg class="spinner" viewBox="25 25 50 50">
                            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                        </svg>
                    </span>
                </td>
            </tr>
            <tr class="list-row" *ngFor="let term of terms">
                <td>
                    <a [routerLink]="[linkToEdit + term.id]">
                        {{term.name}}
                    </a>
                </td>
                <td>
                    {{getTermRange(term)}}
                </td>
                <td>
                    {{ term.isOpen ? 'Open' : 'Closed' }}
                </td>
                <td>
                    {{ term.startingBalance | currency:'CAD':'symbol-narrow':'1.2-2' }}
                </td>
                <td>
                    <eil-color-bar [spent]="term.totalSpent" [pending]="term.totalPending" [requested]="term.totalProposed"
                                   [ceiling]="term.startingBalance"></eil-color-bar>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="!loading && (!terms || !terms.length)">
        No terms found.
    </div>
</div>
