import { Component, 
         forwardRef,
         OnInit,
         Input,
         EventEmitter,
         Output }        from '@angular/core';
import { Calculation } from '../../models/calculation.model';
import { NG_VALUE_ACCESSOR,
         ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

export const UNIT_CALC_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UnitcalcComponent),
    multi: true
};

@Component({
        selector:       'eil-unitcalc',
        templateUrl:    './unitcalc.component.html',
        styleUrls: ['./unitcalc.component.scss'],
        providers: [UNIT_CALC_CONTROL_VALUE_ACCESSOR]
    }
)
export class UnitcalcComponent implements OnInit, ControlValueAccessor {

    @Input() public amountHeader?:     string;
    @Input() public amountPrefix?:     string;
    @Input() public amountSuffix?:     string;
    @Input() public unitCostHeader?:   string;
    @Input() public unitPrefix?:       string;
    @Input() public unitSuffix?:       string;
    @Input() public totalHeader?:      string;
    @Input() public unitCostEditable?: boolean;
    @Output() public change = new EventEmitter<Calculation>();
    @Input() public disabled?:         boolean;

    // The internal data model
    calculation: Calculation = {};

    // Placeholders for the callbacks which are later providesd
    // by the Control Value Accessor
    onTouchedCallback: () => void = noop;
    onChangeCallback: (_: any) => void = noop;

    // get accessor
    get value(): Calculation {
        return this.calculation;
    }

    // set accessor including call the onchange callback
    set value(v: Calculation) {
        if (v !== this.calculation) {
            this.calculation = v;
            this.onChangeCallback(v);
        }
    }

    // Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    // From ControlValueAccessor interface
    writeValue(value: Calculation) {
        if (value !== this.calculation) {
            this.calculation = value;
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    constructor() { 

    }

    ngOnInit() {

    }

    onUnitCostChange(value: string) {

        const currentAmount = this.calculation.amount;
        

        const updatedCalc: Calculation = {
            amount: currentAmount,
            unitCost: 0,
            total: "0"
        };

        const changingUnitCost = parseFloat(value);

        if (changingUnitCost) {
            updatedCalc.unitCost = changingUnitCost;
            updatedCalc.total = this.roundN(changingUnitCost * currentAmount, 2);
            if (updatedCalc.total == "NaN") { updatedCalc.total = "0"; }
        }

        this.calculation = updatedCalc;

        this.onTouchedCallback();
        this.onChangeCallback(this.calculation);
        this.change.emit(this.calculation);
    }

    onAmountChange(value: string) {

        const currentUnitCost = this.calculation.unitCost;

        const updatedCalc: Calculation = {
            amount: 0,
            unitCost: currentUnitCost,
            total: "0"
        };

        const changingAmount = parseFloat(value);

        if (changingAmount) {
            updatedCalc.amount = changingAmount;
            updatedCalc.total = this.roundN(changingAmount * currentUnitCost, 2);
            if (updatedCalc.total == "NaN") { updatedCalc.total = "0"; }
        } 
        
        this.calculation = updatedCalc;

        this.onTouchedCallback();
        this.onChangeCallback(this.calculation);
        this.change.emit(this.calculation);
    }

    roundN(num, n) { // TODO: move to a common helper function spot
        return (
                    Math.round(
                        num * Math.pow(10, n)
                    ) 
                    / Math.pow(10, n)
                ).toFixed(n);
    }
}
