import { ConfigService }        from './services/config.service';
import { CurrentUserService }   from "./services/current-user.service";
import { PDAppService }         from "./services/pd-app.service";
import { MembersService }       from "./services/members.service";
import { TermsService }         from "./services/terms.service";
import { MonitoringService } from "./services/monitoring.service";
import {BulkCreditService} from "./services/bulk-credit.service";
import { OrderByPipe } from './order-by.pipe';
import { BlobClientService } from './services/blob.service';


export const PROVIDERS = [
    CurrentUserService,
    PDAppService,
    ConfigService,
    MembersService,
    TermsService,
    MonitoringService,
    BulkCreditService,
    BlobClientService,
    OrderByPipe
];
