import {
    Component,
    forwardRef,
    OnInit,
    Input
} from '@angular/core';
import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor
} from '@angular/forms';
import { IFileUpload } from "../../models/file-upload.model";
import { BlobClientService } from '../../services/blob.service';
import { ConfigService } from '../../services/config.service';
import { RestError } from '@azure/storage-blob';
import { ToastrService } from 'ngx-toastr';
const noop = () => {
};

export const FILE_UPLOAD_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true
};


@Component({
    selector: 'eil-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [FILE_UPLOAD_CONTROL_VALUE_ACCESSOR]
}
)
export class FileUploadComponent implements OnInit, ControlValueAccessor {
    @Input() public disabled?: boolean;

    // The internal data model
    files: IFileUpload[] = [];

    // Placeholders for the callbacks which are later provided
    // by the Control Value Accessor
    onTouchedCallback: () => void = noop;
    onChangeCallback: (_: any) => void = noop;

    constructor(private blobClient: BlobClientService, private toatrService: ToastrService) {}

    ngOnInit() {
    }

    // get accessor
    get value(): IFileUpload[] {
        return this.files;
    }

    // set accessor including call the onchange callback
    set value(v: IFileUpload[]) {
        if (!v) { v = []; }
        if (v !== this.files) {
            this.files = v;
            this.onChangeCallback(v);
        }
    }

    // From ControlValueAccessor interface
    writeValue(value: IFileUpload[]) {
        if (!value) { value = []; }
        if (value !== this.files) {
            this.files = value;
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    addFile() {
        this.files.splice(0, 0, {});
        this.onChangeCallback(this.files);
    }

    fileChanged(file, e) {
        file.filename = e.target.files[0].name;
        if (this.fileIsAcceptable(file.filename)) {
            file.isSelected = true;
            file.isUploaded = false;
            file.isUploading = true;

            this.blobClient.uploadFile(e.target.files[0], (isSuccess, result, error) => {
                //console.log('Transfer completed !');
                if (isSuccess) {
                    file.isUploading = false;
                    file.isUploaded = true;
                    file.blobStorageUrl = result;
                } else {
                    this.toatrService.error("Something went wrong, please try again later.");
                    this.removeFile(file);
                }
            })
        }
    }

    fileIsAcceptable(filename: string) {
        return filename &&
            filename.toLowerCase().endsWith(".docx") ||
            filename.toLowerCase().endsWith(".pdf") ||
            filename.toLowerCase().endsWith(".bmp") ||
            filename.toLowerCase().endsWith(".doc") ||
            filename.toLowerCase().endsWith(".jpg") ||
            filename.toLowerCase().endsWith(".jpeg") ||
            filename.toLowerCase().endsWith(".png") ||
            filename.toLowerCase().endsWith(".gif");
    }

    isImageLink(linkText) {
        const regex = /jpe?g|gif|png$/;
        return regex.test(linkText);
    }

    deleteFile(file: IFileUpload) {
        if (!confirm(`Are you sure you want to delete ${file.filename}?`)) {
            return;
        }

        file.isDeleting = true;
        if (!file.blobStorageUrl) {
            // The file didn't get uploaded properly, possibly due to poor connection
            this.removeFile(file)
            return;
        }
        const parts = file.blobStorageUrl.split("/");
        const blobName = parts[parts.length - 1];
        this.blobClient.deleteFile(blobName).then(() => {
            this.removeFile(file);
        }).catch((reason: RestError) => {
            if (reason.code === "BlobNotFound") {
                // The file already got deleted but the application didn't get saved
                this.removeFile(file);
                return;
            }
            this.toatrService.error("Something went wrong, please try again later.");
            console.log(reason);
            file.isDeleting = false;
        });
    }

    private removeFile(file: IFileUpload) {
        const index = this.files.indexOf(file);
        if (index > -1) {
            this.files.splice(index, 1);
            this.onChangeCallback(this.files);
        }
    }

    cancelFile(file) {
        // remove from the list
        const index = this.files.indexOf(file);
        if (index > -1) {
            this.files.splice(index, 1);
            this.onChangeCallback(this.files);
        }
    }
}
