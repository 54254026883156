import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { ServiceBase } from './base.service';
import { IPDAppModel } from "../models/pd-app.model";
import { ParsedError } from "./parsed-error";
import { ToastrService } from 'ngx-toastr';
import { MonitoringService } from "./monitoring.service";

@Injectable()
export class PDAppService extends ServiceBase {

    constructor(protected http: HttpClient,
        protected monitoringService: MonitoringService,
        protected toastrService: ToastrService) {
        super(http, monitoringService, toastrService);
    }

    private apiUrl = 'pdapp/';

    getMany(filter, callback: (isSuccess: boolean, result?: IPDAppModel[], error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'GetAllForFilter';
        this.http.post<Array<IPDAppModel>>(callUrl, filter)
            .subscribe(
            result => {
                callback(true, result, null);
            },
            error => {
                // TODO
                callback(false, null, this.handleError(error, filter));
            });
    }

    getOne(id, callback: (isSuccess: boolean, result?: IPDAppModel, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'GetOne';
        this.http.get<IPDAppModel>(callUrl,
                {
                    params: {
                        "id": id
                    }
                })
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, {}, this.handleError(error, id));
                });
    }

    savePending(pdapp: IPDAppModel, callback: (isSuccess: boolean, result?: IPDAppModel, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'savePending';
        this.http.post(callUrl, pdapp)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error, pdapp));
                });
    }

    cancel(pdapp: IPDAppModel, callback: (isSuccess: boolean, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'cancel';
        this.http.post(callUrl, pdapp.id)
            .subscribe(
                result => {
                    callback(true, null);
                },
                error => {
                    // TODO
                    callback(false, this.handleError(error, pdapp));
                });
    }

    submitExpenses(pdapp: IPDAppModel, callback: (isSuccess: boolean, result?: IPDAppModel, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'submitExpenses';
        this.http.post(callUrl, pdapp)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error, pdapp));
                });
    }

    approve(pdapp: IPDAppModel, callback: (isSuccess: boolean, result?: IPDAppModel, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'approve';
        this.http.post(callUrl, pdapp)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error, pdapp));
                });
    }

    expensesDue(pdapp: IPDAppModel, callback: (isSuccess: boolean, result?: IPDAppModel, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'expensesDue';
        this.http.post(callUrl, pdapp)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error, pdapp));
                });
    }

    expensesOverdue(pdapp: IPDAppModel, callback: (isSuccess: boolean, result?: IPDAppModel, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'expensesOverdue';
        this.http.post(callUrl, pdapp)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error, pdapp));
                });
    }

    decline(pdapp: IPDAppModel, callback: (isSuccess: boolean, result?: IPDAppModel, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'decline';
        this.http.post(callUrl, pdapp)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error, pdapp));
                });
    }

    approveExpenses(pdapp: IPDAppModel, callback: (isSuccess: boolean, result?: IPDAppModel, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'approveExpenses';
        this.http.post(callUrl, pdapp)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error, pdapp));
                });
    }

    declineExpenses(pdapp: IPDAppModel, callback: (isSuccess: boolean, result?: IPDAppModel, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'declineExpenses';
        this.http.post(callUrl, pdapp)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, null, this.handleError(error, pdapp));
                });
    }
}

