<div class="login-body">
  <p *ngIf="!currentUser || !currentUser.isLoaded">
    Checking user...
  </p>
  <p *ngIf="currentUser && currentUser.isLoaded && !currentUser.isAuthenticated">
    Need to sign in. <a href="DoLogin">Sign in</a>
  </p>
  <p *ngIf="currentUser && currentUser.isLoaded && currentUser.isAuthenticated">
      Signed in as {{ currentUser.firstName }} {{ currentUser.lastName }} ({{currentUser.email}})
  </p>
</div>
