import { Component,
         OnInit }       from '@angular/core';

@Component({
    selector:           'eil-admin',
    templateUrl:        './admin.component.html',
    styleUrls:          ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    constructor() { 

    }

    ngOnInit() {

    }

}
