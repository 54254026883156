<div class="credits">

  <h4>Credits</h4>

  <div class="row">
    <div class="col-sm-12">
      <eil-color-bar [isCreditsMode]="true" [available]="availableCredits" [pending]="pendingCredits" [ceiling]="maximumAllowedCredits"></eil-color-bar>
    </div>
  </div>
  <div class="row balance-row">
    <div class="col-sm-8 balance" [ngClass]="{'red': currentUser && (!currentUser.unpendingBalance || currentUser.unpendingBalance < 0) }">
      <span class="balance-amt" [ngClass]="{'red': currentUser && (!currentUser.unpendingBalance || currentUser.unpendingBalance < 0) }">
          {{ availableCredits | currency:'CAD':'symbol-narrow':'1.2-2' }}
      </span>
      <span class="balance-label" [ngClass]="{'red': currentUser && (!currentUser.unpendingBalance || currentUser.unpendingBalance < 0) }">
        available credit
      </span>
      <div>
        <span class="pending-amt">
            {{ pendingCredits | currency:'CAD':'symbol-narrow':'1.2-2' }}
          </span>
          <span class="pending-label">
            pending credit
          </span>
      </div>
    </div>
    <div class="col-sm-4 maximum">
        <div class="maximum-amt">
            {{ maximumAllowedCredits | currency:'CAD':'symbol-narrow':'1.2-2' }}
        </div>
      <div class="maximum-label">
        Maximum
      </div>
    </div>
  </div>
  <!--<div class="row">
    <div class="col-sm-12 replenishment">
      <span class="replenishment-amt">
        $800
      </span> replenishment on June 1, 2018
    </div>
  </div>-->
</div>
