
<div class="row top-row">
    <div class="col-xs-12 col-lg-5">
        <eil-funding></eil-funding>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 excel-link">
        <a href="{{ linkToExcel }}">Open all in Excel</a>
    </div>
</div>
  <div class="row">
    <div class="col-sm-12">
      <eil-admin-applist></eil-admin-applist>
    </div>
  </div>
  
