import { HttpClient } from '@angular/common/http';
import { ServiceBase } from './base.service';
import { ToastrService } from 'ngx-toastr';
import { ParsedError } from './parsed-error';
import { Injectable } from '@angular/core';

export interface UploadParams {
    storageAccount: string;
    containerName: string;
}

@Injectable()
export class BlobClientService extends ServiceBase {
    private apiUrl = "fileUpload/";

    constructor(protected http: HttpClient,
        protected toastrService: ToastrService) {
         super(http, null, toastrService);
    }

    public async deleteFile(fileName: string) {
        const callUrl = this.apiUrl + "DeleteFile?fileName=" + fileName;
        this.http.delete(callUrl)
            .subscribe(
                result => {

                },
                error => {
                    this.handleError(error);
                }
            )
    }

    public async uploadFile (file: File, callback: (isSuccess: boolean, result?: string, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + "UploadFile";
        const formData = new FormData();
        formData.append("uploadedFile", file);
        this.http.post<string>(callUrl, formData)
            .subscribe(
                result => {
                    callback(true, result);
                },
                error => {
                    callback(false, null, this.handleError(error));
                }
            )
    }
}
