import { Component,
         OnInit }       from '@angular/core';

import { IUserDetail }       from "../../models/user-detail.model";
import { CurrentUserService } from "../../services/current-user.service";
import { Router,
    ActivatedRoute }     from '@angular/router';
import { ROUTES }             from '../../routes';
import { ToastrService }      from 'ngx-toastr';
import {MembersService} from "../../services/members.service";
import { MemberLogic } from "../../models/member-logic";
import moment from "moment";

@Component({
        selector:       'eil-account-detail',
        templateUrl:    './account-detail.component.html',
        styleUrls:      ['./account-detail.component.scss']
    }
)
export class AccountDetailComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    currentUser: IUserDetail = {};
    user: IUserDetail = {};
    waiting: boolean;
    memberLogic = new MemberLogic();
    emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    postalCodeRegex = /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/i;
    phoneNumberRegex = /^1?[ -]?\(?[0-9]{3}\)?[ -]?[0-9]{3}[ -]?[0-9]{4}$/;

    creditsToAdd: number;

    constructor(private currentUserService: CurrentUserService,
        private membersService: MembersService,
        private toastrService: ToastrService,
        protected router: Router,
        private route: ActivatedRoute) {

    }

    ngOnInit() {

        // check if we're editing the current user or someone else (whether there's an ID parameter in the route)
        const paramMap = this.route.snapshot.paramMap;
        if (paramMap.has('id')) {

            // subscribe but only for the current user
            this.currentUserService.currentUser.subscribe(user => {
                this.currentUser = user;

                if (!user.isLoaded || !user.isAuthenticated) {
                    // redirect to login (with a return path) so that the user can be authorized and then come back to this
                    this.router.navigate([ROUTES.LOGIN], { queryParams: { returnUrl: this.router.url } });

                }
            });

            const id = paramMap.get('id');
            if (id) {
                // flag wait
                this.waiting = true;
                this.membersService.getOne(id,
                    (success, result) => {
                        if (success) {

                            this.user = result;

                            // unflag wait
                            this.waiting = false;
                        }
                    });
            } else {
                // TODO: throw an error?
            }
        } else {
            // subscribe as both (force refresh though)
            this.currentUserService.load(() => {});
            this.currentUserService.currentUser.subscribe(user => {
                this.currentUser = user;
                this.user = user;

                if (!user.isLoaded || !user.isAuthenticated) {
                    // redirect to login (with a return path) so that the user can be authorized and then come back to this
                    this.router.navigate([ROUTES.LOGIN], { queryParams: { returnUrl: this.router.url } });

                }
            });
        }
    }

    showAdminPanel() {
        return this.currentUser && this.currentUser.email
            && this.currentUser.isAdmin
            && this.user && this.user.email
            && this.user.email !== this.currentUser.email;
    }

    onSave() {
        this.waiting = true;
        this.currentUserService.save(this.user,
            (success, result, error) => {
                this.waiting = false;
                if (success && result) {
                    this.toastrService.success("Information saved.");
                    if (this.currentUser.isAdmin) {
                        this.router.navigateByUrl(`${ROUTES.ADMIN}/${ROUTES.ADMIN_MEMBERS}`);
                    } else {
                        this.router.navigateByUrl(ROUTES.HOME);
                    }
                } else if (!error.displayHasBeenHandled) {
                    this.toastrService.error(error.errorMessage);
                }
            });
    }

    toggleMemberSetting(member: IUserDetail, valueToSet: boolean, settingName: string) {

        if (confirm(`Change ${settingName} for ${member.firstName} ${member.lastName} to ${valueToSet}?`)) {
            this.waiting = true;
            this.membersService.setMemberSetting(member.email, settingName, valueToSet,
                (success, result, error) => {
                    if (success) {
                        this.user = result;
                        this.toastrService.success("Setting changed.");
                    } else if (!error.displayHasBeenHandled) {
                        this.toastrService.error(error.errorMessage);
                    }
                    // unflag wait
                    this.waiting = false;
                });
        }
    }

    reject(member: IUserDetail) {
        if (confirm(`This will delete ${member.firstName} ${member.lastName}.  Are you sure?`)) {
            this.waiting = true;
            this.membersService.delete(member.email,
                (success, error) => {
                    if (success) {
                        this.toastrService.success("Member rejected.");
                        this.router.navigateByUrl(`${ROUTES.ADMIN}/${ROUTES.ADMIN_MEMBERS}`);
                    } else if (!error.displayHasBeenHandled) {
                        this.toastrService.error(error.errorMessage);
                    }
                    // unflag wait
                    this.waiting = false;
                });
        }
    }

    deactivate(member: IUserDetail) {
        // TODO: something clearer
        this.toggleMemberSetting(member, false, "IsApproved");
    }

    reactivate(member: IUserDetail) {
        // TODO: something clearer
        this.toggleMemberSetting(member, true, "IsApproved");
    }

    toggleIsRegistered(member: IUserDetail, valueToSet: boolean) {
        this.toggleMemberSetting(member, valueToSet, "IsRegistered");
    }

    toggleIsApproved(member: IUserDetail, valueToSet: boolean) {
        this.toggleMemberSetting(member, valueToSet, "IsApproved");
    }

    toggleIsMember(member: IUserDetail, valueToSet: boolean) {
        this.toggleMemberSetting(member, valueToSet, "IsMember");
    }

    toggleIsAdmin(member: IUserDetail, valueToSet: boolean) {
        this.toggleMemberSetting(member, valueToSet, "IsAdmin");
    }

    addCredits(amount) {
        if (confirm(`Add ${amount} to this member's credits?`)) {
            this.waiting = true;
            this.membersService.addCredits(this.user.email,
                amount,
                (success, result, error) => {
                    if (success) {
                        this.toastrService.success("Credits added.");
                        this.user = result;
                    } else if (!error.displayHasBeenHandled) {
                        this.toastrService.error(error.errorMessage);
                    }
                    // unflag wait
                    this.waiting = false;
                });
        }
    }

    unsubscribe() {
        if (confirm(`Are you sure you want to unsubscribe from all EIL mail?`)) {
            this.waiting = true;
            this.membersService.unsubscribe(this.user.email,
                (success, result, error) => {
                    if (success) {
                        this.toastrService.success("Unsubscribed.");
                        this.user = result;
                    } else if (!error.displayHasBeenHandled) {
                        this.toastrService.error(error.errorMessage);
                    }
                    // unflag wait
                    this.waiting = false;
                });
        }
    }

    resubscribe() {
        if (confirm(`Are you sure you want to resubscribe to EIL mail?`)) {
            this.waiting = true;
            this.membersService.resubscribe(this.user.email,
                (success, result, error) => {
                    if (success) {
                        this.toastrService.warning("Subscribed.  NOTE: if you have previously unsubscribed, you may receive an email requiring that you confirm your resubscription before it takes effect.");
                        this.user = result;
                    } else if (!error.displayHasBeenHandled) {
                        this.toastrService.error(error.errorMessage);
                    }
                    // unflag wait
                    this.waiting = false;
                });
        }
    }

    getDisplayDate(date) {
        return moment(date).format("YYYY-MM-DD");
    }
}
