import { Component,
         OnInit }       from '@angular/core';
import {TermsService} from "../../services/terms.service";
import moment from 'moment';
import {ITerm} from "../../models/term.model";

@Component({
        selector:       'eil-funding',
        templateUrl:    './funding.component.html',
        styleUrls:      ['./funding.component.scss']
    }
)
export class FundingComponent implements OnInit {

    terms = [];
    loading = false;

    constructor(private termsService: TermsService) {
    }

    ngOnInit() {
        this.loading = true;
        this.termsService.getImportant((success, result) => {
            this.terms = result;

            // unflag wait
            this.loading = false;
        });
    }

    getTermRange(term: ITerm) {
        // TODO: this actually should be common code as it's used on applist as well
        const start = moment(term.startDate);
        const end = moment(term.endDate);
        const fullFormatString = "MMMM D, YYYY";

        if (start.get('year') !== end.get('year')) {
            return `${start.format(fullFormatString)} - ${end.format(fullFormatString)}`;
        }

        if (start.get('month') !== end.get('month')) {
            return `${start.format("MMMM D")} - ${end.format(fullFormatString)}`;
        }

        return `${start.format("MMMM D")} - ${end.format("D, YYYY")}`;
    }
}
