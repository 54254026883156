
<div class="row top-row">
  <div class="col-sm-12 col-md-6 col-lg-5">
    <eil-funding></eil-funding>
  </div>
  <div class="col-xs-0 col-md-1 col-lg-2">
  </div>
  <div class="col-sm-12 col-md-5">
    <eil-credits></eil-credits>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="new-button-div">
      <a class="btn btn-default" [routerLink]="[linkToNew]">
        + New Application
      </a>
    </div>
    <eil-pd-applist></eil-pd-applist>
  </div>
</div>

