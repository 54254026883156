import { Component,
         OnInit }         from '@angular/core';
import { ROUTES }         from "../../../routes";
import { MembersService } from "../../../services/members.service";
import { IUserDetail }    from "../../../models/user-detail.model";
import { ToastrService }  from 'ngx-toastr';
import moment from "moment";
import {CurrentUserService} from "../../../services/current-user.service";
import {MemberLogic} from "../../../models/member-logic";
import { OrderByPipe } from '../../../order-by.pipe';

@Component({
        selector:       'eil-member-list',
        templateUrl:    './member-list.component.html',
        styleUrls:      ['./member-list.component.scss']
    }
)
export class MemberListComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;
    currentUser: IUserDetail;
    linkToEdit: string;
    loading = false;
    memberLogic = new MemberLogic();
    members = [];
    order: string = "getStatus(member)";
    reverse: boolean = false;
    searchText: string = "";
    tablePage: number;

    constructor(private membersService: MembersService, private toastrService: ToastrService, private currentUserService: CurrentUserService, orderByPipe: OrderByPipe) {
        this.linkToEdit = `/${ROUTES.ADMIN}/${ROUTES.ADMIN_EDITMEMBER}/`;
        this.loading = true;
        // TODO: filter?
        this.membersService.getAll((success, result, error) => {
            // unflag wait
            this.loading = false;
            if (success) {
                this.members = result;
            } else if (error && !error.displayHasBeenHandled) {
                toastrService.error(error.errorMessage);
            }
        });
        this.tablePage = 1; //Start at the first page
        orderByPipe.transform(this.members, "getStatus(member)")
    }

    // TODO: put this in a common library!
    getAgo(dateString) {
        if (!dateString) {
            return '--';
        }
        return moment(dateString).fromNow();
    }

    toggleMemberSetting(member: IUserDetail, valueToSet: boolean, settingName: string) {

        if (confirm(`Change ${settingName} for ${member.firstName} ${member.lastName} to ${valueToSet}?`)) {
            this.loading = true;
            this.membersService.setMemberSetting(member.email, settingName, valueToSet,
                (success, result, error) => {
                    if (success) {
                        let index = this.members.indexOf(member);
                        if (index < 0) {
                            this.toastrService.warning("member not found in list...");
                        } else {
                            this.members[index] = result;
                        }
                    } else if (!error.displayHasBeenHandled) {
                        this.toastrService.error(error.errorMessage);
                    }
                    // unflag wait
                    this.loading = false;
                });
        }
    }

    reject(member: IUserDetail) {
        if (confirm(`This will delete ${member.firstName} ${member.lastName}.  Are you sure?`)) {
            this.loading = true;
            this.membersService.delete(member.email,
                (success, error) => {
                    if (success) {
                        let index = this.members.indexOf(member);
                        if (index < 0) {
                            this.toastrService.warning("member not found in list...");
                        } else {
                            this.members.splice(index, 1);
                        }
                    } else if (!error.displayHasBeenHandled) {
                        this.toastrService.error(error.errorMessage);
                    }
                    // unflag wait
                    this.loading = false;
                });
        }
    }

    deactivate(member: IUserDetail) {
        // TODO: something clearer
        this.toggleMemberSetting(member, false, "IsApproved");
    }

    reactivate(member: IUserDetail) {
        // TODO: something clearer
        this.toggleMemberSetting(member, true, "IsApproved");
    }

    toggleIsRegistered(member: IUserDetail, valueToSet: boolean) {
        this.toggleMemberSetting(member, valueToSet, "IsRegistered");
    }

    toggleIsAuditor(member: IUserDetail, valueToSet: boolean) {
        this.toggleMemberSetting(member, valueToSet, "IsAuditor");
    }

    toggleIsApproved(member: IUserDetail, valueToSet: boolean) {
        this.toggleMemberSetting(member, valueToSet, "IsApproved");
    }

    toggleIsMember(member: IUserDetail, valueToSet: boolean) {
        this.toggleMemberSetting(member, valueToSet, "IsMember");
    }

    toggleIsAdmin(member: IUserDetail, valueToSet: boolean) {
        this.toggleMemberSetting(member, valueToSet, "IsAdmin");
    }

    ngOnInit() {
        // TODO: flag wait?
        this.currentUserService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }

    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }

        this.order = value;
    }
}
