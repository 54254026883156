import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';

import { ROUTES } from './routes';
import { TwoOutletLayoutComponent } from './two-outlet-layout/two-outlet-layout.component';
import { LoginComponent } from './login/login.component';
import { SideNavComponent } from './side-nav/side-nav.component';

import { PDApplicationsComponent } from './pd/pd-applications/pd-applications.component';
import { AccountDetailComponent } from './pd/account-detail/account-detail.component';
import { AwaitingApprovalComponent } from './pd/awaiting-approval/awaiting-approval.component';
import { PDFormComponent } from "./pd/pd-applications/pd-form/pd-form.component";

import { FundtermsComponent } from './admin/fundterms/fundterms.component';
import { MembersComponent } from './admin/members/members.component';
import { AdvancedComponent } from './admin/advanced/advanced.component';
import { AdminApplicationsComponent } from './admin/admin-applications/admin-applications.component';
import { FundtermFormComponent } from "./admin/fundterms/fundterm-form/fundterm-form.component";
import { AuthGuard } from './authenticate-route.guard';


export const appRoutes: Routes = [
    { path: ROUTES.HOME, redirectTo: ROUTES.LOGIN, pathMatch: 'full' },
    { path: ROUTES.LOGIN, component: LoginComponent },
    {
        path: ROUTES.REGISTER,
        component: TwoOutletLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ROUTES.REGISTER_DETAIL,
                component: AccountDetailComponent
            },
            {
                path: ROUTES.REGISTER_AWAITING,
                component: AwaitingApprovalComponent
            }
        ]
    },
    {
        path: ROUTES.PD,
        component: TwoOutletLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ROUTES.HOME,
                redirectTo: ROUTES.PD_APPLICATIONS,
                pathMatch: 'prefix'
            },
            {
                path: ROUTES.PD_APPLICATIONS,
                data: {
                    showInSideNavAs: 'Applications',
                    svgIcon: 'applications-icon',
                },
                component: PDApplicationsComponent
            },
            {
                path: ROUTES.PD_NEWAPP,
                component: PDFormComponent
            },
            {
                path: ROUTES.PD_EDITAPP + '/:id',
                component: PDFormComponent
            },
            {
                path: ROUTES.PD_ACCOUNTDETAIL,
                data: {
                    showInSideNavAs: 'Account Detail',
                    svgIcon: 'members-icon',
                },
                component: AccountDetailComponent
            },
            // {
            //     path: ROUTES.PD_AWAITINGAPPROVAL,
            //     component: AwaitingApprovalComponent
            // },
            {
                path: ROUTES.HOME,
                outlet: 'sidenav',
                component: SideNavComponent
            },
            {
                path: ROUTES.IDPARAM,
                outlet: 'sidenav',
                component: SideNavComponent
            }
        ]
    },
    {
        path: ROUTES.ADMIN,
        component: TwoOutletLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ROUTES.HOME,
                redirectTo: ROUTES.ADMIN_APPLICATIONS,
                pathMatch: 'prefix'
            },
            {
                path: ROUTES.ADMIN_APPLICATIONS,
                data: {
                    showInSideNavAs: 'Applications',
                    svgIcon: 'applications-icon',
                },
                component: AdminApplicationsComponent
            },
            {
                path: ROUTES.ADMIN_FUNDTERMS,
                data: {
                    showInSideNavAs: 'Fund Terms',
                    svgIcon: 'fundterms-icon',
                },
                component: FundtermsComponent
            },
            {
                path: ROUTES.ADMIN_FUNDTERMS_NEW,
                component: FundtermFormComponent
            },
            {
                path: ROUTES.ADMIN_FUNDTERMS_EDIT + '/:id',
                component: FundtermFormComponent
            },
            {
                path: ROUTES.ADMIN_MEMBERS,
                data: {
                    showInSideNavAs: 'Members',
                    svgIcon: 'members-icon',
                },
                component: MembersComponent
            },
            {
                path: ROUTES.ADMIN_EDITMEMBER + '/:id',
                component: AccountDetailComponent
            },
            //{
            //    path: ROUTES.ADMIN_PDEVENTS,
            //    showInSideNavAs: 'PD Events',
            //    svgIcon: 'pdevents-icon',
            //    component: PDEventsComponent
            //},
            {
                path: ROUTES.ADMIN_ADVANCED,
                data: {
                    showInSideNavAs: 'Advanced',
                    svgIcon: 'advanced-icon',
                },
                component: AdvancedComponent
            },
            {
                path: ROUTES.HOME,
                outlet: 'sidenav',
                component: SideNavComponent
            },
            {
                path: ROUTES.IDPARAM,
                outlet: 'sidenav',
                component: SideNavComponent
            }
        ]
    },
    { path: ROUTES.WILDCARD, redirectTo: ROUTES.LOGIN }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
