import { Component, ViewChild, OnInit, TemplateRef } from "@angular/core";
import { IPDAppModel } from "../../../models/pd-app.model";
import { IUserDetail } from "../../../models/user-detail.model";
import { CurrentUserService } from "../../../services/current-user.service";
import { PDAppService } from "../../../services/pd-app.service";
import { ConfigService } from "../../../services/config.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ROUTES } from "../../../routes";
import { ToastrService } from "ngx-toastr";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { CalculationPair } from "../../../models/calculation-pair.model";
import moment from "moment";
import { ITerm } from "../../../models/term.model";
import { TermsService } from "../../../services/terms.service";
import { UploadParams } from "../../../services/blob.service";

interface PDFormState {
    currentUserIsSubmitter?: boolean;
    showUserDetails?: boolean;
    canEditUserDetails?: boolean;
    canEditEventDetails?: boolean;
    canEditExpenses?: boolean;
    expensesAreActual?: boolean;
    anticipatedIsApproved?: boolean;
    canSubmit?: boolean;
    canSubmitExpenses?: boolean;
    showExpenseReceipts?: boolean;
    canUploadExpenseReceipts?: boolean;
    showSubNames?: boolean;
    canProvideSubNames?: boolean;
    canApproveOrDeclineApp?: boolean;
    canApproveOrDeclineExpenses?: boolean;
    canNagSubmitterToSubmitExpenses?: boolean;
    showExplanation?: boolean;
    canEditExplanation?: boolean;
    canEditAdditionalComments?: boolean;
    canCancelApplication?: boolean;
    gridUnitCostEditable?: boolean;
    messageAboutState?: string;
    submitText?: string;
}

@Component({
    selector: "eil-pd-form",
    templateUrl: "./pd-form.component.html",
    styleUrls: ["./pd-form.component.scss"],
})
export class PDFormComponent implements OnInit {
    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    @ViewChild("principalConfirmTemplate", { static: true })
    principalConfirmTemplateRef: TemplateRef<any>;
    @ViewChild("noExpensesToSubmitTemplate", { static: true })
    noExpensesTemplateRef: TemplateRef<any>;

    costPerHalfDay: number;
    costPerFullDay: number;
    maximumGridCostPerDay: number;
    carAllowancePerKm: number;
    subsistencePerDay: number;
    maximumAllowedCredits: number;
    maxAccommodationPricePerNight: number;
    maxExpensesSubmitable: number;
    maxSubsistencePerDay: number;
    dateHelpText: string;
    conferenceInfoText: string;
    pdExplanationHelpText: string;
    substituteHelpText: string;
    mileageHelpText: string;
    subsistenceHelpText: string;

    currentUser: IUserDetail;
    configIsLoaded = false;
    blobConfig: UploadParams;

    loadingPDApp = false;
    savingPDApp = false;

    originalApplicationApprovalComments;
    originalExpenseApprovalComments;

    principalConfirmModal: BsModalRef;
    noExpensesToSubmitModal: BsModalRef;
    noExpenseHeader: string = "";
    noExpenseModalText: string[] = [];

    dateCustomClasses: { date: Date; classes: string[] }[] = [];
    // To enable scrolling the calendar even when it's disabled, we fake disable it by only enabling only the maximum date in js
    datesEnabled = [new Date(8640000000000000)];

    state: PDFormState = {};

    pdApp: IPDAppModel = {
        anticipatedTotal: "0",
        actualTotal: "0",
        event: {
            useNonContinuousDate: true,
            nonContinuousDates: [],
            files: [],
        },
        applicant: {},
        carAllowanceCalculation: CalculationPair.newDefault(),
        halfDaysCalculation: CalculationPair.newDefault(),
        fullDaysCalculation: CalculationPair.newDefault(),
        gridDaysCalculation: CalculationPair.newDefault(),
        accommodationCalculation: CalculationPair.newDefault(),
        subsistenceCalculation: CalculationPair.newDefault(),
        registrationFee: {},
        flightsCost: {},
        parkingCost: {},
        carRentalCost: {},
        otherCost: {},
    };
    term: ITerm = {};
    loadingTerm = false;

    history = [];

    constructor(
        private currentUserService: CurrentUserService,
        private pdAppService: PDAppService,
        private configService: ConfigService,
        private toastrService: ToastrService,
        protected router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private termsService: TermsService
    ) {}

    ngOnInit() {
        // TODO: flag wait?
        this.currentUserService.currentUser.subscribe((user) => {
            this.currentUser = user;

            // call setFormState in case the user got updated after loading the pd app or something
            this.setFormState("init");

            // fill the user if it's a new application
            if (
                !this.pdApp.id &&
                (!this.pdApp.applicant || !this.pdApp.applicant.firstName)
            ) {
                this.pdApp.applicant = user;
                this.pdApp.applicantName = user.firstName + " " + user.lastName;
            }
        });

        this.configService.instance.subscribe((config) => {
            this.pdApp.halfDaysCalculation.anticipated.unitCost =
                config.substituteCostPerHalfDay;
            this.pdApp.halfDaysCalculation.actual.unitCost =
                config.substituteCostPerHalfDay;

            this.pdApp.fullDaysCalculation.anticipated.unitCost =
                config.substituteCostPerFullDay;
            this.pdApp.fullDaysCalculation.actual.unitCost =
                config.substituteCostPerFullDay;

            this.pdApp.gridDaysCalculation.anticipated.unitCost =
                config.substituteMaximumGridCostPerDay;
            this.pdApp.gridDaysCalculation.actual.unitCost =
                config.substituteMaximumGridCostPerDay;

            this.pdApp.carAllowanceCalculation.anticipated.unitCost =
                config.carAllowancePerKm;
            this.pdApp.carAllowanceCalculation.actual.unitCost =
                config.carAllowancePerKm;

            this.pdApp.subsistenceCalculation.anticipated.unitCost =
                config.subsistencePerDay;
            this.maxSubsistencePerDay = config.subsistencePerDay;
            this.pdApp.subsistenceCalculation.actual.unitCost =
                config.subsistencePerDay;

            this.maxExpensesSubmitable = config.maxExpensesSubmitable;
            this.maximumAllowedCredits = config.maximumAllowedCredits;
            this.maxAccommodationPricePerNight =
                config.maxAccommodationPricePerNight;

            this.dateHelpText = config.dateHelpText;
            this.conferenceInfoText = config.conferenceInfoText;
            this.pdExplanationHelpText = config.pdExplanationHelpText;
            this.substituteHelpText = config.substituteExpenseHelpText;
            this.mileageHelpText = config.mileageHelpText;
            this.subsistenceHelpText = config.subsistenceHelpText;

            this.blobConfig = {
                storageAccount: config.blobStorageAccount,
                containerName: config.blobContainerName,
            };
        });
        // TODO: should load be called automatically by the service rather than manually on-demand here?
        this.configService.load((isSuccess) => {
            if (isSuccess) {
                this.configIsLoaded = true;
            }
        });

        // load an existing form if applicable
        const paramMap = this.route.snapshot.paramMap;
        if (paramMap.has("id")) {
            const id = paramMap.get("id");
            if (id) {
                // flag wait
                this.loadingPDApp = true;
                this.pdAppService.getOne(id, (success, result) => {
                    if (success) {
                        this.pdApp = result;

                        this.originalApplicationApprovalComments =
                            result.applicationApprovalComments;
                        this.originalExpenseApprovalComments =
                            result.expenseApprovalComments;

                        // control needs to be made aware to show things
                        if (this.pdApp.event.files) {
                            this.pdApp.event.files.map((e) => {
                                e.isSelected = true;
                                e.isUploaded = true;
                            });
                        }
                        // control needs to be made aware to show things
                        if (this.pdApp.receipts) {
                            this.pdApp.receipts.map((e) => {
                                e.isSelected = true;
                                e.isUploaded = true;
                            });
                        }

                        // date picker is selfish and wants Date objects, not strings.
                        // (https://github.com/valor-software/ngx-bootstrap/issues/4020)
                        if (
                            this.pdApp.event.startDate &&
                            !(this.pdApp.event.startDate instanceof Date)
                        ) {
                            this.pdApp.event.startDate = new Date(
                                this.pdApp.event.startDate
                            );
                        }
                        if (
                            this.pdApp.event.endDate &&
                            !(this.pdApp.event.endDate instanceof Date)
                        ) {
                            this.pdApp.event.endDate = new Date(
                                this.pdApp.event.endDate
                            );
                        }

                        if (this.pdApp.event.nonContinuousDates) {
                            this.pdApp.event.nonContinuousDates.map((value) => {
                                let date: Date;
                                if (!(value instanceof Date)) {
                                    date = new Date(value as string);
                                } else {
                                    date = value;
                                }
                                if (
                                    !this.pdApp.event.startDate ||
                                    date < this.pdApp.event.startDate
                                ) {
                                    this.pdApp.event.startDate = date;
                                }
                                if (
                                    !this.pdApp.event.endDate ||
                                    date > this.pdApp.event.endDate
                                ) {
                                    this.pdApp.event.endDate = date;
                                }
                                this.dateCustomClasses.push({
                                    date,
                                    classes: ["bg-info", "text-white"],
                                });
                            });
                        }

                        // combine histories of application and event
                        // TODO: don't do this if the event is shared?
                        if (result.history && result.history.length) {
                            this.history = result.history;
                        }
                        if (
                            result.event &&
                            result.event.history &&
                            result.event.history.length
                        ) {
                            this.history = this.history.concat(
                                result.event.history
                            );
                        }
                        this.history.sort((a, b) => {
                            const date1 = moment(a.actionDate);
                            const date2 = moment(b.actionDate);
                            return date2.valueOf() - date1.valueOf();
                        });

                        this.loadTerm();

                        // unflag wait
                        this.loadingPDApp = false;

                        // evaluate the state the form should be in and set it accordingly
                        this.setFormState("callback");
                    }
                });
            }
        } else {
            // set for a new submission
            this.setFormState("new");
            this.loadTerm();
        }
    }

    onCalendarSelect(value: Date): void {
        if (this.pdApp.event.nonContinuousDates) {
            if (!moment(value).isValid()) return;
            // The datepicker also includes the current time in this so we need to remove it
            value = new Date(value.toDateString());
            this.dateCustomClasses = this.dateCustomClasses.filter(
                (dcc) => !dcc.classes.some((c) => c === "bg-white")
            );
            if (!this.state.canEditEventDetails) {
                // This covers the edge case that someone scrolls all the way to the maximum date in js and clicks it
                this.dateCustomClasses.push({
                    date: value,
                    classes: ["bg-white", "text-secondary"],
                });
                return;
            }
            const existingIndex = this.pdApp.event.nonContinuousDates.findIndex(
                (date) => {
                    const maybeMoment = moment(date);
                    if (!maybeMoment.isValid()) return false;

                    return maybeMoment.isSame(moment(value), "date");
                }
            );
            if (existingIndex === -1) {
                // New selected date
                this.pdApp.event.nonContinuousDates.push(value);
                this.sortNonContinuous();
                this.dateCustomClasses.push({
                    date: value,
                    classes: ["bg-info", "text-white"],
                });
            } else {
                // Remove selected date
                this.pdApp.event.nonContinuousDates.splice(existingIndex, 1);
                this.sortNonContinuous();
                // Date doesn't support equality checking using === or !==
                this.dateCustomClasses = this.dateCustomClasses.filter(
                    (dcc) =>
                        new Date(dcc.date.toDateString()) < value ||
                        new Date(dcc.date.toDateString()) > value
                );
                this.dateCustomClasses.push({
                    date: value,
                    classes: ["bg-white", "text-secondary"],
                });
            }
            this.pdApp.event.startDate = this.pdApp.event.nonContinuousDates[0];
            this.pdApp.event.endDate =
                this.pdApp.event.nonContinuousDates[
                    this.pdApp.event.nonContinuousDates.length - 1
                ];
        }
    }

    loadTerm() {
        let startMoment = moment();
        if (this.pdApp && this.pdApp.event && this.pdApp.event.startDate) {
            let maybeMoment = moment(this.pdApp.event.startDate);
            if (maybeMoment.isValid()) {
                startMoment = maybeMoment;
            }
        }
        if (
            !this.term ||
            !this.term.id ||
            !startMoment.isBetween(
                moment(this.term.startDate),
                moment(this.term.endDate)
            )
        ) {
            this.loadingTerm = true;
            this.termsService.getForDateAndAppId(
                startMoment.format("YYYY-MM-DD HH:mm"),
                this.pdApp.id,
                (success, result, error) => {
                    if (success) {
                        this.term = result;
                    } else if (!error.displayHasBeenHandled) {
                        this.toastrService.error(error.errorMessage);
                    }
                    this.loadingTerm = false;
                }
            );
        }
    }

    setFormState(label) {
        //console.log(`setting form state (${label})`);
        // start from a clean, permissionless slate
        this.state = {
            submitText: "Submit Application",
        };

        if (this.loadingPDApp) {
            // if we're loading, leave everything permissionless for now and wait for the callback to execute
            return;
        }

        const submitOrEditMyOwnApplication: PDFormState = {
            submitText: "Submit Application",
            currentUserIsSubmitter: true,
            showUserDetails: true,
            canEditUserDetails: true,
            canEditEventDetails: true,
            canEditExpenses: true,
            showExplanation: true,
            canEditExplanation: true,
            canEditAdditionalComments: true,
            canSubmit: true,
        };

        const currentUserIsSubmitter =
            this.currentUser &&
            this.pdApp.applicant &&
            this.currentUser.email === this.pdApp.applicant.email;

        const viewOnlyState: PDFormState = {
            currentUserIsSubmitter: currentUserIsSubmitter,
        };

        const submitOrEditMyOwnExpenses: PDFormState = {
            currentUserIsSubmitter: true,
            canEditExpenses: true,
            expensesAreActual: true,
            showSubNames: true,
            canProvideSubNames: true,
            canSubmitExpenses: true,
            showExpenseReceipts: true,
            canUploadExpenseReceipts: true,
            canEditAdditionalComments: true,
        };

        if (!this.pdApp.id && this.currentUser.isAuditor) {
            // Allow an auditor to view only
            this.state = viewOnlyState;
            this.state.showUserDetails = true;
            this.state.messageAboutState =
                "View Only For Auditor. Auditor cannot create applications";
            return;
        }

        if (!this.pdApp.id) {
            // this is a new application
            this.state = submitOrEditMyOwnApplication;
            this.state.messageAboutState = "Create new application";
            this.state.submitText = "Submit Application";
            return;
        }

        if (
            !currentUserIsSubmitter &&
            !this.currentUser.isAdmin &&
            !this.currentUser.isAuditor
        ) {
            // they're not supposed to be in here at all and something else should have kept them from this. but it could be transitory?
            this.state = viewOnlyState;
            this.state.messageAboutState = "Not authorized to view";
            return;
        }

        if (
            !currentUserIsSubmitter &&
            !this.currentUser.isAdmin &&
            this.currentUser.isAuditor
        ) {
            // Allow an auditor to view only
            this.state = viewOnlyState;
            this.state.showUserDetails = true;
            this.state.messageAboutState = "View Only For Auditor";
            return;
        }

        switch (this.pdApp.status) {
            case "Submitted":
                if (currentUserIsSubmitter) {
                    // they may make edits
                    this.state = submitOrEditMyOwnApplication;
                    this.state.canCancelApplication = true;
                    this.state.messageAboutState = "Edit pending application";
                    this.state.submitText = "Save Edits";
                } else if (this.currentUser.isAdmin) {
                    // they may approve or decline, and tweak the anticipated expenses
                    this.state = {
                        showUserDetails: true,
                        showExplanation: true,
                        canEditExpenses: true,
                        canApproveOrDeclineApp: true,
                        messageAboutState: "Approve or decline application",
                    };
                }
                return;
            case "Declined":
                // nothing to do for a declined item, they can only view it
                this.state = viewOnlyState;
                // no wait that's not true, they need to be able to edit it as long as it switches back to Submitted
                this.state.canEditEventDetails = true;
                this.state.canEditExpenses = true;
                this.state.showExplanation = true;
                this.state.canEditExplanation = true;
                this.state.canEditAdditionalComments = true;
                if (currentUserIsSubmitter) {
                    this.state.canEditExplanation = true;
                    this.state.canEditAdditionalComments = true;
                    this.state.canSubmit = true;
                    this.state.submitText = "Re-submit with edits";
                    this.state.messageAboutState =
                        "View or re-submit declined application";
                } else if (this.currentUser.isAdmin) {
                    (this.state.showUserDetails = true),
                        (this.state.canApproveOrDeclineApp = true);
                    this.state.messageAboutState =
                        "Adjust or approve declined application";
                }
                return;
            case "Approved":
                const endMoment = moment(this.pdApp.event.endDate);
                const nowMoment = moment();
                if (endMoment.isBefore(nowMoment)) {
                    // if it's in the past,
                    if (currentUserIsSubmitter) {
                        // the submitter can enter actual expenses, receipts, and subs
                        this.state = submitOrEditMyOwnExpenses;
                        this.state.canEditEventDetails = true;
                        this.state.canCancelApplication = true;
                        this.state.messageAboutState = "Submit actual expenses";
                    } else if (this.currentUser.isAdmin) {
                        // admin can push a nag button if we build one
                        this.state = {
                            showUserDetails: true,
                            showExplanation: true,
                            canNagSubmitterToSubmitExpenses: true,
                            canCancelApplication: true,
                            messageAboutState: "Viewing outstanding expenses",
                        };
                    }
                } else {
                    // if it's approved and still upcoming/ongoing then no one can do anything to it
                    this.state = viewOnlyState;
                    // no wait that's not true, they need to be able to edit it as long as it switches back to Submitted
                    this.state.canEditEventDetails = true;
                    this.state.canEditExpenses = true;
                    this.state.showUserDetails = true;
                    this.state.showExplanation = true;
                    this.state.canCancelApplication = true;
                    if (currentUserIsSubmitter) {
                        this.state.canEditExplanation = true;
                        this.state.canEditAdditionalComments = true;
                        this.state.canSubmit = true;
                        this.state.submitText = "Re-submit with edits";
                        this.state.messageAboutState =
                            "View or edit upcoming application";
                    } else if (this.currentUser.isAdmin) {
                        this.state.canApproveOrDeclineApp = true;
                        this.state.canCancelApplication = true;
                        this.state.messageAboutState =
                            "Adjust or deny approved application";
                    }
                }
                this.state.anticipatedIsApproved = true;
                return;
            case "ExpensesSubmitted":
                // submitter can make edits to expenses.  Admin can make certain edits only
                if (currentUserIsSubmitter) {
                    this.state = submitOrEditMyOwnExpenses;
                    this.state.messageAboutState =
                        "Edit pending actual expenses";
                } else if (this.currentUser.isAdmin) {
                    this.state.canEditExpenses = true;
                    this.state.gridUnitCostEditable = true;
                    this.state.showUserDetails = true;
                    this.state.expensesAreActual = true;
                    this.state.showExpenseReceipts = true;
                    this.state.showSubNames = true;
                    this.state.canApproveOrDeclineExpenses = true;
                    this.state.messageAboutState =
                        "Approve or reject actual expenses";
                }
                this.state.anticipatedIsApproved = true;
                return;
            case "ExpensesApproved":
                this.state = viewOnlyState;
                this.state.expensesAreActual = true;
                this.state.showExplanation = true;
                this.state.showExpenseReceipts = true;
                this.state.showSubNames = true;
                if (currentUserIsSubmitter) {
                    this.state.messageAboutState = "View Approved Expenses";
                } else if (this.currentUser.isAdmin) {
                    this.state.showUserDetails = true;
                    this.state.canApproveOrDeclineExpenses = true;
                    this.state.messageAboutState =
                        "View or edit Approved Expenses";
                }
                this.state.anticipatedIsApproved = true;
                return;
            case "ExpensesDeclined":
                // can edit and resubmit
                if (currentUserIsSubmitter) {
                    this.state = submitOrEditMyOwnExpenses;
                    this.state.showExplanation = true;
                    this.state.messageAboutState =
                        "Edit declined actual expenses";
                } else if (this.currentUser.isAdmin) {
                    this.state = viewOnlyState;
                    this.state.expensesAreActual = true;
                    this.state.showExplanation = true;
                    this.state.showUserDetails = true;
                    this.state.showExpenseReceipts = true;
                    this.state.showSubNames = true;
                    this.state.canApproveOrDeclineExpenses = true;
                    this.state.messageAboutState =
                        "View or edit declined Expenses";
                }
                this.state.anticipatedIsApproved = true;
                return;
            case "ExpensesDue":
                if (currentUserIsSubmitter) {
                    this.state = submitOrEditMyOwnExpenses;
                    this.state.showUserDetails = true;
                    this.state.messageAboutState =
                        "Submit Expenses. Expenses Are Due";
                } else if (this.currentUser.isAdmin) {
                    this.state.showUserDetails = true;
                    this.state.canNagSubmitterToSubmitExpenses = true;
                    this.state.canApproveOrDeclineExpenses = true;
                    this.state.messageAboutState = "Expenses Are Due";
                }
                this.state.canEditExpenses = true;
                this.state.showExplanation = true;
                this.state.canCancelApplication = true;
                this.state.anticipatedIsApproved = true;
                return;
            case "ExpensesOverdue":
                if (currentUserIsSubmitter) {
                    this.state = submitOrEditMyOwnExpenses;
                    this.state.showUserDetails = true;
                    this.state.messageAboutState = "Expenses Are Overdue";
                } else if (this.currentUser.isAdmin) {
                    this.state.showUserDetails = true;
                    this.state.canNagSubmitterToSubmitExpenses = true;
                    this.state.canApproveOrDeclineExpenses = true;
                    this.state.messageAboutState = "Expenses Are Overdue";
                }
                this.state.canEditExpenses = true;
                this.state.showExplanation = true;
                this.state.canCancelApplication = true;
                this.state.showExplanation = true;
                this.state.anticipatedIsApproved = true;
                return;
        }
    }

    subNamesRequired() {
        return (
            (this.pdApp.halfDaysCalculation &&
                this.pdApp.halfDaysCalculation.actual &&
                this.pdApp.halfDaysCalculation.actual.amount) ||
            (this.pdApp.fullDaysCalculation &&
                this.pdApp.fullDaysCalculation.actual &&
                this.pdApp.fullDaysCalculation.actual.amount) ||
            (this.pdApp.gridDaysCalculation &&
                this.pdApp.gridDaysCalculation.actual &&
                this.pdApp.gridDaysCalculation.actual.amount)
        );
    }

    subNamesInvalid() {
        return this.subNamesRequired() && !this.pdApp.subNames;
    }

    principalConfirmationRequired() {
        return (
            (this.pdApp.halfDaysCalculation &&
                this.pdApp.halfDaysCalculation.anticipated &&
                this.pdApp.halfDaysCalculation.anticipated.amount) ||
            (this.pdApp.fullDaysCalculation &&
                this.pdApp.fullDaysCalculation.anticipated &&
                this.pdApp.fullDaysCalculation.anticipated.amount) ||
            (this.pdApp.gridDaysCalculation &&
                this.pdApp.gridDaysCalculation.anticipated &&
                this.pdApp.gridDaysCalculation.anticipated.amount)
        );
    }

    actualExceedsAnticipated() {
        let parsedAnticipated = parseFloat(this.pdApp.anticipatedTotal);
        if (isNaN(parsedAnticipated)) {
            parsedAnticipated = 0;
        }
        let parsedActual = parseFloat(this.pdApp.actualTotal);
        if (isNaN(parsedActual)) {
            parsedActual = 0;
        }
        return parsedActual > parsedAnticipated;
    }

    anticipatedExceedsCredits() {
        let parsed = parseFloat(this.pdApp.anticipatedTotal);
        if (isNaN(parsed)) {
            parsed = 0;
        }
        return (
            !this.state.expensesAreActual &&
            this.pdApp.applicant.unpendingBalance < parsed
        );
    }

    actualExceedsCredits() {
        let parsed = parseFloat(this.pdApp.actualTotal);
        if (isNaN(parsed)) {
            parsed = 0;
        }
        return (
            this.state.expensesAreActual &&
            this.pdApp.applicant.unpendingBalance < parsed
        );
    }

    onSubmit(principalConfirmTemplateRef: TemplateRef<any>) {
        if (this.accomodationUnitCostExceedsMax()) {
            console.log(
                "Aborted submission because accomodation unit cost exceeds max"
            );
            return;
        }

        // TODO: validate?
        if (
            this.anticipatedExceedsCredits() &&
            !confirm(
                "This application's anticipated expenses exceeds your credit balance. Anything over your credit balance will not be reimbursed. Please remember, new credits are allocated in September of each year and would be applied if appropriate."
            )
        ) {
            return;
        }

        if (this.principalConfirmationRequired()) {
            this.principalConfirmModal = this.modalService.show(
                principalConfirmTemplateRef
            );
        } else {
            this.performSubmit();
        }
    }

    cancel() {
        if (!confirm("Are you sure you want to cancel this application?")) {
            return;
        }
        // flag wait
        this.savingPDApp = true;

        this.pdAppService.cancel(this.pdApp, (success, error) => {
            // unflag wait
            this.savingPDApp = false;

            if (success) {
                this.toastrService.success("Application cancelled.");
                this.router.navigateByUrl(ROUTES.PD);
            } else if (!error.displayHasBeenHandled) {
                this.toastrService.error(error.errorMessage);
            }
        });
    }

    cancelPrincipalModal() {
        if (this.principalConfirmModal) {
            this.principalConfirmModal.hide();
        }
    }

    cancelNoExpensesModal() {
        if (this.noExpensesToSubmitModal) {
            this.noExpensesToSubmitModal.hide();
        }
    }

    confirmPrincipal() {
        if (this.principalConfirmModal) {
            this.principalConfirmModal.hide();
        }
        this.performSubmit();
    }

    confirmNoExpenses() {
        if (this.noExpensesToSubmitModal) {
            this.noExpensesToSubmitModal.hide();
        }
        this.performSubmitExpenses();
    }

    performSubmit() {
        // flag wait
        this.savingPDApp = true;

        this.pdAppService.savePending(this.pdApp, (success, result, error) => {
            // unflag wait
            this.savingPDApp = false;

            if (success) {
                this.toastrService.success("Application saved.");
                // trigger user refresh
                this.currentUserService.load(() => {});
                this.router.navigateByUrl(ROUTES.PD);
            } else if (!error.displayHasBeenHandled) {
                this.toastrService.error(error.errorMessage);
            }
        });
    }

    submitExpenses(noExpensesTemplateRef: TemplateRef<any>) {
        // TODO: validate?
        if (this.actualExceedsCredits()) {
            alert(
                "You can not submit actual expenses that exceed your credit balance. You may need to adjust your entered actual expenses accordingly in order to claim the maximum amount available."
            );
            this.toastrService.error(
                "You can not submit actual expenses that exceed your credit balance. You may need to adjust your entered actual expenses accordingly in order to claim the maximum amount available."
            );
            return;
        }

        if (this.actualExceedsMaxSubmittable()) {
            alert(
                "You can not submit actual expenses that exceed $" +
                    this.maxExpensesSubmitable.toString() +
                    ". You may need to adjust your entered actual expenses accordingly in order to claim the maximum amount available."
            );
            this.toastrService.error(
                "You can not submit actual expenses that exceed " +
                    this.maxExpensesSubmitable.toString() +
                    ". You may need to adjust your entered actual expenses accordingly in order to claim the maximum amount available."
            );
            return;
        }

        // Show a modal if the actual total is null or 0 and there were anticipated expenses
        if (this.isMissingApprovedExpenses()) {
            this.noExpensesToSubmitModal = this.modalService.show(
                noExpensesTemplateRef
            );
        } else {
            this.performSubmitExpenses();
        }
    }

    actualExceedsMaxSubmittable() {
        if (this.state.expensesAreActual) {
            let parsed = parseFloat(this.pdApp.actualTotal);
            if (isNaN(parsed)) {
                parsed = 0;
            }
            return this.maxExpensesSubmitable < parsed;
        } else {
            let parsed = parseFloat(this.pdApp.anticipatedTotal);
            if (isNaN(parsed)) {
                parsed = 0;
            }
            return this.maxExpensesSubmitable < parsed;
        }
    }

    isMissingApprovedExpenses() {
        this.noExpenseHeader = "";
        this.noExpenseModalText = [];
        var anticipated, actual;
        if (
            this.pdApp.status == "ExpensesDue" ||
            this.pdApp.status == "ExpensesOverdue"
        ) {
            if (
                (this.pdApp.actualTotal == null ||
                    parseFloat(this.pdApp.actualTotal) == 0) &&
                parseFloat(this.pdApp.anticipatedTotal) > 0
            ) {
                this.noExpenseHeader =
                    "Are you sure you do not want to submit any expenses?";
                this.noExpenseModalText.push(
                    "This application had total approved expenses of " +
                        this.pdApp.anticipatedTotal +
                        "."
                );
                return true;
            }
            // Accomodation
            anticipated = parseFloat(
                this.pdApp.accommodationCalculation.anticipated.total
            );
            actual = parseFloat(
                this.pdApp.accommodationCalculation.actual.total
            );
            this.checkExpense(anticipated, actual, "Accomodation");
            // Mileage
            anticipated = parseFloat(
                this.pdApp.carAllowanceCalculation.anticipated.total
            );
            actual = parseFloat(
                this.pdApp.carAllowanceCalculation.actual.total
            );
            this.checkExpense(anticipated, actual, "Mileage");
            // Car Rental
            anticipated = this.pdApp.carRentalCost.anticipated;
            actual = this.pdApp.carRentalCost.actual;
            this.checkExpense(anticipated, actual, "Car Rental");
            // Flights
            anticipated = this.pdApp.flightsCost.anticipated;
            actual = this.pdApp.flightsCost.actual;
            this.checkExpense(anticipated, actual, "Flights");
            // Substitute Full Days
            anticipated = parseFloat(
                this.pdApp.fullDaysCalculation.anticipated.total
            );
            actual = parseFloat(this.pdApp.fullDaysCalculation.actual.total);
            this.checkExpense(anticipated, actual, "Substitute Full Days");
            // Substitue Half Days
            anticipated = parseFloat(
                this.pdApp.halfDaysCalculation.anticipated.total
            );
            actual = parseFloat(this.pdApp.halfDaysCalculation.actual.total);
            this.checkExpense(anticipated, actual, "Substitute Half Days");
            // Subsititute Grid Days
            anticipated = parseFloat(
                this.pdApp.gridDaysCalculation.anticipated.total
            );
            actual = parseFloat(this.pdApp.gridDaysCalculation.actual.total);
            this.checkExpense(anticipated, actual, "Substitute Grid Days");
            // Parking Cost
            anticipated = this.pdApp.parkingCost.anticipated;
            actual = this.pdApp.parkingCost.actual;
            this.checkExpense(anticipated, actual, "Parking");
            // Other Cost
            anticipated = this.pdApp.otherCost.anticipated;
            actual = this.pdApp.otherCost.actual;
            this.checkExpense(anticipated, actual, "Other Cost");
            // Registration Fee
            anticipated = this.pdApp.registrationFee.anticipated;
            actual = this.pdApp.registrationFee.actual;
            this.checkExpense(anticipated, actual, "Registration Fee");
            // Subsistence
            anticipated = parseFloat(
                this.pdApp.subsistenceCalculation.anticipated.total
            );
            actual = parseFloat(this.pdApp.subsistenceCalculation.actual.total);
            this.checkExpense(anticipated, actual, "Subsistence");
        }

        if (this.noExpenseModalText.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    checkExpense(anticipated, actual, fieldName) {
        if (anticipated > 0 && actual == 0) {
            this.noExpenseHeader =
                this.noExpenseHeader == ""
                    ? "Missing expenses in some fields. Do you want to continue?"
                    : this.noExpenseHeader;
            this.noExpenseModalText.push(
                fieldName +
                    ": Requested expenses of $" +
                    anticipated.toString() +
                    " but submitted $0"
            );
            return true;
        }
        return false;
    }

    performSubmitExpenses() {
        // flag wait
        this.savingPDApp = true;

        this.pdAppService.submitExpenses(
            this.pdApp,
            (success, result, error) => {
                // unflag wait
                this.savingPDApp = false;

                if (success) {
                    this.toastrService.success("Expenses submitted.");
                    this.router.navigateByUrl(ROUTES.PD);
                } else if (!error.displayHasBeenHandled) {
                    this.toastrService.error(error.errorMessage);
                }
            }
        );
    }

    approve() {
        // flag wait
        this.savingPDApp = true;

        this.pdAppService.approve(this.pdApp, (success, result, error) => {
            // unflag wait
            this.savingPDApp = false;

            if (success) {
                this.toastrService.success("Application approved.");
                this.router.navigateByUrl(ROUTES.ADMIN);
            } else if (!error.displayHasBeenHandled) {
                this.toastrService.error(error.errorMessage);
            }
        });
    }

    decline() {
        if (
            !this.pdApp.applicationApprovalComments ||
            this.pdApp.applicationApprovalComments ==
                this.originalApplicationApprovalComments
        ) {
            let errorText =
                "Declining requires that you enter (or add to) details in the 'Application Approval Comments' text area.";
            alert(errorText);
            this.toastrService.error(errorText);
            return;
        }

        // flag wait
        this.savingPDApp = true;

        this.pdAppService.decline(this.pdApp, (success, result, error) => {
            // unflag wait
            this.savingPDApp = false;

            if (success) {
                this.toastrService.success("Application rejected.");
                this.router.navigateByUrl(ROUTES.ADMIN);
            } else if (!error.displayHasBeenHandled) {
                this.toastrService.error(error.errorMessage);
            }
        });
    }

    approveExpenses() {
        // flag wait
        this.savingPDApp = true;

        this.pdAppService.approveExpenses(
            this.pdApp,
            (success, result, error) => {
                // unflag wait
                this.savingPDApp = false;

                if (success) {
                    this.toastrService.success(
                        "Application expenses approved."
                    );
                    this.router.navigateByUrl(ROUTES.ADMIN);
                } else if (!error.displayHasBeenHandled) {
                    this.toastrService.error(error.errorMessage);
                }
            }
        );
    }

    declineExpenses() {
        if (
            !this.pdApp.expenseApprovalComments ||
            this.pdApp.expenseApprovalComments ==
                this.originalExpenseApprovalComments
        ) {
            let errorText =
                "Declining expenses requires that you enter (or add to) details in the 'Expense Approval Comments' text area.";
            alert(errorText);
            this.toastrService.error(errorText);
            return;
        }

        // flag wait
        this.savingPDApp = true;

        this.pdAppService.declineExpenses(
            this.pdApp,
            (success, result, error) => {
                // unflag wait
                this.savingPDApp = false;

                if (success) {
                    this.toastrService.success(
                        "Application expenses rejected."
                    );
                    this.router.navigateByUrl(ROUTES.ADMIN);
                } else if (!error.displayHasBeenHandled) {
                    this.toastrService.error(error.errorMessage);
                }
            }
        );
    }

    updateTotal() {
        if (this.state.expensesAreActual) {
            this.pdApp.actualTotal = this.roundN(
                (parseFloat(this.pdApp.halfDaysCalculation.actual.total) || 0) +
                    (parseFloat(this.pdApp.fullDaysCalculation.actual.total) ||
                        0) +
                    (parseFloat(this.pdApp.gridDaysCalculation.actual.total) ||
                        0) +
                    (this.pdApp.registrationFee.actual || 0) +
                    (parseFloat(
                        this.pdApp.carAllowanceCalculation.actual.total
                    ) || 0) +
                    (this.pdApp.flightsCost.actual || 0) +
                    (this.pdApp.parkingCost.actual || 0) +
                    (this.pdApp.carRentalCost.actual || 0) +
                    (this.pdApp.otherCost.actual || 0) +
                    (parseFloat(
                        this.pdApp.accommodationCalculation.actual.total
                    ) || 0) +
                    (parseFloat(
                        this.pdApp.subsistenceCalculation.actual.total
                    ) || 0),
                2
            );
            if (this.term) {
                this.term.totalSelected = this.pdApp.actualTotal;
            }
        } else {
            this.pdApp.anticipatedTotal = this.roundN(
                (parseFloat(this.pdApp.halfDaysCalculation.anticipated.total) ||
                    0) +
                    (parseFloat(
                        this.pdApp.fullDaysCalculation.anticipated.total
                    ) || 0) +
                    (parseFloat(
                        this.pdApp.gridDaysCalculation.anticipated.total
                    ) || 0) +
                    (this.pdApp.registrationFee.anticipated || 0) +
                    (parseFloat(
                        this.pdApp.carAllowanceCalculation.anticipated.total
                    ) || 0) +
                    (this.pdApp.flightsCost.anticipated || 0) +
                    (this.pdApp.parkingCost.anticipated || 0) +
                    (this.pdApp.carRentalCost.anticipated || 0) +
                    (this.pdApp.otherCost.anticipated || 0) +
                    (parseFloat(
                        this.pdApp.accommodationCalculation.anticipated.total
                    ) || 0) +
                    (parseFloat(
                        this.pdApp.subsistenceCalculation.anticipated.total
                    ) || 0),
                2
            );
            if (this.term) {
                this.term.totalSelected = this.pdApp.anticipatedTotal;
            }
        }
    }

    roundN(num, n) {
        // TODO: move to a common helper function spot
        return (Math.round(num * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n);
    }

    getLogDate(date) {
        const momentDate = moment(date);
        return momentDate.format("YYYY-MM-DD HH:mm");
    }

    getStartDate() {
        if (this.pdApp && this.pdApp.event) {
            const momentDate = moment(this.pdApp.event.startDate);
            return momentDate.format("MMMM DD YYYY");
        }
    }

    accomodationUnitCostExceedsMax() {
        if (!this.maxAccommodationPricePerNight) return false;
        if (!this.pdApp || !this.pdApp.accommodationCalculation) return false;

        if (this.state.expensesAreActual) {
            if (!this.pdApp.accommodationCalculation.actual) return false;
            return (
                this.pdApp.accommodationCalculation.actual.unitCost >
                this.maxAccommodationPricePerNight
            );
        } else {
            if (!this.pdApp.accommodationCalculation.anticipated) return false;
            return (
                this.pdApp.accommodationCalculation.anticipated.unitCost >
                this.maxAccommodationPricePerNight
            );
        }
    }

    accomodationTotalExceedsMax() {
        const maxDays = 25;
        if (!this.pdApp || !this.pdApp.accommodationCalculation) return false;

        if (this.state.expensesAreActual) {
            if (!this.pdApp.accommodationCalculation.actual) return false;
            return this.pdApp.accommodationCalculation.actual.amount > maxDays;
        } else {
            if (!this.pdApp.accommodationCalculation.anticipated) return false;
            return (
                this.pdApp.accommodationCalculation.anticipated.amount > maxDays
            );
        }
    }

    subDatesExceedTotalDates() {
        var subDays = 0;
        subDays +=
            typeof this.pdApp.fullDaysCalculation.anticipated.amount ==
            "undefined"
                ? 0
                : this.pdApp.fullDaysCalculation.anticipated.amount;
        subDays +=
            typeof this.pdApp.halfDaysCalculation.anticipated.amount ==
            "undefined"
                ? 0
                : this.pdApp.halfDaysCalculation.anticipated.amount;
        subDays +=
            typeof this.pdApp.gridDaysCalculation.anticipated.amount ==
            "undefined"
                ? 0
                : this.pdApp.gridDaysCalculation.anticipated.amount;

        if (this.pdApp.event.useNonContinuousDate) {
            return subDays > this.pdApp.event.nonContinuousDates.length;
        } else {
            var eventDays =
                moment(this.pdApp.event.endDate).diff(
                    moment(this.pdApp.event.startDate),
                    "days"
                ) + 1;
            return subDays > eventDays;
        }
    }

    // TODO: put this function in a common spot so I stop replicating it everywhere
    getDateRange(startDate, endDate) {
        const start = moment(startDate);
        const end = moment(endDate);
        const fullFormatString = "MMMM D, YYYY";

        if (start.get("year") !== end.get("year")) {
            return `${start.format(fullFormatString)} - ${end.format(
                fullFormatString
            )}`;
        }

        if (start.get("month") !== end.get("month")) {
            return `${start.format("MMMM D")} - ${end.format(
                fullFormatString
            )}`;
        }

        return `${start.format("MMMM D")} - ${end.format("D, YYYY")}`;
    }

    print() {
        window.print();
    }

    setNonContinuous() {
        this.pdApp.event.useNonContinuousDate =
            !this.pdApp.event.useNonContinuousDate;
    }

    getDates() {
        var i, tempDate;
        var retString = "";
        if (this.pdApp.event.nonContinuousDates != null) {
            const fullFormatString = "MMMM D, YYYY";
            this.sortNonContinuous();

            for (i = 0; i < this.pdApp.event.nonContinuousDates.length; i++) {
                tempDate = moment(this.pdApp.event.nonContinuousDates[i]);
                retString += `${tempDate.format(fullFormatString)}; `;
            }
            // Remove the final semi-colon from the end
            if (retString.endsWith("; ")) {
                return retString.substring(0, retString.length - 2);
            }
        }
        return retString;
    }

    sortNonContinuous() {
        this.pdApp.event.nonContinuousDates =
            this.pdApp.event.nonContinuousDates.sort(function (a, b) {
                return moment(a).valueOf() - moment(b).valueOf();
            });
    }

    nonContinuousExists() {
        return !(
            this.pdApp.event.nonContinuousDates == null &&
            this.pdApp.event.nonContinuousDates.length > 0
        );
    }

    subsistencePastMax() {
        if (this.state.canSubmitExpenses) {
            return (
                this.pdApp.subsistenceCalculation.actual.unitCost >
                this.maxSubsistencePerDay
            );
        }
        return (
            this.pdApp.subsistenceCalculation.anticipated.unitCost >
            this.maxSubsistencePerDay
        );
    }

    termExists() {
        return Object.keys(this.term).length > 0 && this.term.id != null;
    }
}
