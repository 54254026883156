import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, ErrorHandler } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { ModalModule } from "ngx-bootstrap/modal";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ToastrModule } from "ngx-toastr";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { InlineSVGModule } from "ng-inline-svg-2";
import { NgxPaginationModule } from "ngx-pagination";

import { AppRoutingModule } from "./app.route";

import { AppComponent } from "./app.component";

import { COMPONENTS } from "./components";
import { PROVIDERS } from "./providers";
import { MonitoringErrorHandler } from "./services/error.handler";
import { OrderByPipe } from "./order-by.pipe";
import { NewLine2BrPipe } from "./nl2br.pipe";

@NgModule({
    declarations: [AppComponent, COMPONENTS, OrderByPipe, NewLine2BrPipe],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        InlineSVGModule,
        BrowserAnimationsModule,
        MatProgressBarModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ProgressbarModule.forRoot(),
        ToastrModule.forRoot({
            closeButton: true,
            timeOut: 10000
        }),
        ModalModule.forRoot(),
        MatSlideToggleModule,
        AppRoutingModule,
        NgxPaginationModule
    ],
    providers: [
        PROVIDERS,
        { provide: ErrorHandler, useClass: MonitoringErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
