<div *ngIf="actual" class="anticipated-calc" [ngClass]="{'exceeded': isExceeded() }">
    <span class="anticipated-text">Anticipated:</span>
    <span class="amount-text">{{amountPrefix}}{{calcPair.anticipated.amount}}{{amountSuffix}}</span>
    x
    <span class="unit-cost-text">{{ unitPrefix }}{{calcPair.anticipated.unitCost}}{{ unitSuffix }}</span>
    =
    <span class="total-text">{{ unitPrefix }}{{calcPair.anticipated.total}}{{ unitSuffix }}</span>
</div>
<eil-unitcalc [(ngModel)]="boundCalc"
              [ngModelOptions]="{standalone: true}"
              [amountHeader]="amountHeader"
              [amountPrefix]="amountPrefix"
              [amountSuffix]="amountSuffix"
              [unitCostHeader]="unitCostHeader"
              [unitPrefix]="unitPrefix"
              [unitSuffix]="unitSuffix"
              [totalHeader]="totalHeader"
              [unitCostEditable]="unitCostEditable"
              (change)="onChangeCallback(calcPair)"
              [disabled]="disabled"
              ></eil-unitcalc>
