import { Component,
         OnInit,
         ChangeDetectorRef,
         Input          } from '@angular/core';

@Component({
        selector:       'eil-color-bar',
        templateUrl:    './color-bar.component.html',
        styleUrls:      ['./color-bar.component.scss']
    }
)
export class ColorBarComponent implements OnInit {

    @Input() public available?: number;
    @Input() public availableBarText?: string;
    @Input() public availableLegendText = "credits available";
    @Input() public isCreditsMode?: boolean = false;

    @Input() public spent?: number;
    @Input() public spentBarText?: string;
    @Input() public spentLegendText = "actual expenses approved";

    @Input() public pending?: number;
    @Input() public pendingBarText?: string;
    @Input() public pendingLegendText = "approved applications pending expenses";

    @Input() public requested?: number;
    @Input() public requestedBarText?: string;
    @Input() public requestedLegendText = "requests pending approval";

    @Input() public selected?: number;
    @Input() public selectedBarText?: string;
    @Input() public selectedLegendText = "in this item";
    @Input() public selectedIsActual?: boolean;
    @Input() public selectedIsApproved?: boolean;

    @Input() public excessLegendText = "more than available";

    @Input() public ceiling?: number;
    @Input() public showCeilingLabel?: boolean;
    @Input() public ceilingLabelText = "maximum";

    @Input() public showLegend?: boolean;
    @Input() public showRemaining?: boolean;
    @Input() public remainingText = "remaining";

    @Input() public overdrawnText = "allocations exceed funding by";
    @Input() public potentiallyOverdrawnText = "if all approved, requests would exceed funding by";

    @Input() public showCreditAllocationExplanation?: boolean;
    @Input() public creditAllocationExplanationText = "Please remember, new credits are allocated in September of each year and would be applied if appropriate.";

    total?: number;
    remaining?: number;
    potentiallyRemaining?: number;
    stack = [];
    legend = [];
    overdrawn = false;
    potentiallyOverdrawn = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {

    }

    ngOnInit() {

    }

    ngOnChanges() {
        this.stack = [];
        this.legend = [];
        let runningTotal = 0;
        let spentPlusPending = 0;

        if (this.selected && typeof(this.selected) === "string") {
            let parsed = parseFloat(this.selected);
            if (!isNaN(parsed)) {
                this.selected = parsed;
            }
        }

        if (this.isCreditsMode) {
            this.total = this.ceiling;

            if (this.selected && !this.selectedIsApproved && this.selected > this.available) {
                // overdrawn!
                this.overdrawn = true;
                // available funds shown in the pink
                // excess shown in red
                if (this.available) {
                    this.stack.push({
                        value: this.available,
                        type: 'available-exceeded',
                        label: this.availableBarText
                    });
                    if (this.showLegend) {
                        this.legend.push({
                            type: 'available-exceeded',
                            amount: this.available,
                            text: `${this.availableLegendText}${this.selected ? " to this application" : ""}`
                        });
                    }
                }
                if (this.pending) {
                    this.stack.push({
                        value: this.pending,
                        type: 'pending',
                        label: this.pendingBarText
                    });
                    if (this.showLegend) {
                        this.legend.push({
                            type: 'pending',
                            amount: this.pending,
                            text: this.pendingLegendText
                        })
                    }
                }

                let excess = this.selected - this.available;

                this.stack.push({
                    value: excess,
                    type: 'excess'
                });

                if (this.showLegend) {
                    this.legend.push({
                        type: 'selected',
                        amount: this.selected,
                        text: this.selectedLegendText
                    });
                    this.legend.push({
                        type: 'excess',
                        amount: excess,
                        text: this.excessLegendText
                    });
                }
            } else {
                this.overdrawn = false;
                if (this.available) {
                    let localAvailable = this.available;
                    if (this.selected && !this.selectedIsApproved) {
                        localAvailable = this.available - this.selected;
                    }
                    //console.log(`localAvailable: ${localAvailable}`);
                    this.stack.push({
                        value: localAvailable,
                        type: 'available',
                        label: this.availableBarText
                    });
                    if (this.showLegend) {
                        this.legend.push({
                            type: 'available',
                            amount: this.available,
                            text: this.availableLegendText
                        });
                    }
                }

                if (this.pending) {
                    this.stack.push({
                        value: this.pending,
                        type: 'pending',
                        label: this.pendingBarText
                    });
                    if (this.showLegend) {
                        this.legend.push({
                            type: 'pending',
                            amount: this.pending,
                            text: this.pendingLegendText
                        });
                    }
                }

                if (this.selected) {
                    this.stack.push({
                        value: this.selected,
                        type: 'selected',
                        label: this.selectedBarText
                    });

                    if (this.showLegend) {
                        this.legend.push({
                            type: 'selected',
                            amount: this.selected,
                            text: this.selectedLegendText
                        });
                    }
                }

            }
        } else {
            if (this.spent) {
                this.stack.push({
                    value: this.spent,
                    type: 'spent',
                    label: this.spentBarText
                });
                runningTotal += this.spent;
                spentPlusPending += this.spent;
                if (this.showLegend) {
                    this.legend.push({
                        type: 'spent',
                        amount: this.spent,
                        text: this.spentLegendText
                    });
                }
            }

            // if there's a selected item and it's Actual, then its total goes between spent and pending
            if (this.selected && this.selectedIsActual) {
                this.stack.push({
                    value: this.selected,
                    type: 'selected',
                    label: this.selectedBarText
                });
                runningTotal += this.selected;
                spentPlusPending += this.selected;
                if (this.showLegend) {
                    this.legend.push({
                        type: 'selected',
                        amount: this.selected,
                        text: this.selectedLegendText
                    });
                }
            }

            if (this.pending) {
                this.stack.push({
                    value: this.pending,
                    type: 'pending',
                    label: this.pendingBarText
                });
                runningTotal += this.pending;
                spentPlusPending += this.pending;
                if (this.showLegend) {
                    this.legend.push({
                        type: 'pending',
                        amount: this.pending,
                        text: this.pendingLegendText
                    });
                }
            }

            // if there's a selected item and it's not actual, then its total goes between pending and requested
            if (this.selected && !this.selectedIsActual) {

                this.stack.push({
                    value: this.selected,
                    type: 'selected',
                    label: this.selectedBarText
                });
                runningTotal += this.selected;
                if (this.selectedIsApproved) {
                    spentPlusPending += this.selected;
                }
                if (this.showLegend) {
                    this.legend.push({
                        type: 'selected',
                        amount: this.selected,
                        text: this.selectedLegendText
                    });
                }
            }

            if (this.requested) {
                this.stack.push({
                    value: this.requested,
                    type: 'requested',
                    label: this.requestedBarText
                });
                runningTotal += this.requested;
                if (this.showLegend) {
                    this.legend.push({
                        type: 'requested',
                        amount: this.requested,
                        text: this.requestedLegendText
                    });
                }
            }

            if (this.ceiling) {
                this.total = this.ceiling;
            } else {
                this.total = runningTotal;
            }
            this.remaining = this.total - spentPlusPending;
            this.potentiallyRemaining = this.total - runningTotal;
            this.overdrawn = this.remaining < 0;
            this.potentiallyOverdrawn = this.potentiallyRemaining < 0 && !this.overdrawn;
        }

        // there has to be a better way than this, I'm just not picturing it correctly at the moment
        this.changeDetectorRef.detectChanges();
    }

    /*getStack() {

        let stack = [];
        this.legend = [];
        let runningTotal = 0;

        if (this.spent) {
            stack.push({
                value: this.spent,
                type: 'success',
                label: this.spentBarText
            });
            runningTotal += this.spent;
            if (this.showLegend) {
                this.legend.push({
                    type: 'spent',
                    amount: this.spent,
                    text: this.spentLegendText
                });
            }
        }

        if (this.pending) {
            stack.push({
                value: this.pending,
                type: 'warning',
                label: this.pendingBarText
            });
            runningTotal += this.pending;
            if (this.showLegend) {
                this.legend.push({
                    type: 'pending',
                    amount: this.pending,
                    text: this.pendingLegendText
                });
            }
        }

        if (this.requested) {
            stack.push({
                value: this.requested,
                type: 'info',
                label: this.requestedBarText
            });
            runningTotal += this.requested;
            if (this.showLegend) {
                this.legend.push({
                    type: 'requested',
                    amount: this.requested,
                    text: this.requestedLegendText
                });
            }
        }

        if (this.ceiling) {
            this.total = this.ceiling;
        } else {
            this.total = runningTotal;
        }
        this.remaining = this.total - runningTotal;

        return stack;
    }*/

}
