import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "memberSearchFilter",
    pure: false
})
export class MemberSearchFilterPipe implements PipeTransform {
    transform(items: any[], args: string): any {

        var test = items.filter(item => item.teachingCertNo != null && item.teachingCertNo.toLowerCase().indexOf(args.toLowerCase()) !== -1);

        return items.filter(item => ((item.teachingCertNo != null && item.teachingCertNo.toLowerCase().indexOf(args.toLowerCase()) !== -1) ||
            (item.firstName != null && item.firstName.toLowerCase().indexOf(args.toLowerCase()) !== -1) ||
            (item.lastName != null && item.lastName.toLowerCase().indexOf(args.toLowerCase()) !== -1) ||
             (item.preferredEmail ? item.preferredEmail.toLowerCase().indexOf(args.toLowerCase()) !== -1 : item.email.toLowerCase().indexOf(args.toLowerCase()) !== -1)));
    }
}
