import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ParsedError } from "./parsed-error";
import { MonitoringService } from "./monitoring.service";

// import { Response } from '@angular/http/src/static_response';

export abstract class ServiceBase {

    constructor(protected http: HttpClient, protected monitoringService: MonitoringService, protected toastrService: ToastrService) {  }

    handleError(error: any, requestData?: any): ParsedError {

        // log to console
        console.error(error);

        let returnValue: ParsedError = {
            originalError: error
        };

        let serializedError: string = "";
        try {
            serializedError = JSON.stringify(error, null, 1);
        } catch (e) {
            serializedError = e.errorMessage;
        }

        let serializedRequestData: string = "";
        try {
            serializedRequestData = JSON.stringify(requestData, null, 1);
        } catch (e) {
            serializedRequestData = e.errorMessage;
        }
        //if (serializedRequestData.length > 7000) serializedRequestData = `Sorry, was too long (${serializedRequestData.length})`;

        if (error instanceof HttpErrorResponse) {
            let httpError = error as HttpErrorResponse;
            let httpErrorError = httpError.error ? httpError.error : httpError.message;
            if (httpError.statusText === "Unknown Error") {
                returnValue.errorMessage = "An unknown error occurred.  You may need to sign out and in again.";
                this.toastrService.warning(returnValue.errorMessage);
                returnValue.displayHasBeenHandled = true;
            } else {
                returnValue.errorMessage = httpErrorError;
            }
            if (this.monitoringService) {
                this.monitoringService.logError(new Error(httpErrorError),
                    {
                        "error": httpError.error,
                        "message": httpError.message,
                        "name": httpError.name,
                        "status": httpError.status ? httpError.status.toString() : "",
                        "statusText": httpError.statusText,
                        "serializedRequestData": serializedRequestData,
                        "serializedErrorJson": serializedError
                    });
            }
        } else if (error instanceof Error) {
            let genericError = error as Error;
            returnValue.errorMessage = genericError.message;
            if (this.monitoringService) {
                this.monitoringService.logError(genericError,
                    {
                        "error": genericError.message,
                        "message": genericError.message,
                        "name": genericError.name,
                        "stack": genericError.stack,
                        "serializedRequestData": serializedRequestData,
                        "serializedErrorJson": serializedError
                    });
            }
            return returnValue;
        } else {
            returnValue.errorMessage = (error.message)
                ? error.message
                : (error._body)
                ? error._body
                : (error.status)
                ? `${error.status} - ${error.statusText}${error.errorMessage ? ' (' + error.errorMessage + ') ' : ''}`
                        : 'Server error';
            if (this.monitoringService) {
                this.monitoringService.logError(new Error(returnValue.errorMessage),
                    {
                        "error": error.error,
                        "message": error.message,
                        "name": error.name,
                        "_name": error._body,
                        "status": error.status ? error.status.toString() : "",
                        "statusText": error.statusText,
                        "stack": error.stack,
                        "serializedRequestData": serializedRequestData,
                        "serializedErrorJson": serializedError
                    });
            }
        }

        return returnValue;
    }
}
