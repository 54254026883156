import { Component, 
         OnInit,
         Input,
         Output, 
         EventEmitter }        from '@angular/core';

@Component({
        selector:       'eil-quick-toggle',
        templateUrl:    './quick-toggle.component.html',
        styleUrls:      ['./quick-toggle.component.scss'] 
    }
)
export class QuickToggleComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    @Input() currentState: boolean;
    @Output() toggle = new EventEmitter<boolean>();

    toggleClick(valueToSet: boolean) {
        this.toggle.emit(valueToSet);
    }

    constructor() { 

    }

    ngOnInit() {

    }

}
