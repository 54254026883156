<div *ngIf="actual" class="anticipated-single-amount" [ngClass]="{'exceeded': amountPair.actual > amountPair.anticipated }">
    <span class="anticipated-text">Anticipated: </span>
    <span class="total-text">{{amountPrefix}}{{amountPair.anticipated}}{{amountSuffix}}</span>
</div>
<div class="single-amount-div">
    {{ amountPrefix }}
    <input class="single-amount-input"
           type="number"
           [(ngModel)]="boundAmount"
           [ngModelOptions]="{standalone: true}"
           [placeholder]="placeholder"
           [step]="step"
           [min]="min"
           [disabled]="disabled"
           (change)="onChangeCallback(amountPair)"
           (keyup)="onChangeCallback(amountPair)" />
    {{ amountSuffix }}
</div>

