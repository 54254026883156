import {IUserDetail} from "./user-detail.model";

export class MemberStatus {
    public static Incomplete: string = 'Incomplete';
    public static Pending: string = 'Pending';
    public static Deactivated: string = 'Deactivated';
    public static Approved: string = 'Approved';
    public static Auditor: string = 'Auditor';

    static for(user: IUserDetail) {

        if (!user.isApproved) {
            if (!user.isRegistered) {
                return MemberStatus.Incomplete;
            } else {
                if (!user.hasBeenApproved) {
                    return MemberStatus.Pending;
                } else {
                    return MemberStatus.Deactivated;
                }
            }
        } else {
            if (user.isAuditor) {
                return MemberStatus.Auditor;
            }
            return MemberStatus.Approved;
        }

    }
}
