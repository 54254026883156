import { Component, OnInit } from "@angular/core";
import { PDAppService } from "../../../services/pd-app.service";
import { ROUTES } from "../../../routes";
import moment from "moment";
import { OrderByPipe } from "../../../order-by.pipe";
import { SearchFilterPipe } from "./admin-applist-search.component";
import { ToastrService } from "ngx-toastr";
import { Pipe, PipeTransform } from "@angular/core";

@Component({
    selector: "eil-admin-applist",
    templateUrl: "./admin-applist.component.html",
    styleUrls: ["./admin-applist.component.scss"]
})
export class AdminApplistComponent implements OnInit {
    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    filterStatus: string = "";
    linkToEdit: string;
    loading = false;
    order: string = "created";
    pdApps = [];
    reverse: boolean = false;
    searchText: string = "";
    tablePage: number;
    toastrService: ToastrService;

    constructor(
        private pdAppService: PDAppService,
        orderByPipe: OrderByPipe
    ) {
        this.linkToEdit = `/${ROUTES.PD}/${ROUTES.PD_EDITAPP}/`;
        this.loading = true;

        var filterDate = moment().subtract(6, 'months');
        //filterDate.setMonth(filterDate.getMonth() - 6);

        var filter = {
            ApplicantEmail: null,
            ExcludeStatus: 'ExpensesApproved',
            CreatedAt: filterDate
        }
        this.pdAppService.getMany(filter, (success, result) => {
            this.pdApps = result;
            // TODO: process result
            this.addDisplayTotal();
            this.checkAndUpdateStatus();
            // unflag wait
            this.loading = false;
        });
        this.tablePage = 1; //Start at the first page
        orderByPipe.transform(this.pdApps, "created");
        this.setOrder("created");
    }

    ngOnInit() { }

    getAgo(dateString) {
        return moment(dateString).fromNow();
    }

    addDisplayTotal() {
        var i;
        for (i = 0; i < this.pdApps.length; i++) {
            this.pdApps[i]["displayTotal"] = this.pdApps[i].actualTotal
                ? parseFloat(this.pdApps[i].actualTotal)
                : parseFloat(this.pdApps[i].anticipatedTotal);
            if (isNaN(this.pdApps[i].displayTotal)) {
                this.pdApps[i].displayTotal = "";
            }
        }
    }

    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }
        this.order = value;
    }

    checkAndUpdateStatus() {
        var i;
        var oneDay = 24 * 60 * 60 * 1000;
        for (i = 0; i < this.pdApps.length; i++) {
            if (this.pdApps[i] && this.pdApps[i].status) {
                if (this.pdApps[i].status == "Approved" || this.pdApps[i].status == "ExpensesDue") {
                    var endDate = new Date(this.pdApps[i].event.endDate);
                    var now = new Date();
                    if (now.valueOf() > endDate.valueOf()) {
                        if ((now.getTime() - endDate.getTime()) / oneDay > 21) {
                            this.pdAppService.expensesOverdue(
                                this.pdApps[i],
                                (success, result, error) => {
                                    if (success) {
                                        this.pdApps[i].status = "ExpensesOverdue";
                                    } else if (!error.displayHasBeenHandled) {
                                        this.toastrService.error(
                                            error.errorMessage
                                        );
                                    }
                                }
                            );
                        } else {
                            if (this.pdApps[i].status != "ExpensesDue") {
                                this.pdAppService.expensesDue(
                                    this.pdApps[i],
                                    (success, result, error) => {
                                        if (success) {
                                            this.pdApps[i].status = "ExpensesDue";
                                        } else if (!error.displayHasBeenHandled) {
                                            this.toastrService.error(
                                                error.errorMessage
                                            );
                                        }
                                    }
                                );
                            }
                        }
                    }
                }
            }
        }
    }

    loadAllApps() {
        this.loading = true
        this.pdAppService.getMany({}, (success, result) => {
            this.pdApps = result;
            // TODO: process result
            this.addDisplayTotal();
            // unflag wait
            this.loading = false;
        });
    }
}
