import { Injectable } from '@angular/core';
import { ServiceBase } from './base.service';
import { IUserDetail } from "../models/user-detail.model";
import { ParsedError } from "./parsed-error";
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { MonitoringService } from "./monitoring.service";

@Injectable()
export class MembersService extends ServiceBase {

    constructor(protected http: HttpClient,
        protected monitoringService: MonitoringService,
        protected toastrService: ToastrService) {
        super(http, monitoringService, toastrService);
    }

    private apiUrl = 'user/';

    getOne(id, callback: (isSuccess: boolean, result?: IUserDetail, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'GetOne';
        this.http.get<IUserDetail>(callUrl,
                {
                    params: {
                        "id": id
                    }
                })
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, {}, this.handleError(error, id));
                });
    }

    getAll(callback: (isSuccess: boolean, result?: IUserDetail[], error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'GetAll';
        this.http.get<Array<IUserDetail>>(callUrl)
            .subscribe(
            result => {
                callback(true, result, null);
            },
            error => {
                // TODO
                callback(false, null, this.handleError(error));
            });
    }

    setMemberSetting(email: string, settingName: string, valueToSet: boolean, callback: (success: boolean, result?: IUserDetail, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'Set' + settingName;
        this.http.post<IUserDetail>(callUrl, {
                email: email,
                valueToSet: valueToSet
            })
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, {}, this.handleError(error, {email: email, settingName: settingName, valueToSet: valueToSet }));
                });
    }

    delete(email: string, callback: (success: boolean, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'Delete';
        this.http.post<IUserDetail>(callUrl, email)
            .subscribe(
                result => {
                    callback(true, null);
                },
                error => {
                    // TODO
                    callback(false, this.handleError(error, email));
                });
    }

    addCredits(email: string, amount: number, callback: (success: any, result: IUserDetail, error: ParsedError) => void) {
        const callUrl = this.apiUrl + 'AddCredits';
        this.http.post<IUserDetail>(callUrl, {
                email: email,
                creditToAdd: amount
            })
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, {}, this.handleError(error, {email: email, amount: amount}));
                });
    }

    unsubscribe(email: string, callback: (success: boolean, result: IUserDetail, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'Unsubscribe';
        this.http.post<IUserDetail>(callUrl, email)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, {}, this.handleError(error, email));
                });
    }

    resubscribe(email: string, callback: (success: boolean, result: IUserDetail, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'Resubscribe';
        this.http.post<IUserDetail>(callUrl, email)
            .subscribe(
                result => {
                    callback(true, result, null);
                },
                error => {
                    // TODO
                    callback(false, {}, this.handleError(error, email));
                });
    }
}

