import { Component, 
         OnInit }       from '@angular/core';
import { ROUTES }       from "../../routes";

@Component({
        selector:       'eil-pd-applications',
        templateUrl:    './pd-applications.component.html',
        styleUrls:      ['./pd-applications.component.scss']
    }
)
export class PDApplicationsComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    linkToNew:                  string;

    constructor() {
        this.linkToNew = `/${ROUTES.PD}/${ROUTES.PD_NEWAPP}`;
    }

    ngOnInit() {

    }

}
