import { Component,
         OnInit }       from '@angular/core';
import { Location } from '@angular/common';
@Component({
        selector:             'eil-admin-applications',
        templateUrl:          './admin-applications.component.html',
        styleUrls:            ['./admin-applications.component.scss']
    }
)
export class AdminApplicationsComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    linkToExcel: string;

    constructor(location: Location) {
        this.linkToExcel = location.prepareExternalUrl("PDApp/GetAllPDAsExcel");
    }

    ngOnInit() {
    }

}
