<div class='navbar top-right'>
    <div class="navbar-wrapper">
        <div class="row">
            <div class="col-lg-5
           col-md-6
           col-12 order-3 order-md-1">
                <div class='navbar-collapse collapse show'>
                    <ul class='nav navbar-nav' style="flex-direction: row;">
                        <li>
                            <a href="/blog" routerLinkActive="link-active">
                                Public <span class="optional-text">Blog</span>
                            </a>
                        </li>
                        <li *ngIf="currentUser && currentUser.isAuthenticated && !currentUser.isAuditor">
                            <a href="/app/privateBlog" routerLinkActive="link-active">
                                Members <span class="optional-text">Info</span>
                            </a>
                        </li>
                        <li *ngIf="currentUser && currentUser.isAuthenticated && currentUser.isMember && !currentUser.isAuditor">
                            <a routerLink="/pd" routerLinkActive="link-active">
                                My PD
                            </a>
                        </li>
                        <li *ngIf="currentUser && currentUser.isAuthenticated && (currentUser.isAdmin || currentUser.isAuditor)">
                            <a routerLink="/admin" routerLinkActive="link-active">
                                Admin
                            </a>
                        </li>

                    </ul>

                </div>
            </div>
            <div class="col-lg-2
           col-md-3
            col-6 order-1 order-md-2
           logo-col">
                <a class='logo-link' [routerLink]="['/']">
                    <img class="logo-img" src="easypd-logo.svg"/>
                </a>
            </div>
            <div class="col-lg-5
           col-md-3
                col-6 order-2 order-md-3
                 user-col">
                <eil-user-menu></eil-user-menu>
            </div>
        </div>
    </div>

</div>
