import { Injectable }      from '@angular/core';
import { Observable ,  BehaviorSubject }      from 'rxjs';
import { ServiceBase }     from './base.service';
import { IConfigValues }   from '../models/config-values.model';
import { ParsedError } from "./parsed-error";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class ConfigService extends ServiceBase {

    constructor(protected http: HttpClient,
        protected toastrService: ToastrService) {
         super(http, null, toastrService);
    }

    private source = new BehaviorSubject<IConfigValues>({
        isLoaded: false
    });

    instance = this.source.asObservable();

    private apiUrl = 'config/';

    load(callback: (isSuccess: boolean, error?: ParsedError) => void) {
        if (!this.source.value || !this.source.value.isLoaded) {
            const callUrl = this.apiUrl + "Get";
            this.http.get<IConfigValues>(callUrl)
                .subscribe(
                    item => {
                        item.isLoaded = true;
                        this.source.next(item);
                        callback(true, null);
                    },
                    error => {
                        callback(false, this.handleError(error));
                    });
        } else {
            callback(true, null);
        }
    }

    save(config: IConfigValues, callback: (isSuccess: boolean, error?: ParsedError) => void) {
        const callUrl = this.apiUrl + 'save';
        this.http.post(callUrl, config)
            .subscribe(
            result => {
                    config.isLoaded = true;
                    this.source.next(config);
                    callback(true, null);
                },
                error => {
                    callback(false, this.handleError(error, config));
                });
    }
}


