import { Component,
         OnInit}                from '@angular/core';
import { Router,
         ActivatedRoute,
         Route}       from '@angular/router';

@Component({
        selector:       'eil-side-nav',
        templateUrl:    './side-nav.component.html',
        styleUrls:      ['./side-nav.component.scss']
    }
)
export class SideNavComponent implements OnInit {

    public parentChildRoutes: Route[];
    private parentRoute: string;

    constructor(route: ActivatedRoute, protected router: Router) {
        route.params.subscribe(params => {
            if (route.parent && route.parent.routeConfig && route.parent.routeConfig.children) {
                const childRouteArray = route.parent.routeConfig.children.filter((e) => e.data && e.data.showInSideNavAs);
                this.parentChildRoutes = childRouteArray;
                this.parentRoute = route.parent.routeConfig.path;
            }
        });
    }

    ngOnInit() {

    }

}
