import { Component, 
         OnInit }               from '@angular/core';
import { CurrentUserService }   from "../services/current-user.service";
import { IUserDetail }          from "../models/user-detail.model";

@Component({
        selector:       'eil-top-nav',
        templateUrl:    './top-nav.component.html',
        styleUrls:      ['./top-nav.component.scss']
    }
)
export class TopNavComponent implements OnInit {

    currentUser: IUserDetail;

    isExpanded = false;

    constructor(private currentUserService: CurrentUserService) {
        
    }

    ngOnInit() {
        this.currentUserService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }

    collapse() {
        this.isExpanded = false;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }
}
