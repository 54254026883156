import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ConfigService } from "./config.service";


@Injectable()
export class MonitoringService {

    private appInsights: ApplicationInsights;
    private userId: string | null = null;

    constructor(
        private router: Router,
        configService: ConfigService
    ) {
        configService.load((isSuccess, error) => {
            if (isSuccess) {
                configService.instance.subscribe(config => {
                    this.appInsights = new ApplicationInsights({ config: {
                        instrumentationKey: config.instrumentationKey
                    }});
                    this.appInsights.loadAppInsights()
                    if (this.userId) {
                        this.appInsights.setAuthenticatedUserContext(this.userId);
                    }
                });

            } else {
                // TODO: error?
            }
        });

    }

    setAuthenticatedUserId(userId: string | null): void {
        if (userId) {
            this.userId = userId;
            this.appInsights?.setAuthenticatedUserContext(userId);
        }
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {

        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }

    private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
        if (!properties) {
            properties = {};
        }

        //add your custom properties such as app version

        return properties;
    }

    public logPageView(
        name: string,
        url?: string,
        properties?: { [key: string]: string },
        measurements?: { [key: string]: number },
        duration?: number) {

        this.appInsights?.trackPageView({
            name,
            uri: url,
            measurements,
            properties: {
                properties, duration
            }
        });
    }

    public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        this.appInsights?.trackEvent({
            name,
            properties,
            measurements
        });
    }

    public logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        this.appInsights?.trackException({
            error,
            properties,
            measurements
        });
    }
}
