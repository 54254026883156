import { Component,
         OnInit }               from '@angular/core';
import { CurrentUserService }   from '../services/current-user.service';
import { IUserDetail }          from '../models/user-detail.model';
import { Router, ActivatedRoute }               from '@angular/router';
import { ROUTES }               from '../routes';
import { ToastrService } from 'ngx-toastr';

@Component({
        selector:       'eil-login',
        templateUrl:    './login.component.html',
        styleUrls:      ['./login.component.scss']
    }
)
export class LoginComponent implements OnInit {

    currentUser:    IUserDetail;
    errorMessage:   any;
    loading:        boolean;

    constructor(private currentUserService: CurrentUserService, protected router: Router, private route: ActivatedRoute, private toastrService: ToastrService) {
        this.loading = true;
        this.currentUserService.load(
            (wasSuccess, error) => {
                this.loading = false;
                if (!wasSuccess && !error.displayHasBeenHandled) {
                    toastrService.error("An error occurred loading your membership.  Please try again later.");
                }
            });
    }

    ngOnInit() {
        this.currentUserService.currentUser.subscribe(user => {
            const newlyAuthenticated = user.isAuthenticated && (!this.currentUser || !this.currentUser.isAuthenticated);

            this.currentUser = user;

            if (newlyAuthenticated) {
                // user has just logged in; need to redirect to default page
                let returnUrl: string | undefined;
                if (this.route && this.route.snapshot && this.route.snapshot.queryParams) {
                    returnUrl = this.route.snapshot.queryParams.returnUrl;
                }

                if (returnUrl) {
                    this.router.navigateByUrl(returnUrl);
                } else if (user.isAdmin || user.isAuditor) {
                    this.router.navigateByUrl(ROUTES.ADMIN);
                } else if (user.isMember) {
                    this.router.navigateByUrl(ROUTES.PD);
                }
            }
        });
    }

}
