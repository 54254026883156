<div [ngClass]="{'potentially-overdrawn': potentiallyOverdrawn, 'overdrawn': overdrawn}">
    <progressbar class="progress" [value]="stack" [max]="total"></progressbar>
    <div *ngIf="showRemaining || showCeilingLabel">
        <div class="row">
            <div *ngIf="showRemaining" class="col remaining-div">

                <div class="remaining-overdrawn" *ngIf="overdrawn">
                    {{overdrawnText}} {{remaining | currency:'CAD':'symbol-narrow':'1.2-2'}}
                </div>
                <div class="remaining-potentially-overdrawn" *ngIf="potentiallyOverdrawn && !overdrawn">
                    {{potentiallyOverdrawnText}} {{potentiallyRemaining | currency:'CAD':'symbol-narrow':'1.2-2'}}
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="showLegend" class="legend-div">
        <ul class="legend-ul">
            <li *ngFor="let legendItem of legend" class="legend-li" [ngClass]="legendItem.type">
                {{ legendItem.text }}
            </li>
        </ul>
        <span class="credits-explanation" *ngIf="showCreditAllocationExplanation">
            {{creditAllocationExplanationText}}
        </span>
    </div>
</div>
