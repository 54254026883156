import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "nl2br" })
export class NewLine2BrPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: string, santize: boolean = true): string {
        if (typeof value !== 'string') {
            return value;
        }

        const parsed = value.replace(/(?:\r\n|\r|\n)/g, "<br />");

        if (santize) {
            return this.sanitizer.sanitize(SecurityContext.HTML, parsed);
        } else {
            return this.sanitizer.bypassSecurityTrustHtml(parsed) as string;
        }
    }
}
