import { Component, 
    OnInit }       from '@angular/core';

@Component({
        selector:       'eil-awaiting-approval',
        templateUrl:    './awaiting-approval.component.html',
        styleUrls:      ['./awaiting-approval.component.scss']
    }
)
export class AwaitingApprovalComponent implements OnInit {

    // @Input() public inputExample:    string;

    // public publicExample:            string;
    // public anotherExample:           any;

    // private privateExample:          boolean;

    ngOnInit() {
        
    }
}
