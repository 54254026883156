export class ROUTES {

    public static HOME:                 string = '';

    public static LOGIN:                string = 'login';

    public static REGISTER:             string = 'registration';
    public static REGISTER_AWAITING:    string = 'awaiting-approval';
    public static REGISTER_DETAIL:      string = 'account-detail';

    public static PD:                   string = 'pd';

    public static PD_APPLICATIONS:      string = 'applications';
    public static PD_ACCOUNTDETAIL:     string = 'account-detail';
    // public static PD_AWAITINGAPPROVAL:  string = 'awaiting-approval';
    public static PD_NEWAPP:            string = 'new';
    public static PD_EDITAPP:           string = 'edit';

    public static ADMIN:                string = 'admin';

    public static ADMIN_APPLICATIONS:   string = 'applications';
    public static ADMIN_FUNDTERMS:      string = 'fund-terms';
    public static ADMIN_FUNDTERMS_NEW:  string = 'newterm';
    public static ADMIN_FUNDTERMS_EDIT: string = 'editterm';
    public static ADMIN_MEMBERS:        string = 'members';
    public static ADMIN_EDITMEMBER:     string = 'editmember';
    // public static ADMIN_PDEVENTS:       string = 'pd-events';
    public static ADMIN_ADVANCED:       string = 'advanced';

    public static IDPARAM:              string = ':id';

    public static WILDCARD:             string = '**';
}
